import React, { useRef,useEffect ,useState} from "react";
import Moment from 'react-moment';

import LOGO from '../Assets/plsprint.png'
import {AiFillPrinter} from 'react-icons/ai';
import styled from 'styled-components'
import ReactToPrint from "react-to-print";
import TextareaAutosize from 'react-textarea-autosize';
import { Img, Label, currency } from "../utilities/Shared";


export const Wrapper = styled.div`
padding:20px 40px;
font-size:.8rem;
font-weight:bold;
text-transform:capitalize;
`;
export const Input = styled.input`

position: ${props => props.position || "relative"};

width:${props => props.width || "90%"};
padding:${props => props.padding || "5px 3px 0px 3px"};
max-width:${props => props.maxWidth || "100%"};
margin:${props => props.margin || "0px 0px 0px 0px"};
height: ${props => props.height || ""};
font-size:${props => props.fontSize || ".8rem"};
text-align: ${props => props.textalign || "center"};
font-weight: ${props => props.fontWeight || "bold"};

outline:none;
border:none;
border-bottom:1px solid grey;
border-radius:5px;
:focus{
  border:2px solid var(--plsGreen);
}
`;
export const Flexed = styled.div`
width:${props => props.width || "100%"};
display:flex;
justify-content:${props => props.justifycontent || "flex-end"};
align-items:${props => props.alignItems || "flex-start"};
position: ${props => props.position || "relative"};
padding: ${props => props.padding || "3px 0px"};
bottom: ${props => props.bottom || ""};
font-size: ${props => props.fontSize || ""};
`;

export const Flex = styled.div`
width:${props => props.width || "100%"};
position: ${props => props.position || "relative"};
flex:${props => props.flex || "1"};
white-space: ${props => props.whiteSpace || ""};
flex: ${props => props.flex || "1"};
border-bottom: ${props => props.border ? "1px solid black" : ""};
text-align:${props => props.textAlign || "left"};
padding: ${props => props.padding || "0"};
font-size: ${props => props.fontSize || ""};

h2{
    margin:0;
    padding:0;
}
`;




export const Textarea = styled(TextareaAutosize)`

min-height:${props =>props.minHeight || "50px"};
width:${props =>props.width || "100%"};
outline:none;
text-align:center;
border:1px solid #c8c8c8;
font-size:calc(.7rem + .5vw);
padding:${props =>props.padding || "8px"};
margin:${props =>props.margin || "0"};
resize:none;
font-weight:bold;
border-bottom:1px solid black;
:focus{
    border:2px solid var(--plsGreen);
  }

`;
export const Headerspan = styled.div`
width:100%;
text-align:center;
font-size:1.1rem;
`;


export default function PrintComponent({csrjobs,title,salesreps}) {
  let componentRef = useRef();
const [message, setMessage] = useState("")
const [total, setTotal] = useState(0)

console.log(csrjobs)








 




  return (
    <>
      <Flex id="print_component">
        {/* button to trigger printing of target component */}
        <ReactToPrint
      
          trigger={() => <AiFillPrinter style={{fontSize:"calc(1.4rem + .5vw)"}} />}
          content={() => componentRef}
        />

        {/* component to be printed */}
        <Flex style={{ display: "none" }}>
          <ComponentToPrint salesreps={salesreps} csrjobs={csrjobs} title={title} ref={(el) => (componentRef = el)} />
        </Flex>
      </Flex>
    </>
  );
}




// component to be printed
class ComponentToPrint extends React.Component {

  constructor(props) {
    super(props);
     
  
  }

  render() {
    return (
      
     <Wrapper style={{fontSize:"1.5rem",display: 'block',padding:"10px 10px",pageBreakAfter: "auto"}}>
        <Flexed justifycontent="center" style={{fontSize:"2.5rem",textAlign:"center",alignItems:"center"}}>{this.props.title}</Flexed>
        <Flexed style={{borderBottom:"1px solid black",padding:"4px",background:"#8398AB",color:"white"}} className="hand">
  <Flex fontSize="1.5rem" padding="0px 0px 0px 10px" >Job#</Flex>
  <Flex flex="1.5" fontSize="1.5rem" >Customer</Flex>
  <Flex  flex="3" fontSize="1.5rem" >Job Name</Flex>
  <Flex  fontSize="1.5rem" >Quantity</Flex>
  <Flex  fontSize="1.5rem" >Entered</Flex>
  <Flex  fontSize="1.5rem" >Due</Flex>
  <Flex  fontSize="1.5rem" >Salesrep</Flex>
</Flexed>
<div >
{ 
 this.props.csrjobs?.map(job => {
    return <Flexed className="normalrow hand" >
      <Flex fontSize="1.4rem" >&nbsp;&nbsp;&nbsp;{job.job}</Flex>
      <Flex fontSize="1.4rem"  flex="1.5">{job.customer}</Flex>
      <Flex fontSize="1.4rem"  flex="3">{job.jobname}</Flex>
      <Flex fontSize="1.4rem" >{job.quantity}</Flex>
      <Flex fontSize="1.4rem" ><Moment format="MM/DD/YYYY">{job.dateentered}</Moment></Flex>
      <Flex fontSize="1.4rem" ><Moment format="MM/DD/YYYY">{job.duedate}</Moment></Flex>
      <Flex fontSize="1.4rem" >{this.props.salesreps.filter(rep => rep.empId === job.salesrep).map(rep => (rep.firstName+" "+rep.lastName.substr(0,1)))}</Flex>

      


      </Flexed>
  })
}
</div>
    </Wrapper>

  
    );
  }
}
/////////////////////////////////////////////////////////////////////////////////////////////////////
