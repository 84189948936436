import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { HeaderWrap,LOGO, Logout, Div } from './HeaderStyles'
import {Flexed,Flex,Wrapper, Button } from '../../utilities/Shared'
import LOGOIMG from '../../Assets/Untitled.png'
import { CgProfile } from 'react-icons/cg'
import { AiOutlineDown } from 'react-icons/ai'
import Navlinks from '../../utilities/Navlinks'
import { Link,NavLink, useNavigate } from 'react-router-dom'
import UserContext from '../../Context/UserContext'
import { IoIosNotifications } from "react-icons/io";
import ChatContext from "../../Context/ChatContext"
import axios from "axios"


const Header = () => {
  const {userInfo,logout } = useContext(UserContext)
  const [goto,setGoto ] = useState("")
  const [open, SetOpen] = useState(false)
  const { socket, unread}  = useContext(ChatContext)
 
  const [checkMessages, setCheckMessages] = useState(null)


const nav = useNavigate()

const submit =() => {
  nav(`${goto}`)
}

  

const DropItem = ({item,onClick}) => {

  
  return (
    <Div  onClick={onClick} >{item}</Div>
  )
  }













  return (
  <HeaderWrap>
    <Flexed margin="0" height="50px" gap="0" alignItems="center" justifycontent="space-around" >
      <Flex>
        {userInfo.empId === "1537" || userInfo.empId === "1648" || userInfo.empId === "1388" ? <a href="https://highgroundsoftware.com/dash/dashboard/" ><LOGO src={LOGOIMG} alt="Logo" className="hand"/></a> : <a href="https://dash.highgroundsoftware.com" ><LOGO src={LOGOIMG} alt="Logo" className="hand"/> </a>}
        </Flex>
   
     
      <Flex display="flex" justifycontent="flex-end" alignItems="flex-end" style={{marginBottom:"0px"}}>
      <IoIosNotifications style={{position:"relative",margin:"0px 30px 10px 30px",fontSize:"calc(3rem + .2vw)",color:`${unread?.length > 0 ? "red" : "grey"}`}}/>
        <Logout onMouseEnter={() => SetOpen(true)} onMouseLeave={() => SetOpen(false)} className="hand" >
        
          <Flexed  style={{padding:"5px 0px 10px 0px",height:"40px"}} >
          <Flex flex="0"  style={{display:"flex",alignItems:"center",paddingLeft:"10px"}} ><CgProfile style={{fontSize:"calc(1.6rem + .4vw)",color:"white"}} /></Flex>
          <Flex fontWeight="bold" ><span style={{fontSize:"calc(1rem + .4vw)",color:"white",position:"relative",top:"-18px"}} >{userInfo?.fullName}</span></Flex>
          <Flex className="hand" flex="0" style={{display:"flex",alignItems:"center"}}><AiOutlineDown style={{fontSize:"1.7rem",fontWeight:"bold",position:"absolute",top:"0px",right:"20px"}}/></Flex>
          </Flexed>
          <div className={`dropdown ${open ? "active" : "inactive" }`} >
          
          <DropItem item="Profile" onClick={() => nav(`/employees/${sessionStorage.getItem('trackuser')}`)} />
          <DropItem item="Logout" onClick={() => {logout();nav(`/`)}}/>
      
  </div>
        </Logout>
       </Flex>
    </Flexed>
    <form onSubmit={submit}>
    <Flexed  justifycontent="space-around" padding="0px 10px" alignItems="center" margin="0" bgColor="var(--plsGreen)" width="100%" height="var(--Nav-height)" color="white">
      
      <Flex> <Button padding="0" margin="3px 0 0 0" fontSize="var(--headerMedium)" bgcolor="var(--plsGreen)" type="submit" onClick={e => setGoto('/')} > Dashboard</Button></Flex>
       {/*<Flex ><NavLink style={{color:"white",fontSize:"var(--headerMedium)"}} to="/">Dashboard</NavLink></Flex>
     {
     //userInfo.auth.toString() === "106" && <Flex ><NavLink style={{color:"white",fontSize:"var(--headerMedium)"}} to="customers">Customers</NavLink></Flex>
     */} 
     {userInfo.auth?.some(cat => cat.value !== "300") && <>
     <Flex > <Button padding="0" margin="3px 0 0 0" fontSize="var(--headerMedium)" bgcolor="var(--plsGreen)" type="submit" onClick={e => setGoto('/customers')} > Customers</Button></Flex>
     {userInfo.departments?.some(cat => cat.value === "Sales") || userInfo.auth?.includes('106')? <Flex >
        
        <a style={{color:"white",fontSize:"var(--headerMedium)"}} href="https://highgroundsoftware.com/dash/dashboard/?des=2099" >Forms</a>
        </Flex>:""}
      {/*<Flex ><a style={{color:"white",fontSize:"var(--headerMedium)"}} href="https://highgroundsoftware.com/dash/dashboard/?des=60000" >Estimating</a></Flex>*/}
      <Flex ><a style={{color:"white",fontSize:"var(--headerMedium)"}} href="/mailing/eddm" >Mailing</a></Flex>
      <Flex >
       {/*} <a style={{color:"white",fontSize:"var(--headerMedium)"}} href="https://highgroundsoftware.com/dash/dashboard/?des=8569" >Shipping</a>*/}
        <Button padding="0" margin="3px 0 0 0" fontSize="var(--headerMedium)" bgcolor="var(--plsGreen)" type="submit" onClick={e => setGoto('/shipping')} > Shipping</Button>
        </Flex>
      <Flex ><NavLink style={{color:"white",fontSize:"var(--headerMedium)"}} to="vendors">Vendors</NavLink></Flex>
      {userInfo.auth?.includes('600') || userInfo.auth?.includes('106') || userInfo.departments?.some(cat => cat.value === "Receiving")?
      <Flex ><Button padding="0" margin="3px 0 0 0" fontSize="var(--headerMedium)" bgcolor="var(--plsGreen)" type="submit" onClick={e => setGoto('/supplies')} > Supplies</Button></Flex>:""}
                                                                                                                            
      {userInfo.auth?.includes('600') || userInfo.auth?.includes('106') || userInfo.auth?.includes('104') || userInfo.departments?.some(cat => cat.label === "Receiving")? <Flex flex=".7"><Button padding="0" margin="3px 0 0 0" fontSize="var(--headerMedium)" bgcolor="var(--plsGreen)" type="submit" onClick={e => setGoto('/pos')} > PO</Button></Flex> : ""}
      <Flex >{userInfo.auth?.includes('600') || userInfo.auth?.includes('106') ? <a style={{color:"white",fontSize:"var(--headerMedium)"}} href="https://highgroundsoftware.com/dash/dashboard/?des=60000" >Estimating</a> : null}</Flex>
      <Flex >{userInfo.auth?.includes('600') || userInfo.auth?.includes('106') ||  userInfo.departments?.some(cat => cat.value === "104") ||  userInfo.departments?.some(cat => cat.value === "122") ? <a style={{color:"white",fontSize:"var(--headerMedium)"}} href="/schedules" >Schedule</a> : null}</Flex>
      <Flex >
      {userInfo.auth?.includes('600') || userInfo.departments?.some(cat => cat.value === "113") || userInfo.auth?.includes('106') ||  userInfo.departments?.some(cat => cat.label === "Inventory") ||  userInfo.departments?.some(cat => cat.label === "Digital") ?
        <NavLink style={{color:"white",fontSize:"var(--headerMedium)"}} to="inventory">Inventory</NavLink> : null}</Flex>
     {userInfo.auth?.includes('600') || userInfo.auth?.includes('106') ? <Flex ><NavLink style={{color:"white",fontSize:"var(--headerMedium)"}} to="sitesettings">Admin</NavLink></Flex>: null}
     
    {/*  <Flex ><a style={{color:"white",fontSize:"var(--headerMedium)"}} href="https://highgroundsoftware.com/dash/dashboard/" >Admin</a></Flex>*/}
{
//https://highgroundsoftware.com/dash/dashboard/?des=50000
}
     </> 
}
    </Flexed>
    </form>
  </HeaderWrap>
  )
}

export default Header







