import React, { useContext, useEffect,useState } from 'react'
import { Selectbox } from './Shared'
import axios from 'axios'
import UserContext from '../Context/UserContext'
import Select from './Select'


/* AREAS 
'Digital'}
'Paper Areas'}
'Web Rolls'}
'Colormax'}
'Floor'}

*/

export const SalesReps = ({onChange,value}) => {
const userInfo = useContext(UserContext)
  const [list, setList]= useState([])

  useEffect(() => {
      const getReps = async() => {
  await axios.get('https://highgroundapi.herokuapp.com/employee/sales')
  .then(res => {
    console.log(res.data)
    setList(res.data)
      })
  .catch(err => console.log(err))
      }
      getReps()
  },[])

    return (
      <Selectbox onChange={onChange} value={value}  style={{fontWeight:"bold",padding:"6px 4px",borderLeft:"20px solid rgba(122, 187, 67, .5)"}} border="1px solid rgba(122, 187, 67, .5)"   required >
      <option value="" selected disabled>Select Sales Rep</option>
      {
      list.map(item => {
        return  <option value={item._id} selected={item._id === userInfo.userInfo.salesrep ? "selected" : ""}>{item?.firstName} {item?.lastName}</option>
      })
  }
</Selectbox>
  )
}


export const CSRReps = ({onChange,value}) => {
const userInfo = useContext(UserContext)
    const [list, setList]= useState([])
    
    useEffect(() => {
        const getReps = async() => {
    await axios.get('https://highgroundapi.herokuapp.com/employee/csr')
    .then(res => setList(res.data))
    .catch(err => console.log(err))
        }
        getReps()
    },[])
      return (
        <Selectbox onChange={onChange} value={value}  style={{fontWeight:"bold",padding:"6px 4px",borderLeft:"20px solid rgba(122, 187, 67, .5)"}} border="1px solid rgba(122, 187, 67, .5)"   required >
            <option value="" selected disabled>Select CSR Rep</option>
            {
            list.map(item => {
              return  <option value={item._id} selected={item._id === userInfo.userInfo.csr ? "selected" : ""}>{item?.firstName} {item?.lastName}</option>
            })
        }
    </Selectbox>
      )
    }



    export const SalesRepsByEmpid = ({onChange,value,disabled}) => {

      const [list, setList]= useState([])
        
      useEffect(() => {
          const getReps = async() => {
      await axios.get('https://highgroundapi.herokuapp.com/employee/sales')
      .then(res => setList(res.data))
      .catch(err => console.log(err))
          }
          getReps()
      },[])
        return (
          <Selectbox onChange={onChange} value={value}  style={{fontWeight:"bold",padding:"6px 4px",borderLeft:"20px solid rgba(122, 187, 67, .5)"}} border="1px solid rgba(122, 187, 67, .5)" disabled={disabled}  required >
              <option value="" selected disabled>Select Sales Rep</option>
              {
              list.map(item => {
                return  <option value={item.empId}>{item.preferredName > "" ?item.preferredName : item.firstName} {item.lastName}</option>
              })
          }
      </Selectbox>
      )
    }

    export const CSRRepsByEmpid = ({onChange,value,disabled}) => {

      const [list, setList]= useState([])
        
      useEffect(() => {
          const getReps = async() => {
      await axios.get('https://highgroundapi.herokuapp.com/employee/csr')
      .then(res => setList(res.data))
      .catch(err => console.log(err))
          }
          getReps()
      },[])
        return (
          <Selectbox onChange={onChange} value={value}  style={{fontWeight:"bold",padding:"6px 4px",borderLeft:"20px solid rgba(122, 187, 67, .5)"}} border="1px solid rgba(122, 187, 67, .5)" disabled={disabled}  required >
              <option value="" selected disabled>Select Sales Rep</option>
              {
              list.map(item => {
                return  <option value={item.empId}>{item.preferredName > "" ?item.preferredName : item.firstName} {item.lastName}</option>
              })
          }
      </Selectbox>
      )
    }


