import React, { useEffect, useState } from 'react'
import {Flex, Flexed, Input, MainWrap, Selectbox, Textarea} from '../../utilities/Shared'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import Moment from 'react-moment'
import { IoMdAdd } from 'react-icons/io'
import Modal from '../../modals/Modal'
import Addship from './Addship'
import ShippingTicket from '../../Printing/ShippingTicket'
import Shipitem from '../Search/Shipitem'



const Shipticket = () => {
    const param = useParams()
    const[jobinfo, setJobinfo] = useState({})
    const[customerinfo, setCustomerinfo] = useState({})
    const[tempmainlocation, setTempmainlocation] = useState({})
    const[templist, setTemplist] = useState([])
    const[addship, setAddship] = useState(false)
    const[reset, setReset] = useState(false)
    const [packlist, setPacklist] = useState([])
    const[currentlocation, setCurrentlocation] = useState()
    const [addylist, setAddylist] = useState([])

    const config = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin':'*' 
        }
      };   
    const t = {
        job:param.job.replace(/\D/g, "")
    }


useEffect(() => {

    const getcurrent = async() => {
        let r = {'jobnum':param?.job.replace(/\D/g, "")}
       await axios.post(`https://highgroundapi.herokuapp.com/jobs/find`,r)
        //await axios.post(`https://highgroundapi.herokuapp.com/jobs/find`,r)
       .then(res => {
     
        setJobinfo(res.data[0]);
        y(res.data[0]?.customerid)
    if(res.data[0]?.shippingitems?.length <= 0){
        
        getitems(res.data[0]._id)
    }else{
     
        setTemplist({...res.data[0]})
    }

    })
       .catch(err => console.log(err))
    }

    const y = async(customerid) => {
    
   await axios.get(`https://highgroundapi.herokuapp.com/customers/hg/item/${customerid}`)
     //await axios.get(`http://localhost:5003/customers/hg/item/${customerid}`)
    .then(res => {
        setCustomerinfo(res.data[0]);
        setTempmainlocation(res.data[0]?.address?.filter(filt => filt.itemid === res.data[0].mainlocation))})
    .catch(err => console.log(err))
    }

const getitems = (id) => {
 
  axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getshippingitems.php`,t,config)
    .then(res => {
        if(res.data.length > 0){
            console.log(res.data)
let templist = []
let newlist = []
res.data.map(item => {
let uu = []
   if(templist.some(som => parseInt(som) === parseInt(item.shiptoid))){ 
   }else{  
    newlist.push(item.shiptoid)
    templist.push(item.shiptoid)
    }
  
   })
   setAddylist(newlist)    

let y = 
{
    id:id,
    field:'shippingitems',
    value:res.data 
}

      
        }else{
          
        }
        
    })
   .catch(err => {console.log(err)

})
}

const getshipping = () => {
  axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getshipping.php`,t,config)
    .then(res => {
             //setShipto(res.data)}
    
    })
   .catch(err => {console.log(err)})
}

getshipping()

    getcurrent()    

},[reset])


const addtopack = (check,item) => {
   
if(check === true){
    setPacklist([...packlist,item])
}else{
  let u = packlist.filter(filt => filt.id !== item.id)
  setPacklist(u)
}

}


  return (
    <MainWrap margin="30px">

        <Modal show={addship} header={`Add Shipping Item`} onCancel={() => {setAddship(false)}} ><Addship onCancel={() => {setAddship(false)}}  reset={reset} setReset={setReset} jobinfo={jobinfo} customerinfo={customerinfo} templist={templist} setTemplist={setTemplist}/></Modal>

        <Flexed gap="20px">
            <Flex padding="20px 20px" border="1px solid lightgrey">
<Flexed padding="0px 0px 15px 0px">
    <Flex fontSize="calc(1.2rem + .2vw)">Bill To: <Input type="text" value={customerinfo?.name} disabled /> </Flex>
    <Flex fontSize="calc(1.2rem + .2vw)" flex=".3"><br /> <Input type="text" value={customerinfo?.company_id} disabled /></Flex>
</Flexed>
<Flexed padding="0px 0px 15px 0px">
    <Flex fontSize="calc(1.2rem + .2vw)" flex="2"><Input type="text" value={tempmainlocation[0]?.address1} disabled />  </Flex>
    <Flex fontSize="calc(1.2rem + .2vw)"><Input type="text" value={tempmainlocation[0]?.address2} disabled /></Flex>
</Flexed>
<Flexed padding="0px 0px 15px 0px">
    <Flex fontSize="calc(1.2rem + .2vw)"><Input type="text" value={tempmainlocation[0]?.city} disabled />  </Flex>
    <Flex fontSize="calc(1.2rem + .2vw)"><Input type="text" value={tempmainlocation[0]?.state} disabled /></Flex>
    <Flex fontSize="calc(1.2rem + .2vw)"><Input type="text" value={tempmainlocation[0]?.zip} disabled placeholder="Address2"/></Flex>
</Flexed>
<br /><br /><br />
<Flexed padding="0px 0px 15px 0px">

    <Flex fontSize="calc(1.2rem + .2vw)">Billing Term<br /><Input type="text" value={customerinfo?.billingterms} disabled /></Flex>
    <Flex fontSize="calc(1.2rem + .2vw)">Tax Exempt<br /><Input type="text" value={customerinfo?.taxexempt} disabled /></Flex>
</Flexed>
<Flexed padding="0px 0px 15px 0px"> 
    <Flex fontSize="calc(1.2rem + .2vw)">Permit #<br /><Input type="text" value="" disabled /></Flex>
    <Flex fontSize="calc(1.2rem + .2vw)">Permit Location<br /><Input type="text" value="" disabled /></Flex>
    <Flex fontSize="calc(1.2rem + .2vw)">Permit Style<br /><Input type="text" value="" disabled /></Flex>
</Flexed>
            </Flex>



            <Flex padding="20px 20px" border="1px solid lightgrey">
<Flexed padding="0px 0px 15px 0px">
    <Flex fontSize="calc(1.2rem + .2vw)">Job#: <Input type="text"  value={jobinfo.job} disabled /> </Flex>
    <Flex flex=".2"></Flex>
    <Flex fontSize="calc(1.2rem + .2vw)">Entered: <br /><Moment style={{fontSize:"calc(1.2rem + .2vw)",border:"1px solid rgb(0,0,0,.3)",padding:"6px",position:"relative",top:"5px",borderRadius:"5px"}} format="MM/DD/YYYY">{jobinfo.dateentered}</Moment> </Flex>
    <Flex fontSize="calc(1.2rem + .2vw)">Date Due:<br /> <Moment style={{fontSize:"calc(1.2rem + .2vw)",border:"1px solid rgb(0,0,0,.3)",padding:"6px",position:"relative",top:"5px",borderRadius:"5px"}} format="MM/DD/YYYY">{jobinfo.duedate}</Moment> </Flex>
</Flexed>
<Flexed padding="0px 0px 15px 0px">
<Flex fontSize="calc(1.2rem + .2vw)">Quote#: <Input type="text" value={jobinfo.quote} disabled /> </Flex>
<Flex fontSize="calc(1.2rem + .2vw)">PO#: <Input type="text" value={jobinfo.custpo} disabled /> </Flex>
<Flex fontSize="calc(1.2rem + .2vw)">Prev Job#: <Input type="text" value={jobinfo.prevjob} disabled /> </Flex>
</Flexed>
<Flexed padding="0px 0px 15px 0px">
    <Flex fontSize="calc(1.2rem + .2vw)" flex=".2">Samples:<br /><Input type="text" value="5" disabled />  </Flex>
    <Flex fontSize="calc(1.2rem + .2vw)">Send Samples To:<br /><Input type="text" value="" disabled />  </Flex>
</Flexed>
<Flexed padding="0px 0px 15px 0px">
    <Flex fontSize="calc(1.2rem + .2vw)">Postage Quantity<br /><Input type="text" value="" disabled /></Flex>
    <Flex fontSize="calc(1.2rem + .2vw)">Postage Cost<br /><Input type="text" value="" disabled /></Flex>
    <Flex fontSize="calc(1.2rem + .2vw)"> Total Postage $<br /><Input type="text" value="" disabled /></Flex>
</Flexed>
<Flexed padding="0px 0px 15px 0px"> 
    <Flex flex=".5" fontSize="calc(1.2rem + .2vw)">Drop Shipment Cost<br /><Input type="text" value="" disabled /></Flex>
    <Flex fontSize="calc(1.2rem + .2vw)">Supplier<br /><Input type="text" value="" disabled /></Flex>
   
</Flexed>
            </Flex>
            </Flexed>

<Flexed padding="20px 0px" borderBottom="1px solid black" >
<Flex flex="4" fontSize="calc(1.2rem + .2vw)" ><br />{jobinfo.jobname}</Flex>
<Flex fontSize="calc(1.2rem + .2vw)">Packing LIst Style<br />
    <Selectbox value={templist.blind} onChange={e => setTemplist({...templist,blind:e.target.value})}>
        <option value="normal ">Normal</option>
        <option value="blind ">Blind</option>

    </Selectbox>
</Flex>
<Flex flex="0" padding="0px 10px"><br /><IoMdAdd style={{fontSize:"calc(1.9rem + .2vw)",position:"relative"}} onClick={e => {setAddship(true)}} /></Flex>


<Flex flex="0" padding="0px 20px"><ShippingTicket title="Save & Print" jobinfo={jobinfo} packlist={packlist} setReset={setReset} reset={reset} setTemplist={setTemplist} templist={templist} setJobinfo={setJobinfo} customerinfo={customerinfo} mainloca={tempmainlocation} /></Flex>


</Flexed>


<Flexed padding="10px 3px 5px 3px">
    <Flex fontSize="calc(1.2rem + .2vw)" flex="3">Item:</Flex>
    <Flex fontSize="calc(1.2rem + .2vw)" flex=".9">Ordered:</Flex>
    <Flex fontSize="calc(1.2rem + .2vw)" flex=".9">Sent:</Flex>
    <Flex fontSize="calc(1.2rem + .2vw)" flex=".9">Send Type:</Flex>
    <Flex fontSize="calc(1.2rem + .2vw)">Shipping:</Flex>
    <Flex fontSize="calc(1.2rem + .2vw)" flex=".5">Per:</Flex>
    <Flex fontSize="calc(1.2rem + .2vw)">Total</Flex>
    <Flex fontSize="calc(1.2rem + .2vw)" textAlign="center" flex=".5"></Flex>
</Flexed>


{
templist?.shippingitems?.map((item,index) => {
   return <><Shipitem item={item} placeindex={index} currentlocation={currentlocation} addtopack={addtopack} setCurrentlocation={setCurrentlocation} jobinfo={jobinfo} setJobinfo={setJobinfo} reset={reset} setReset={setReset} customerinfo={customerinfo} /> </>
})}



<br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
<br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />

       
    </MainWrap>
  )
}

export default Shipticket


//<Flex flex="0" padding="0px 10px"><FaClipboardList style={{fontSize:"calc(1.9rem + .2vw)",position:"relative",top:"20px"}}  /></Flex>{
    
