import React, { useRef,useEffect ,useState} from "react";
import Moment from 'react-moment';

//import LOGO from '../Assets/CFPLOGO.png'
import {AiFillPrinter} from 'react-icons/ai';
import styled from 'styled-components'
import ReactToPrint from "react-to-print";
import { Wrap } from "../utilities/Shared";
import { toast } from "react-toastify";



export const Wrapper = styled.div`
padding:20px 40px;
font-size:.7rem;
font-weight:bold;
background-color:white;
`;
export const Input = styled.input`

position: ${props => props.position || "relative"};

width:${props => props.width || "90%"};
padding:${props => props.padding || "5px 3px 0px 3px"};
max-width:${props => props.maxWidth || "100%"};
margin:${props => props.margin || "0px 0px 0px 0px"};
height: ${props => props.height || ""};
font-size:${props => props.fontSize || ".8rem"};
text-align: ${props => props.textalign || "center"};
font-weight: ${props => props.fontWeight || "bold"};

outline:none;
border:none;
border-bottom:1px solid grey;
border-radius:5px;
:focus{
  border:2px solid var(--plsGreen);
}
`;
export const Flexed = styled.div`
width:${props => props.width || "100%"};
display:flex;
justify-content:${props => props.justifycontent || "flex-end"};
align-items:${props => props.alignItems || "flex-end"};
position: ${props => props.position || "relative"};
padding: ${props => props.padding || "3px 0px"};
border-bottom: ${props => props.borderBottom || ""};
border: ${props => props.border || ""};
margin: ${props => props.margin || "0"};
`;

export const Flex = styled.div`
width:${props => props.width || "100%"};
position: ${props => props.position || "relative"};
flex:${props => props.flex || "1"};
white-space: ${props => props.whiteSpace || "nowrap"};
flex: ${props => props.flex || "1"};
border-bottom: ${props => props.border ? "1px solid black" : ""};
text-align:${props => props.textAlign || "left"};
padding: ${props => props.padding || "0"};
margin-right: ${props => props.marginRight || "0"};
border-left: ${props => props.borderLeft || ""};
`;

export const Line = styled.div`
height:60vh;
background-color:black;
position:absolute;
top:0;
right:${props =>props.right || ""};
width:1px;
z-index:10;
`;




export const Headerspan = styled.div`
width:100%;
text-align:center;
font-size:1.1rem;

`;


export default function PrintComponent({job,items}) {
  let componentRef = useRef();
const [message, setMessage] = useState("")
const [totalsheets, setTotalsheets] = useState(0)


  useEffect(() => {
const getpoinfo = async() => {

}

getpoinfo()
  })
  function percentage(partialValue, totalValue) {
    const t = (partialValue / 100) * totalValue
    return t + totalValue
 }      



 const rows = [];
 for (let i = 0; i < 14; i++) {

  rows.push( <Flexed style={{borderBottom:"1px solid black"}}><Flex style={{borderRight:"1px solid black"}}><span style={{fontSize:"2.7rem"}}>&nbsp;</span></Flex>
<Flex style={{borderRight:"1px solid black"}}><span style={{fontSize:"2.7rem"}}>&nbsp;</span></Flex></Flexed>)

    //  rows.push(<Flexed style={{borderBottom:"1px solid grey",borderRight:"1px solid black",height:"30px"}} ><Flex>&nbsp;</Flex><Flex borderLeft="1px solid grey">&nbsp;</Flex></Flexed>)
 } 



console.log(items)


  return (
    <>
      <Flex id="print_component">
        {/* button to trigger printing of target component */}
        <ReactToPrint
 
        onBeforePrint={() => {if(!job.date){return toast.warning('No Date Has Been Set');}else{return}}}
          trigger={() => <AiFillPrinter title="Packing Slip" style={{fontSize:"calc(1.4rem + .5vw)",float:"right",marginTop:"15px",}} />}
          content={() => componentRef}
        />

        {/* component to be printed */}

        <Flex style={{ display: "none" }}>
        <ComponentToPrint items={items} rows={rows} job={job} ref={(el) => (componentRef = el)}  />
        </Flex>
      </Flex>
    </>
  );
}




// component to be printed
class ComponentToPrint extends React.Component {

  constructor(props) {
    super(props);
console.log(this.props)

   

  }
   
 
  render() {
 
    return (  
<Wrap style={{background:"white",padding:"0px"}}>  
    {
        this.props.items?.map((item,index) => {
return    <div key={index} style={{pageBreakAfter:"always"}}>
<Wrap style={{background:"white"}}>
<Flexed style={{borderTop:"1px solid black",fontSize:"2.7rem",padding:"20px 0px",fontWeight:"bold",background:"white"}} margin="30px 0px 0px 0px"><Flex  style={{fontSize:"2.1rem",textAlign:"center"}}  >EVERY DOOR DIRECT MAIL ®<br />FACING SLIP</Flex></Flexed>
<Flexed style={{borderTop:"1px solid black"}}>
<Flex style={{borderRight:"1px solid black"}}><span style={{fontSize:"1.4rem"}}>5-Digit Zip Code (Required)</span><br /><br /><span style={{fontSize:"3.5rem",margin:"10px 0px"}}>{item?.zip}</span><br /><br /></Flex>
<Flex style={{borderRight:"1px solid black"}}><span style={{fontSize:"1.4rem"}}>&nbsp;Route Number (Required)</span><br /><br /><span style={{fontSize:"3.5rem",margin:"10px 0px"}}>&nbsp;{item?.route}</span><br /><br /></Flex>
<Flex ><span style={{fontSize:"1.4rem"}}>&nbsp;Delivery Type (Required)</span><br /><br /><span style={{fontSize:"3.5rem",margin:"10px 0px"}}>&nbsp;{this.props.job.deltype}</span><br /><br /></Flex>

</Flexed>
<Flexed style={{borderTop:"1px solid black",borderBottom:"1px solid grey",justifycontent:"flex-start"}} >

<Flex style={{borderRight:"1px solid black"}}><span style={{fontSize:"1.4rem"}}>Date</span><br /><br /><span style={{fontSize:"3.5rem",margin:"10px 0px"}}>{item?.date ? <Moment style={{fontSize:"3.5rem"}} format="MM/DD/YYYY">{item?.date}</Moment> : <>&nbsp;</>}</span><br /><br /></Flex>
<Flex style={{borderRight:"1px solid black",textAlign:"center"}}><span style={{fontSize:"1.4rem"}}>&nbsp;Total # of Mailpices Per Bundle</span><br /><br /><span style={{fontSize:"3.5rem",margin:"10px 0px"}}>&nbsp;{item?.perbundle}</span><br /><br /></Flex>
<Flex ><span style={{fontSize:"1.4rem"}}>&nbsp;Total # of Bundles</span><br /><br /><span style={{fontSize:"3.5rem",margin:"10px 0px"}}>{item.tre}&nbsp;&nbsp;<span style={{fontSize:"1.5rem"}}>of</span>&nbsp; {item?.pages}</span><br /><br /></Flex>


</Flexed>
<Flexed style={{borderBottom:"1px solid grey"}}>
<Flex style={{fontSize:"1.4rem",padding:"5px 0px",fontWeight:"bold"}}  textAlign="center">Do Not Deliver Address</Flex>
<Flex style={{fontSize:"1.4rem",padding:"5px 0px",fontWeight:"bold"}}  textAlign="center" >Do Not Deliver Address</Flex>
</Flexed>

{this.props.rows}
<Flexed style={{borderBottom:"1px solid grey"}}>
<Flex style={{fontSize:"1.4rem",padding:"5px",fontWeight:"bold"}}  textAlign="center">Saturation Mail Description</Flex>
<Flex style={{fontSize:"1.4rem",padding:"5px",fontWeight:"bold"}}  textAlign="center" flex="1.41" borderLeft="1px solid grey">Mailer Information</Flex>
</Flexed>

<Flexed >  
<Flex textAlign="center"  style={{backgroundColor:"white",zIndex:"20",padding:"4px"}}></Flex>
<Flex textAlign="left" flex="1.4" borderLeft="1px solid grey" style={{fontSize:"1.4rem",padding:"4px",borderBottom:"1px solid grey"}} >&nbsp;Permit Holder Name<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.props.job.holder} </Flex>
</Flexed>

<Flexed >
<Flex textAlign="center"></Flex>
<Flex textAlign="center" flex="1.39" borderLeft="1px solid grey" style={{fontSize:"1.4rem"}}>
<Flexed style={{alignItems:"flex-start"}} >
<Flex  style={{fontSize:"1.4rem",borderBottom:"1px solid grey"}} >&nbsp;Permit Number<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.props.job.permit}</Flex>
<Flex borderLeft="1px solid grey" style={{fontSize:"1.4rem",borderBottom:"1px solid grey"}} >&nbsp;Permit Type<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.props.job.type}</Flex>
<Flex borderLeft="1px solid grey" style={{fontSize:"1.4rem",borderBottom:"1px solid grey"}} >&nbsp;Post Office of Mailing<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Flex>

</Flexed>
</Flex>
</Flexed>
</Wrap>
</div>
            
      
        })
    }
          
      
  
 
                
  
  
  </Wrap>   

 
    )
 
    
}
}
/////////////////////////////////////////////////////////////////////////////////////////////////////
