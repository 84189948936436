import styled from "styled-components";
import Image from '../../../Assets/colorbar.png'
import GripperImg from '../../../Assets/gripper.png'

export const SheetWrap = styled.div`
position:${props => props.position || "relative"};
width:${props => props.width || "100%"};
height:${props => props.height || ""};
border:${props => props.border || "1px solid #303030"};
background:white;
z-index:50;
right:0;
top:0;
transition:all .5s ease-in;
&:hover{
    transform: scale(1.5);
    right:50px;
    top:-20px;
    
}
`;

export const Tail = styled.div`
position:${props => props.position || "relative"};
width:${props => props.width || "100%"};
height:${props => props.height || ""};
background-color:rgb(0,0,0,.2);


`;


export const Colorbar = styled.div`
position:${props => props.position || "relative"};
height:${props => props.height || ""};
background-image:url(${Image});
background-repeat:repeat;
width:100%;
background-size:contain;

`;

export const Colorbarbuff = styled.div`
position:${props => props.position || "relative"};
width:${props => props.width || "100%"};
height:${props => props.height || ""};
background-color:rgb(0,0,0,.2);


`;


export const Content = styled.div`
position:${props => props.position || "relative"};
width:${props => props.width || "100%"};
height:${props => props.height || "calc(100% - 5px - 2.5px)"};
background-color:white;
padding:${props => props.padding || ""};
`;

export const InnerContent = styled.div`
position:${props => props.position || "relative"};
width:${props => props.width || "100%"};
height:${props => props.height || "100%"};
display:flex;
flex-wrap:wrap;
justify-content:space-around;
align-items:center;
border:1px dashed black;
`;

export const Gripper = styled.div`
position:${props => props.position || "relative"};
width:${props => props.width || "100%"};
height:${props => props.height || "2.5px"};
background-color:rgb(0,0,0,.2);
`;