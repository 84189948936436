import React, { useRef,useEffect ,useState} from "react";
import Moment from 'react-moment';

import LOGO from '../Assets/plsprint.png'
import {AiFillPrinter} from 'react-icons/ai';
import styled from 'styled-components'
import ReactToPrint from "react-to-print";
import TextareaAutosize from 'react-textarea-autosize';
import { Img, Label, currency } from "../utilities/Shared";


export const Wrapper = styled.div`
padding:20px 40px;
font-size:.8rem;
font-weight:bold;
text-transform:capitalize;
`;
export const Input = styled.input`

position: ${props => props.position || "relative"};

width:${props => props.width || "90%"};
padding:${props => props.padding || "5px 3px 0px 3px"};
max-width:${props => props.maxWidth || "100%"};
margin:${props => props.margin || "0px 0px 0px 0px"};
height: ${props => props.height || ""};
font-size:${props => props.fontSize || ".8rem"};
text-align: ${props => props.textalign || "center"};
font-weight: ${props => props.fontWeight || "bold"};

outline:none;
border:none;
border-bottom:1px solid grey;
border-radius:5px;
:focus{
  border:2px solid var(--plsGreen);
}
`;
export const Flexed = styled.div`
width:${props => props.width || "100%"};
display:flex;
justify-content:${props => props.justifycontent || "flex-end"};
align-items:${props => props.alignItems || "flex-start"};
position: ${props => props.position || "relative"};
padding: ${props => props.padding || "3px 0px"};
bottom: ${props => props.bottom || ""};
font-size:${props => props.fontSize || ""};
margin:${props => props.margin || ""};
border-bottom: ${props => props.borderBottom || ""};
`;

export const Flex = styled.div`
width:${props => props.width || "100%"};
position: ${props => props.position || "relative"};
flex:${props => props.flex || "1"};
white-space: ${props => props.whiteSpace || "nowrap"};
flex: ${props => props.flex || "1"};
border-bottom: ${props => props.border ? "1px solid black" : ""};
text-align:${props => props.textAlign || "left"};
padding: ${props => props.padding || "0"};
font-size:${props => props.fontSize || ""};


h2{
    margin:0;
    padding:0;
}
`;




export const Textarea = styled(TextareaAutosize)`

min-height:${props =>props.minHeight || "50px"};
width:${props =>props.width || "100%"};
outline:none;
text-align:center;
border:1px solid #c8c8c8;
font-size:calc(.7rem + .5vw);
padding:${props =>props.padding || "8px"};
margin:${props =>props.margin || "0"};
resize:none;
font-weight:bold;
border-bottom:1px solid black;
:focus{
    border:2px solid var(--plsGreen);
  }

`;
export const Headerspan = styled.div`
width:100%;
text-align:center;
font-size:1.1rem;
`;


export default function PrintComponent({poinfo,savepo}) {
  let componentRef = useRef();
const [message, setMessage] = useState("")
const [total, setTotal] = useState(0)

useEffect(() => {
    const addit = () => {
        let u = 0
        poinfo?.items?.map(item => {
       u += (item.extprice)
            })
            setTotal(u)
    
    }
    addit()
},[poinfo])



  useEffect(() => {
const getpoinfo = async() => {

}




getpoinfo()
  },[])


  function percentage(partialValue, totalValue) {
    const t = (partialValue / 100) * totalValue
    return t + totalValue
 }      

 console.log(poinfo)


  return (
    <>
      <Flex id="print_component">
        {/* button to trigger printing of target component */}
        <ReactToPrint
      
          trigger={() => <AiFillPrinter style={{fontSize:"calc(1.4rem + .5vw)"}} />}
          content={() => componentRef}
        />

        {/* component to be printed */}
        <Flex style={{ display: "none" }}>
          <ComponentToPrint poinfo={poinfo} total={total} ref={(el) => (componentRef = el)} />
        </Flex>
      </Flex>
    </>
  );
}




// component to be printed
class ComponentToPrint extends React.Component {

  constructor(props) {
    super(props);
     

  }

  render() {
    return (
      
     <Wrapper >

<Flexed margin="25px 0px 0px 0px">
    <Flex><Img src={LOGO} alt="Logo" style={{height:"110px",left:"0px",}}/></Flex>
    <Flex padding="0px 0px 0px 30px" fontSize="1.3rem">4250 118th Ave N<br />Clearwater, Fl 33762<br />Phone: 727-573-1763<br />Fax: 727-573-2662<br />www.plsprint.com</Flex>
    <Flex flex="1.6" style={{fontSize:"2.2rem",textAlign:"right",top:"10px"}}>Purchase Order<br />No. {this.props.poinfo?.ponum}</Flex>
</Flexed>
<Flexed margin="30px 0px 0px 0px">
    <Flex fontSize="1.5rem"><span style={{fontWeight:"bold",fontSize:"1.7rem"}}>Vendor</span><br />{this.props.poinfo?.vendor?.company.name}<br />{this.props.poinfo?.vendor?.address?.address1}{this.props.poinfo?.vendor?.address.address2 ? <><br />{this.props.poinfo?.vendor?.address.address2}</> : ''} <br />{this.props.poinfo?.vendor?.address?.city}, {this.props.poinfo?.vendor?.address?.state} {this.props.poinfo?.vendor?.address?.zip}</Flex>
    <Flex fontSize="1.5rem"><span style={{fontWeight:"bold",fontSize:"1.7rem"}}>Ship To</span><br />{this.props.poinfo?.shipto?.company.name}<br />{this.props.poinfo?.shipto?.address?.address1}{this.props.poinfo?.shipto?.address.address2 ? <><br />{this.props.poinfo?.shipto?.address.address2}</> : ''} <br />{this.props.poinfo?.shipto?.address?.city}, {this.props.poinfo?.shipto?.address?.state} {this.props.poinfo?.shipto?.address?.zip}</Flex>
    <Flex fontSize="1.5rem"><span style={{fontWeight:"bold",fontSize:"1.9rem"}}>Order Date</span><br /><span ><Moment style={{fontWeight:"bold",fontSize:"1.5rem"}} format="MM/DD/YYYY">{this.props.poinfo?.createdAt}</Moment></span><br /><br /><span style={{fontWeight:"bold",fontSize:"1.9rem"}}>Due By Date</span><br /><Moment style={{fontWeight:"bold",fontSize:"1.5rem"}} format="MM/DD/YYYY">{this.props.poinfo?.dueDate}</Moment></Flex>
    <Flex fontSize="1.5rem"><span style={{fontWeight:"bold",fontSize:"1.9rem"}}>Ordered By</span><br />{this.props.poinfo?.createdBy ? this.props.poinfo?.createdBy?.firstName+" "+this.props.poinfo.createdBy?.lastName.substr(0,1) : "" }<br /><br /><span style={{fontWeight:"bold",fontSize:"1.9rem"}}>Vendor Invoice </span><br />{this.props.poinfo?.invoice}</Flex>
</Flexed>
<Flexed margin="30px 0px 0px 0px" padding="5px 0px" style={{background:"rgb(0,0,0,.1)",border:"1px solid black",borderBottom:"none",fontWeight:"bold"}} >
    <Flex fontSize="1.7rem"  textAlign="center">Quantity</Flex>
    <Flex fontSize="1.7rem"  textAlign="center"> Unit</Flex>
    <Flex fontSize="1.7rem"  flex="3" textAlign="left">Description</Flex>
    <Flex fontSize="1.7rem"  textAlign="center">Size</Flex>
    <Flex fontSize="1.7rem"  textAlign="center">Job#</Flex>
    <Flex fontSize="1.7rem"  textAlign="center">Unit </Flex>
    <Flex fontSize="1.7rem"  textAlign="center">Pricing </Flex>
    <Flex fontSize="1.7rem"  textAlign="center">Price</Flex>
</Flexed>
<div style={{height:"60vh",border:"1px solid black",position:"relative",width:"100%"}}>
{this.props.poinfo?.items?.map(item => {
return <><Flexed padding="5px 0px" margin="10px 0px 0px 0px" style={{borderBottom:`${item.notes > "" ? "" : "1px solid black"}`}} >
    <Flex fontSize="1.5rem" textAlign="center">{item.quantity}</Flex>
    <Flex fontSize="1.5rem"  textAlign="center"> {item.orderby}</Flex>
    <Flex fontSize="1.5rem"  whiteSpace="normal" flex="3" textAlign="left">{item.name}</Flex>
    <Flex fontSize="1.5rem"  textAlign="center">{item.width} {item.length ? 'x'+item.length : ""}</Flex>
    <Flex fontSize="1.5rem"  textAlign="center">{item.jobnum}</Flex>
    <Flex fontSize="1.5rem"  textAlign="center">{item.unitprice} </Flex>
    <Flex fontSize="1.5rem"  textAlign="center">{item.priceby}</Flex>
    <Flex fontSize="1.5rem"  textAlign="center">{currency(item.extprice)}</Flex>
</Flexed>
{item.forjobs?.length > 0  ? item.forjobs.map(it =>{
  return <Flexed padding="3px 0px" margin="3px 0px 0px 0px" borderBottom="1px solid rgb(0,0,0,.2)">
    <Flex flex=".8"fontSize="1.5rem"  textAlign="right" padding="0px 10px">Job# </Flex>
    <Flex flex=".3" fontSize="1.5rem" >{it.job} - </Flex>
    <Flex flex=".3" fontSize="1.5rem" >{it.quantity}</Flex>
    <Flex flex="4"></Flex>
  </Flexed>
}): ""}

{item.notes && <Flexed style={{width:"100%",padding:"2px",marginBottom:"5px"}}><Flex style={{padding:"2px 5px 2px 15px"}}><Textarea value={item.notes?.replace(/&amp;/g, "&")} style={{textAlign:"left",fontSize:"calc(1.3rem + .2vw)",width:"99%",height:"auto",paddingBottom:"10px",overflow:"hidden"}} /></Flex></Flexed>}
</>
 })}
</div>
<Flexed>
    <Flex></Flex>
    <Flex flex="5"></Flex>
    <Flex fontSize="1.7rem" textAlign="center">Sub Total<br />Taxes<br />Total</Flex>
    <Flex  fontSize="1.5rem"textAlign="right" padding="0px 10px 0px 0px">${currency(this.props.total)}<br />0<br/>${currency(this.props.total)}</Flex>
</Flexed>
<Flexed position="absolute" bottom="0px" >
    <Flex flex="0">Approved By:&nbsp;&nbsp;</Flex> <Flex style={{borderBottom:"1px solid black"}}><br /></Flex><Flex flex=".5"></Flex>
</Flexed>

  





    </Wrapper>

  
    );
  }
}
/////////////////////////////////////////////////////////////////////////////////////////////////////
