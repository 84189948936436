import React, { useState } from 'react'
import { Flex, Flexed, Input, Label, Textauto } from '../../../utilities/Shared'
import Sheet from './Sheet'

const FormSetup = ({forms,form,setForms,index,quants}) => {


    const [bleed, setBleed] =  useState(.125)
    const [edges, setEdges] =  useState(.125)
    const [tail, setTail] =  useState(.125)
    const [colorbar, setColorbar] =  useState(.250)
    const [colorbarbuff, setColorbarbuff] =  useState(.125)
    const [gripper, setGripper] =  useState(.250)
const setnew = (e,item) => {
    const newArray = [...forms];
    newArray[index][item] = e.target.value;
    setForms(newArray);
}


const getitems = () => {

}


  return (
  <>
        <Flexed color="#418DA7" id="bottom">

            <Flex flex="2">
<Flexed>
<Flex flex="2"><Label>Form Name</Label><Input value={forms[index]?.name} onChange={e => setnew(e,'name')}/></Flex>
        <Flex><Label>Piece W</Label><br /><Input value={forms[index]?.pieceW } onChange={e => setnew(e,'pieceW')}/></Flex>  
        <Flex><Label>Piece L</Label><br /><Input value={forms[index]?.pieceL } onChange={e => setnew(e,'pieceL')}/></Flex>   
        <Flex><Label>Finish W</Label><br /><Input value={forms[index]?.finishW } onChange={e => setnew(e,'finishW')}/></Flex>  
        <Flex><Label>Finish L</Label><br /><Input value={forms[index]?.finishL } onChange={e => setnew(e,'finishL')}/></Flex>  
        
</Flexed>
<Flexed>
<Flex><Label>Name</Label><br /><Input value={forms[index]?.pieceW } onChange={e => setnew(e,'pieceW')}/></Flex>  
<Flex><Label>Quantity</Label><br /><Input value={forms[index]?.pieceL } onChange={e => setnew(e,'pieceL')}/></Flex>  
        <Flex><Label>Piece W</Label><br /><Input value={forms[index]?.pieceW } onChange={e => setnew(e,'pieceW')}/></Flex>  
        <Flex><Label>Piece L</Label><br /><Input value={forms[index]?.pieceL } onChange={e => setnew(e,'pieceL')}/></Flex>   
        <Flex><Label>Finish W</Label><br /><Input value={forms[index]?.finishW } onChange={e => setnew(e,'finishW')}/></Flex>  
        <Flex><Label>Finish L</Label><br /><Input value={forms[index]?.finishL } onChange={e => setnew(e,'finishL')}/></Flex>  
        
</Flexed>
<Flexed marginTop="10px">

    <Flex>
        </Flex>
        </Flexed>
        <Flexed marginTop="20px">
     
            <Flex><Label>Qty 1</Label><Input value={forms[index]?.q1} onChange={e => setnew(e,'q1')}/></Flex>
            <Flex><Label>Add'l</Label><Input value={forms[index]?.q2} onChange={e => setnew(e,'q2')}/></Flex>
            <Flex><Label>Versions</Label><Input value={forms[index]?.versions} onChange={e => setnew(e,'versions')}/></Flex>
        <Flex><Label>Pages</Label><Input value={forms[index]?.pages} onChange={e => setnew(e,'pages')}/></Flex>
        <Flex><Label>Forms</Label><Input value={forms[index]?.forms} onChange={e => setnew(e,'forms')}/></Flex>
            <Flex><Label># Up</Label><br /><Input value={forms[index]?.pressnumup } onChange={e => setnew(e,'pressnumup ')}/></Flex>   
          
        </Flexed>
        </Flex> 
  
    <Flex padding="0"  style={{position:"relative",border:"1px solid black",minWidth:"400px",minHeight:"280px",maxWidth:"600px",maxHeight:"420px",display:"flex",alignItems:"center",justifycontent:"center",flexDirection:"column"}}>
        <Sheet width={forms[index]?.pressW} length={forms[index]?.pressL} tail={tail} colorbar={colorbar} colorbarbuff={colorbarbuff} edges={edges} gripper={gripper} quants={quants}/>
        <Flexed marginTop="10px">
            <Flex><Label>Parent L</Label><br /><Input value={forms[index]?.parentL} onChange={e => setnew(e,'parentL')}/></Flex>  
        <Flex><Label>Parent W</Label><br /><Input value={forms[index]?.parentW} onChange={e => setnew(e,'parentW')}/></Flex>  
        <Flex><Label>Parent Out</Label><br /><Input value={forms[index]?.parentout } onChange={e => setnew(e,'parentout ')}/></Flex> 
         </Flexed>
         <Flexed marginTop="10px">
         <Flex><Label>Press L</Label><br /><Input value={forms[index]?.pressL } onChange={e => setnew(e,'pressL')}/></Flex>  
         <Flex><Label>Press W</Label><br /><Input value={forms[index]?.pressW } onChange={e => setnew(e,'pressW')}/></Flex>  
        <Flex></Flex> 
         </Flexed>
      
        <Flexed marginTop="10px" >
        <Flex><Label>Bleed</Label><br /><Input value={bleed } onChange={e => setBleed(e.target.value)}/></Flex>  
            <Flex><Label>Edges</Label><br /><Input value={edges } onChange={e => setEdges(e.target.value)}/></Flex>   
            <Flex><Label>Tail</Label><br /><Input value={tail } onChange={e => setTail(e.target.value)}/></Flex> 
            <Flex><Label>Colorbar</Label><br /><Input value={colorbar } onChange={e => setColorbar(e.target.value)}/></Flex>   
 
        </Flexed>
    </Flex>
   
</Flexed>

<br />
          
{/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
<Flexed>
            <Flex fontSize="calc(1.3rem + .2vw)" >
         <div style={{border:"2px solid #BCDDA0",fontSize:"calc(1.2rem + .2vw)",padding:"10px"}}>  8.500 X 11.000 running 3 up on a 14.000 x 28.000 sheet<br />
0 Form(s) - 1 Version(s)<br />
Sheetwise on Ricoh Color with 1 passe(s)<br />
2 out on a 28.000 x 40.000 sheet<br />
100# Anthem Matte Cover<br />
C,M,Y,K<br />
Qty1 - 100 / 3 up * forms * 1 versions = 0 + 5% = 0<br />
0 + 0 M/R = 0<br />
Add'l - 0 / 3 up * forms * 1 versions = 0 + 5% = 0<br />
</div>
</Flex>
<Flex fontSize="calc(1.3rem + .2vw)" >
             <div style={{padding:"12px 30px",border:"2px solid #BCDDA0"}}>
                
            <Flexed fontSize="calc(1.1rem + .2vw)" fontWeight="bold" borderbottom="1px solid black">
    <Flex ></Flex>
    <Flex >HRS</Flex>
    <Flex >100</Flex>
    <Flex ></Flex>
</Flexed>
            <Flexed fontSize="calc(1.1rem + .2vw)" fontWeight="bold" borderbottom="1px solid black">
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>Press</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{padding:"8px 0px"}}>&nbsp; </Flex>
</Flexed>
            <Flexed fontSize="calc(1.1rem + .2vw)" fontWeight="bold" borderbottom="1px solid black">
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>Inks</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{padding:"8px 0px"}}>&nbsp; </Flex>
</Flexed>
<Flexed fontSize="calc(.9rem + .2vw)" fontWeight="bold" borderbottom="1px solid black" >
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>Wash-Up</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{padding:"8px 0px"}}>&nbsp; </Flex>
</Flexed>
<Flexed fontSize="calc(.9rem + .2vw)" fontWeight="bold" borderbottom="1px solid black" >
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>Blanket</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{padding:"8px 0px"}}>&nbsp; </Flex>
</Flexed>
<Flexed fontSize="calc(.9rem + .2vw)" fontWeight="bold" borderbottom="1px solid black" >
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>Cuts</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{padding:"8px 0px"}}>&nbsp; </Flex>
</Flexed>
<Flexed fontSize="calc(.9rem + .2vw)" fontWeight="bold" borderbottom="1px solid black" >
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>Folds</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{padding:"8px 0px"}}>&nbsp; </Flex>
</Flexed>
<Flexed fontSize="calc(.9rem + .2vw)" fontWeight="bold" borderbottom="1px solid black" >
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>Die Cutting</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{padding:"8px 0px"}}>&nbsp; </Flex>
</Flexed>
<Flexed fontSize="calc(.9rem + .2vw)" fontWeight="bold" borderbottom="1px solid black" >
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>Drilling</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{padding:"8px 0px"}}>&nbsp; </Flex>
</Flexed>
<Flexed fontSize="calc(.9rem + .2vw)" fontWeight="bold" borderbottom="1px solid black" >
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>Duplo</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{padding:"8px 0px"}}>&nbsp; </Flex>
</Flexed>
<Flexed fontSize="calc(.9rem + .2vw)" fontWeight="bold" borderbottom="1px solid black" >
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>O Corner</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{padding:"8px 0px"}}>&nbsp; </Flex>
</Flexed>
<Flexed fontSize="calc(.9rem + .2vw)" fontWeight="bold" borderbottom="1px solid black" >
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>Hand Work</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{padding:"8px 0px"}}>&nbsp; </Flex>
</Flexed>
<Flexed fontSize="calc(.9rem + .2vw)" fontWeight="bold" borderbottom="1px solid black" >
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>Additional</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{padding:"8px 0px"}}>&nbsp; </Flex>
</Flexed>
<Flexed fontSize="calc(.9rem + .2vw)" fontWeight="bold" borderbottom="1px solid black" >
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>Total</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{borderRight:"1px solid black",padding:"8px 0px"}}>&nbsp; </Flex>
    <Flex style={{padding:"8px 0px"}}>&nbsp; </Flex>
</Flexed>
</div> 
     </Flex>
    </Flexed>
        <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />
        </>
  )
}

export default FormSetup







/*
nate - 640f438fddce01cbed2ee067
pam - 640f438fddce01cbed2ee06d
celeste - 640f438fddce01cbed2ee0bb
jj - 67a4d5a74deb20b6115a7f37
thom - 640f438fddce01cbed2ee08c
meredith - 640f438fddce01cbed2ee06e
paul - 6580869bd52aa02c41a3f435




kelly - 640f438fddce01cbed2ee0b4

*/