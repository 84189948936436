import React, { useEffect, useState,useRef, useContext } from 'react'
import { Flex, Flexed, Input, Label, MainWrap, Selectbox, Textarea, Textauto } from '../../../utilities/Shared'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Colorbar, Content, Gripper, InnerContent, Sheet, Tail } from './EstimatesStyles'
import { CalendarContainer } from 'react-datepicker'
import {BookCopy, Delete, List, Paperclip, Plus, X} from 'lucide-react'
import '../../../utilities/Tabs.css'
import FormSetup from './FormSetup';
import EstContext from '../../../Context/EstContext';

const SetLayout = () => {


const {RFQ,forms,setForms,addform,removeform,info} = useContext(EstContext)

    const [quants, setQuants] = useState(['500','1000','1500','2000','2500'])
    const [totalwidth, setTotalwidth] = useState(0)
    const [totalheight, setTotalheight] = useState(0)
    const [length, setLength] = useState(24)
    const [width, setWidth] = useState(36)
    const [q1, setQ1] = useState(0)
    const [q2, setQ2] = useState(0)

    


useEffect(() => {
setQ1(quants[0])
setQ2((quants[quants.length - 1] - quants[0]))


},[quants])



    const [projectinfo, setProjectinfo] = useState({"flatWidth":11,"flatLength":8.5,"finishWidth":3.625,"finishLength":8.5,"pages":2,"versions":1,"coverStyle":"+ Cover",pressCheck:"No"})


    const [scale, setScale] = useState(20)



    const colorbar = .250
    const gripper = .5
   const edges = .250
   const tail = .250
   const tailpad = .0625

   let quantbox = useRef()
   useEffect(() => {
setTotalheight(length - colorbar - tail - gripper - edges - edges)
   },[])

    const getnewquants = () => {
     
     
    }
    getnewquants()





const checkquans = (e) => {
    if (e.key === "Enter" && e.target.value > "") {
        e.preventDefault()
  if(quants.includes(e.target.value)){
      quantbox.current.value = ""
  return
  }else{
    e.preventDefault()
    let ll = [...quants,e.target.value]
    ll.sort((a, b) => (parseInt(a) > parseInt(b)) ? 1 : -1)
  setQuants(ll)
  quantbox.current.value = ""
  }
      }
}

const removequant = (item) => {
let u = quants.filter(filt => parseInt(filt) !== parseInt(item))
setQuants(u)
}


    return (
        <MainWrap>
<div style={{padding:"20px",}}>
<Flexed   color="#418DA7" padding="10px 0px 10px 0px" alignItems="flex-end" fontSize="calc(1rem + .2vw)">
    <Flex fontSize='calc(2rem + .2vw)' fontWeight="bold">Quote {RFQ}</Flex>
    <Flex fontWeight="bold"></Flex>
    <Flex></Flex>
    <Flex flex="0" title="Print Estimate" padding="0px 20px"><List  size="28" /></Flex>
    <Flex flex="0" title="Copy Estimate"padding="0px 20px"><BookCopy size="26" /></Flex>
</Flexed>
<hr style={{border:"1px solid #418DA7",margin:"25px 0px 15px 0px"}}/>
<Flexed   color="#418DA7" padding="10px 0px 5px 0px" alignItems="flex-end" gap="25px"> 
    <Flex><Label padding="3px 0px">Customer</Label><br /><Input /></Flex>
    <Flex><Label padding="3px 0px">Project</Label><br /><Input /></Flex>
    <Flex><Label padding="3px 0px">Project Style</Label><br /><Input /></Flex>
</Flexed>
<Flexed   color="#418DA7" padding="10px 0px 5px 0px" alignItems="flex-end"  gap="25px"> 
    <Flex><Label padding="3px 0px">Contact</Label><br /><Input /></Flex>
    <Flex><Label padding="3px 0px">Sales Rep</Label><br /><Input /></Flex>
    <Flex><Label padding="3px 0px">CSR</Label><br /><Input /></Flex>
    <Flex><Label padding="3px 0px">Job Due Date</Label><br /><Input /></Flex>
</Flexed>
<Flexed   color="#418DA7" padding="10px 0px 5px 0px" alignItems="flex-end"  gap="25px"> 
    <Flex flex=".5"><Label padding="3px 0px">Prev Job#</Label><br /><Input /></Flex>
    <Flex flex=".5"><Label padding="3px 0px">Prev Quote #</Label><br /><Input /></Flex>
    <Flex><Label padding="3px 0px">Estimator</Label><br /><Input /></Flex>
    <Flex><Label padding="3px 0px">Whole Job As </Label><br /><Input /></Flex>
    <Flex><Label padding="3px 0px">Priority </Label><br /><Input /></Flex>
</Flexed>
<hr style={{border:"1px solid #418DA7",margin:"25px 0px 15px 0px"}}/>
<Flexed   color="#418DA7" padding="10px 0px 5px 0px" alignItems="flex-end"> 
    <Flex flex=".5" ><Label padding="3px 0px">Quantites</Label>&nbsp;&nbsp;&nbsp;
    <span style={{position:"relative",left:"10px"}}><Input width="35%" ref={quantbox} placeholder="Add Quantity Here" onKeyDown={e => checkquans(e)}/></span></Flex>
</Flexed>  
<Flexed   > 
<Flex >
    <Flexed flexWrap="wrap" color="#418DA7" padding="10px 0px 5px 0px" >
{
        quants?.map((item,index) => {
return    <Flex  key= {index} flex="0" ><div style={{position:"relative",width:"130px"}}><Input value={item} fontSize="calc(1.1rem + .2vw)" maxlength="8" maxWidth="130px" padding="6px 40px 6px 4px" /><X strokeWidth={3} style={{position:"absolute",left:"98px",top:"3px"}} color="rgb(255,0,0,.6)" onClick={e => removequant(item)} /></div></Flex>
        })
    }
    </Flexed>
    </Flex>
    <Flex style={{marginTop:"-40px"}}>
      <Textauto minRows="7"/> 
</Flex>
</Flexed>
<hr style={{border:"1px solid #418DA7",margin:"15px 0px 15px 0px"}}/>
<Flexed >
    <Flex>
        <Flexed   color="#418DA7"  alignItems="flex-end"  gap="25px"> 
    <Flex flex=".5"><Label padding="3px 0px">Flat W</Label><br /><Input value={projectinfo['flatWidth']} onChange={e => setProjectinfo({...projectinfo,flatWidth:e.target.value})} /></Flex>
    <Flex flex=".5"><Label padding="3px 0px">Flat L</Label><br /><Input value={projectinfo['flatLength']} onChange={e => setProjectinfo({...projectinfo,flatLength:e.target.value})} /></Flex>
    <Flex flex=".7"><Label padding="3px 0px">Final W</Label><br /><Input value={projectinfo['finishWidth']} onChange={e => setProjectinfo({...projectinfo,finishWidth:e.target.value})} /></Flex>
    <Flex flex=".7"><Label padding="3px 0px">Final L </Label><br /><Input value={projectinfo['finishLength']} onChange={e => setProjectinfo({...projectinfo,finishLength:e.target.value})} /></Flex>
    <Flex flex=".5"><Label padding="3px 0px">Pages</Label><br /><Input type="number" step="2" min="0" value={projectinfo['pages']} onChange={e => setProjectinfo({...projectinfo,pages:e.target.value})} /></Flex>
    <Flex flex=".5"><Label padding="3px 0px">Ver's</Label><br /><Input type="number" step="1" min="0"  value={projectinfo['versions']} onChange={e => setProjectinfo({...projectinfo,versions:e.target.value})}/></Flex>
    <Flex flex="2"><Label padding="3px 0px">Cover Style</Label><br />
    <Selectbox value={projectinfo['coverStyle']} onChange={e => setProjectinfo({...projectinfo,coverStyle:e.target.value})}>
        <option value="None">None</option>
        <option value="+ Cover">+ Cover</option>
        <option value="Self Cover">Self Cover</option>
    </Selectbox>
    </Flex>
    <Flex minHeight="55px" flex="2"><Label padding="3px 0px">Attach PDF's</Label>&nbsp;&nbsp;<Paperclip  size="18"/><br />
    No Attachments 
    </Flex>
</Flexed>

</Flex>
    
</Flexed>

<hr style={{border:"1px solid #418DA7",margin:"25px 0px 15px 0px"}}/>


<Tabs>

 <TabList> 

     <Tab>Press</Tab>
     <Tab>Components</Tab>
    <Tab>Bindery</Tab>
    <Tab>Mailing</Tab>
    <Tab>OSP</Tab>
    <Tab>Cost Summary</Tab>
    </TabList>
        <TabPanel>
<Tabs>

    <TabList>
    <span style={{position:"relative",top:"10px",marginRight:"10px",textAlign:"left"}} onClick={e => addform()}><Plus color="green" size="24" strokeWidth={3}/></span>
        {forms?.map((form,index) => {
 return <Tab key= {index} >{form?.name} &nbsp; <X size="20" style={{position:"relative",top:"3px"}} color="red" strokeWidth={3} onClick={e => {removeform(e,form?.itemid);
   }} /></Tab>
        })
        }
    </TabList>
        {
            forms?.map((form,index) => {
              return  <TabPanel key= {index} ><FormSetup forms={forms} form={form} setForms={setForms} index={index} quants={quants}/></TabPanel>
            })
        }
</Tabs>




        </TabPanel>
        <TabPanel>2</TabPanel>
         <TabPanel>3</TabPanel>
         <TabPanel>4</TabPanel>
        <TabPanel>5</TabPanel>
        <TabPanel>6</TabPanel>
 </Tabs>








    </div>
        </MainWrap>
      )
}















const Estimates = () => {
  return (
 <SetLayout />
  )
}

export default Estimates