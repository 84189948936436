import { styled } from "styled-components";

export const HeaderWrap = styled.div`
width:100%;
height:var(--Header-height);
background-color:white;
position:fixed;
top:0;
z-index:100;
`;

export const LOGO = styled.img`
height:calc(var(--Header-height) - 5px);
`;

export const Logout = styled.div`
height:70%;
background-color:var(--plsBlue);
width:200px;
color:white;
position:relative; 
right:-3px;
font-size:calc(3rem + .4vw);
padding:5px 0px;

z-index:3000;

`;

export const Div = styled.div`
flex:1;
font-size:var(--headerLarge);
padding:10px 0px 10px 47px;
font-weight:bold;
transition: .2s ease;

&:hover{
  background-color:white;
  color:#0E425A;
  border:1px solid #0E425A;

}
&:last-child{
  border-bottom-left-radius:4px;
  border-bottom-right-radius:4px;
}
`;