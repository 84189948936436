import React, { useContext, useEffect, useState } from 'react'
import { Flex, Flexed, Input, MainWrap, PhoneNumber, Scroll, Selectbox, currency } from '../../utilities/Shared'
import { IoMdAdd, IoMdAddCircle } from 'react-icons/io'
import { GiHamburgerMenu } from 'react-icons/gi'
import { useNavigate,useParams } from 'react-router-dom'
import axios from 'axios'
import EditCustomer from './EditCustomer'
import Modal from '../../modals/Modal'
import {useCollapse} from 'react-collapsed';
import {LiaMinusSolid} from 'react-icons/lia'
import Collapse from '../../utilities/Collapse'
import { AiFillEdit } from 'react-icons/ai'
import NewLocation from './NewLocation'
import NewContact from './NewContact'
import EditContact from './EditContact'
import EditLocation from './EditLocation'
import Moment from 'react-moment'
import Spin from '../../components/Spinner/Spin'
import UserContext from '../../Context/UserContext'
import { toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid';



const Customer = () => {
    const [isExpanded, setExpanded] = useState(false)
    const { getCollapseProps, getToggleProps } = useCollapse();
    const [vendorinfo, setVendorinfo] = useState()
    const [editVendor, setEditVendor] = useState(false)
    const [reset, setReset] = useState(false)
    const [poslist, setPolist] = useState([])
    const [positems, setItems] = useState([])
    const [main, setMain] = useState({})
    const [mainc, setMainc] = useState({})


    const [formidnum, setFormidnum] = useState(null)
    const [show, setShow] = useState({})
    const [currentcontact, setCurentcontact] = useState({})
    const [curentlocation, setCurentlocation] = useState({})
    const [updateshow, setUpdateshow] = useState({})
    const [contactshow, setContactshow] = useState(false)
    const [locationshow, setLocationshow] = useState(false)
    const [editContactshow, setEditContactshow] = useState(false)
    const [editLocationshow, setEditLocationshow] = useState(false)
    const [oldjobs, setOldJobs] = useState([])
    const [jobs, setJobs] = useState([])
    const [quotes, setQuotes] = useState([])
    const [price, setPrice] = useState()
    const nav = useNavigate()
const param = useParams()

const [today, setToday] = useState()
const [week, setWeek] = useState()
const [lastweek, setLastweek] = useState()
const [month, setMonth] = useState()
const [qtd, setQTD] = useState()
const [ytd, setYTD] = useState()

const [search, setSearch] = useState("")
const [searchquote, setSearchquote] = useState("")
const {userInfo,spin,setSpin } = useContext(UserContext)
useEffect(() => {  
    setSpin(true)
const y = async() => {
    await axios.get(`https://highgroundapi.herokuapp.com/customers/item/${param.id}`)
.then(res => {
   
    setVendorinfo(res.data)
    getToday(res.data.company_id)
    
    let n = res.data.address.filter(item => item.itemid === res.data.mainlocation)
    setMain(n)
    let m = res.data.contacts.filter(item => item.contactid === res.data.maincontact)
    setMainc(m)
get(res.data)
   
})
.catch(err => console.log(err))
}

const z = () => {
    const t = {"id":param.id}
    //axios.get(`https://highgroundapi.herokuapp.com/vendor/vendor/${param.id}`)
    axios.post(`https://highgroundapi.herokuapp.com/po/vendor/get/all`,t)
    .then(res => {setPolist(res.data);setItems(res.data.items)})
    .catch(err => console.log(err))
    }

    const quotes = async(info) => {
        const config = {
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin':'*' 
            }
          };   
        const t = {
            customer:info.name
        }

        axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getallquotes.php`,t,config)
        .then(res => {setQuotes(res.data);setSpin(false)})
        .catch(err => {console.log(err);setSpin(false)})
    }

    const get = async(info) => {
        const config = {
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin':'*' 
            }
          };   
        const t = {
            customerid:info.company_id
        }
        axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getalljobs.php`,t,config)
        .then(res => {setJobs(res.data);quotes(info);getoldjobs(t.customerid)})
        .catch(err => {console.log(err);setSpin(false)})
    }
y()
//z()
},[reset])

const getToday = (company_id) => {
    let e = 0
    const config = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin':'*' 
        }
      };   
    const t = {
        customerid:company_id
    }

    axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getcustomersalestoday.php`,t,config)
    .then(res => {
  
res.data.map(item => {
    e += parseFloat(item.currentprice)
})
setToday(e)
getWeek(company_id)
    })
    .catch(err => {console.log(err);setSpin(false)})
}

const getWeek = (company_id) => {
    let e = 0
    const config = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin':'*' 
        }
      };   
    const t = {
        customerid:company_id
    }
 
    axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getcustomersalesweek.php`,t,config)
    .then(res => {
      
res.data.map(item => {
    e += parseFloat(item.currentprice)
})
setWeek(e)
getLastWeek(company_id)
    })
    .catch(err => {console.log(err);setSpin(false)})
}


const getLastWeek = (company_id) => {
    let e = 0
    const config = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin':'*' 
        }
      };   
    const t = {
        customerid:company_id
    }

    axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getcustomersaleslast.php`,t,config)
    .then(res => {
res.data.map(item => {
    e += parseFloat(item.currentprice)
})
setLastweek(e)
getMonth(company_id)
    })
    .catch(err => {console.log(err);setSpin(false)})
}

const getMonth = (company_id) => {
    let e = 0
    const config = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin':'*' 
        }
      };   
    const t = {
        customerid:company_id
    }

    axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getcustomersalesmonth.php`,t,config)
    .then(res => {
   
res.data.map(item => {
    e += parseFloat(item.currentprice)
})
setMonth(e)
getQTD(company_id)
    })
    .catch(err => {console.log(err);setSpin(false)})
}


const getQTD = (company_id) => {
    let e = 0
    const config = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin':'*' 
        }
      };   
    const t = {
        customerid:company_id
    }
 
    axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getcustomersalesqtd.php`,t,config)
    .then(res => {

res.data.map(item => {
    e += parseFloat(item.currentprice)
})
setQTD(e)
getYTD(company_id)
    })
    .catch(err => {console.log(err);setSpin(false)})
}

const getYTD = (company_id) => {
    let e = 0
    const config = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin':'*' 
        }
      };   
    const t = {
        customerid:company_id
    }
  
    axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getcustomersalesytd.php`,t,config)
    .then(res => {
     
res.data.map(item => {
    e += parseFloat(item.currentprice)
})
setYTD(e)
    })
    .catch(err => {console.log(err);setSpin(false)})
}


const getoldjobs = (id) => {
    const config = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin':'*' 
        }
      };   
    const t = {
        customerid:id
    }
    axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getoldjobs.php`,t,config)
        .then(res => {setOldJobs(res.data)})
        .catch(err => {console.log(err);})  
}




useEffect(() => {
let r = 0
    if(jobs.length > 0){
        jobs.map((item,index) => {
r =+ parseInt(item.currentprice)
        })
        setPrice(r)
    }

},[jobs])



const createlink = (e) => {
e.preventDefault()
let email
let form = e.target.value

const setforminfo = async() => {
   let t = {
        id:vendorinfo._id,
        field:'formid',
        value:uuidv4()
    }
 
    await axios.post(`https://highgroundapi.herokuapp.com/customers/update/field`,t)
    .then(res => {
      email = `https://app.highgroundsoftware.com/information?cid=${vendorinfo?._id}&qid=${res.data.formid}`;
     navigator.clipboard.writeText(email)
toast.success('Link copied to clipboard')
})
    .catch(err => {console.log(err);})    
}
setforminfo()
}


 
  return (
    <MainWrap padding="100px 35px 10px 30px" >
      
            <Modal show={editVendor} headerbgcolor="#8498AC" width="75%" headercolor="#144259" header={`Edit Details`} onCancel={() => {setEditVendor(false)}} ><EditCustomer onCancel={() => {setEditVendor(false)}}  reset={reset} setReset={setReset} customer={vendorinfo}/></Modal>
            <Modal show={editContactshow} header={`Edit Contact`} onCancel={() => {setEditContactshow(false)}} ><EditContact onCancel={() => {setEditContactshow(false)}}  reset={reset} setReset={setReset} customer={currentcontact} customerid={vendorinfo?._id}/></Modal>
            <Modal show={editLocationshow} header={`Edit Location`} onCancel={() => {setEditLocationshow(false)}} ><EditLocation onCancel={() => {setEditLocationshow(false)}}  reset={reset} setReset={setReset} customer={curentlocation}/></Modal>
            <Modal show={contactshow} header={`Add New Contact`} onCancel={() => {setContactshow(false)}} ><NewContact reset={reset} setReset={setReset}  customerid={vendorinfo?._id} onCancel={() => {setContactshow(false)}}/></Modal>
            <Modal show={locationshow} header={`Add New Location`} onCancel={() => {setLocationshow(false)}} ><NewLocation reset={reset} setReset={setReset}  customerid={vendorinfo?._id} onCancel={() => {setLocationshow(false)}}/></Modal>
          
    <Flexed color="#418DA7" margin="0px" fontWeight="600">
    {spin && <Spin asOverlay />} 
    <Flex fontSize="calc(.7rem + .4vw)" flex="2.5" color={vendorinfo?.pros === "1"? "#7ABB43" : "#EA8D1F"} >{vendorinfo?.pros === "1"? "Customer" : "Prospect"} # {vendorinfo?.company_id}</Flex>
    <Flex padding="0px 0px 0px 0px" fontSize="calc(.7rem + .4vw)" flex="2">Main Address</Flex>
    <Flex flex="1.4" padding="0px 0px 0px 0px" fontSize="calc(.7rem + .4vw)">Main Phone</Flex>
    <Flex flex="1.5" padding="0px 0px 0px 0px" fontSize="calc(.7rem + .4vw)">Contact</Flex>
    <Flex flex="1.5" padding="0px 0px 0px 0px" fontSize="calc(.7rem + .4vw)"> Reps</Flex>
   {/* <Flex padding="0px 0px 0px 0px" fontSize="calc(.7rem + .4vw)" flex="1.5">Email</Flex>*/}
    </Flexed>
    
    <Flexed color="rgb(0,0,0,.7)" padding="0px" >

    <Flex flex="2.5" fontSize="calc(1.7rem + .4vw)" fontWeight="bold" >{vendorinfo?.name}<br /><span className="hand"  onClick={e => {setEditVendor(true)}} style={{bottom:"0px",width:"100%",position:"relative",fontWeight:"400",fontSize:"calc(.4rem + .4vw)",color:"limegreen",display:"block",}}>
    <GiHamburgerMenu style={{fontSize:"1rem",position:"relative",bottom:"-3px"}} className="hand" />&nbsp;Edit Details</span><br />
    {userInfo.departments?.some(cat => cat.label === "Administration") || userInfo.departments?.some(cat => cat.label === "Sales")?<span><Selectbox width="40%" value="" style={{fontSize:".9rem",position:"relative",top:"-30px"}} onChange={e => createlink(e)}>
    <option value="">Generate a Link</option>
        <option value="form">Generate Customer Form Link</option>
        <option value="credit" disabled>Generate Credit App Link</option>
        </Selectbox></span> : null}
    </Flex>
    <Flex fontWeight="bold" flex="2" padding="6px 0px 0px 0px" fontSize="calc(.6rem + .4vw)">
    <div>{main[0]?.name}</div>
    <div>{main[0]?.address1}</div>
{main[0]?.address2 > "" && <div>{main[0]?.address2}</div>}
<div>{main[0]?.city && main[0]?.city+', '}{main[0]?.state}&nbsp;{main[0]?.zip}</div>

    </Flex>
    <Flex flex="1.4" fontWeight="bold" padding="6px 0px 0px 0px" fontSize="calc(.6rem + .4vw)">M:{PhoneNumber(vendorinfo?.mainphone)}<br /></Flex>
    <Flex  flex="1.5" fontWeight="bold" padding="6px 0px 0px 0px" fontSize="calc(.6rem + .4vw)">{mainc[0]?.name}<br />{mainc[0]?.email}<br />{PhoneNumber(mainc[0]?.officephone)}</Flex>
    <Flex  flex="1.5" fontWeight="bold" padding="6px 0px 0px 0px" fontSize="calc(.6rem + .4vw)">Sales:  {vendorinfo?.salesrep?.firstName} {vendorinfo?.salesrep?.lastName}<br />CSR:  {vendorinfo?.custrep?.firstName} {vendorinfo?.custrep?.lastName}</Flex>

   {/*} <Flex fontWeight="600" padding="6px 0px 0px 0px" fontSize="calc(.6rem + .4vw)" flex="1.5"><span className="hand" onClick={() => window.location = `mailto:${vendorinfo?.email}`}>{vendorinfo?.email}</span></Flex>*/}
    </Flexed>
    
    <Flexed bgColor="#8398AB" padding="3px 0px" fontWeight="bold" color="white" margin="25px 0px 0px 0px" >
        <Flex fontSize="var(--headerMedium)" textAlign="center">Today</Flex>
        <Flex fontSize="var(--headerMedium)" textAlign="center">This Week</Flex>
        <Flex fontSize="var(--headerMedium)" textAlign="center">Last Week</Flex>
        <Flex fontSize="var(--headerMedium)" textAlign="center">This Month</Flex>
        <Flex fontSize="var(--headerMedium)" textAlign="center">QTD</Flex>
        <Flex fontSize="var(--headerMedium)" textAlign="center">YTD</Flex>
    </Flexed>
    <Flexed padding="3px 0px" fontWeight="bold"  color="rgb(0,0,0,.7)" bgColor="white">
        <Flex textAlign="center">${currency(today)}</Flex>
        <Flex textAlign="center">${currency(week)}</Flex>
        <Flex textAlign="center">${currency(lastweek)}</Flex>
        <Flex textAlign="center">${currency(month)}</Flex>
        <Flex textAlign="center">${currency(qtd)}</Flex>
        <Flex textAlign="center">${currency(ytd)}</Flex>
    </Flexed>

    <Flexed bgColor="#8398AB" padding="3px 0px" fontWeight="bold" color="white" margin="30px 0px 0px 0px">
    <Flex fontSize="var(--headerMedium)" >CONTACTS</Flex><IoMdAddCircle style={{fontSize:"2rem"}} onClick={() => setContactshow(true)}/>
</Flexed>

<Flexed padding="3px 0px"  color="#418DA7" bgColor="white" margin="0">
    <Flex fontWeight="bold" fontSize="10pt" textAlign="left" flex="1.3">Name</Flex>
    <Flex fontWeight="bold" fontSize="10pt" textAlign="left">Title</Flex>
    <Flex fontWeight="bold" fontSize="10pt" textAlign="left">Office Phone</Flex>
    <Flex fontWeight="bold" fontSize="10pt" textAlign="left">Mobile Phone</Flex>
    <Flex fontWeight="bold" fontSize="10pt"  flex="1.4" textAlign="left">Email</Flex>
    <Flex fontWeight="bold" fontSize="10pt" textAlign="left"> Invoices</Flex>
</Flexed>
<Scroll height="225px" >
{vendorinfo?.contacts?.map(contact => {
return(
    
<Flexed bgColor="white" className="hand normalrow" padding="3px 0px"  color="rgb(0,0,0,.7)" margin="0" borderBottom="1px solid rgb(0,0,0,.5)"  onClick={() => {setCurentcontact(contact);setEditContactshow(true)}}>
    <Flex textAlign="left" flex="1.3">{contact?.name}</Flex>
    <Flex textAlign="left">{contact?.title}</Flex>
    <Flex textAlign="left">{PhoneNumber(contact?.officephone) !== '(0)  - ' && PhoneNumber(contact?.officephone)}{contact?.ext > "0" && <>  Ext:{contact.ext}</>}</Flex>
    <Flex textAlign="left">{PhoneNumber(contact?.cell) !== '(0)  - ' && PhoneNumber(contact?.cell)}</Flex>
    <Flex  flex="1.4" textAlign="left" onClick={() => window.location = `mailto:${contact?.email}`}>{contact?.email}</Flex>
    <Flex >{contact?.invoices}</Flex>
</Flexed>


)
})
}
</Scroll>
    <Flexed bgColor="#8398AB" padding="3px 0px" fontWeight="bold" color="white" margin="30px 0px 0px 0px">
        <Flex fontSize="var(--headerMedium)">LOCATIONS</Flex><IoMdAddCircle  style={{fontSize:"2rem"}} onClick={() => setLocationshow(true)}/>
    </Flexed>
    <Flexed padding="3px 0px" fontWeight="bold"  color="#418DA7" bgColor="white" margin="0" >
        <Flex fontWeight="bold" fontSize="10pt" flex="1.5" textAlign="left" >Name</Flex>
        <Flex fontWeight="bold" fontSize="10pt" flex="1.5" textAlign="left" >Address</Flex>
        <Flex fontWeight="bold" fontSize="10pt" flex="1.5" textAlign="left">City</Flex>
        <Flex fontWeight="bold" fontSize="10pt" flex=".5" textAlign="left">State</Flex>
        <Flex fontWeight="bold" fontSize="10pt" textAlign="left" flex=".5">Zip</Flex>
        <Flex fontWeight="bold" fontSize="10pt" flex="1.1" textAlign="left">Phone</Flex>
        <Flex fontWeight="bold" fontSize="10pt" textAlign="left">Attn </Flex>

    </Flexed>
   <Scroll height="225px">
    {vendorinfo?.address?.map(location => {
    return(
    <Flexed bgColor="white" className="hand normalrow" padding="3px 0px"  color="rgb(0,0,0,.7)" margin="0" borderBottom="1px solid rgb(0,0,0,.5)" onClick={() => {setCurentlocation(location);setEditLocationshow(true)}}>
        <Flex  flex="1.5" textAlign="left" >{location?.name}</Flex>
        <Flex flex="1.5" textAlign="left" >{location?.address1}&nbsp;{location?.address2}</Flex>
        <Flex flex="1.5" textAlign="left">{location?.city}</Flex>
        <Flex flex=".5" textAlign="left">{location?.state}</Flex>
        <Flex flex=".5" textAlign="left">{location?.zip}</Flex>
        <Flex flex="1.1" textAlign="left">{PhoneNumber(location?.phone) !== '(0)  - ' && PhoneNumber(location?.phone)}</Flex>
        <Flex >{location?.attn}</Flex>
    
    </Flexed>
    )
    })
 }
   </Scroll>
        

    <>
  
    <Flexed bgColor="#8398AB" padding="3px 0px" fontWeight="bold" color="white" margin="30px 0px 10px 0px">
        <Flex fontSize="var(--headerMedium)" >JOBS&nbsp; &nbsp;&nbsp;{jobs.length + oldjobs.length}</Flex><IoMdAddCircle  style={{fontSize:"2rem"}} onClick={() => nav('/pos/new')}/>
    </Flexed>

    <Flexed margin="0px 0px 20px 0px"><Flex><Input type="text" value={search} placeholder="Search" onChange={e => setSearch(e.target.value)} /></Flex>
      </Flexed>
    <Flexed style={{borderTop:"1px solid black",padding:"2px",backgroundColor:"white",color:"var(--plsBlue)",fontWeight:"bold"}}>
    <Flex fontWeight="bold" fontSize="10pt">Job#</Flex>
<Flex fontWeight="bold" fontSize="10pt">PO#(s)</Flex>
<Flex fontWeight="bold" fontSize="10pt" >Qty</Flex>
<Flex fontWeight="bold" fontSize="10pt" flex="3">Job Name</Flex>
<Flex fontWeight="bold" fontSize="10pt">Date Entered</Flex>
<Flex fontWeight="bold" fontSize="10pt">Due Date</Flex>
<Flex fontWeight="bold" fontSize="10pt" flex=".1"></Flex>
</Flexed>


<Scroll height={jobs?.length > 1 ? "250px" : "135px"}>
{jobs.filter(item => {return search.toLowerCase() === '' ? item : item.jobname?.toLowerCase().includes(search.toLowerCase())  || item.job.toLowerCase().includes(search)}).map((item,index) => {
return   <>

      <Flexed key={index} margin="0" className="normalrow" onClick={e => window.location.replace(`/jobticket/${item?.job}`)} style={{borderTop:"1px solid black",padding:"4px" }}>
      <Flex>{item.job}</Flex>
<Flex>{item.custpo}</Flex>
<Flex >{item.quantity}</Flex>
<Flex flex="3">{item.jobname}</Flex>
<Flex><Moment format="MM/DD/YYYY">{item.dateentered}</Moment></Flex>
<Flex><Moment format="MM/DD/YYYY">{item.duedate}</Moment></Flex>
<Flex fontWeight="bold" fontSize="10pt" flex=".1"></Flex>
      </Flexed>

 </> 

})}

{oldjobs?.filter(ite => {return search === '' ? ite : ite.job?.includes(search) || ite.custpo?.toLowerCase().includes(search.toLowerCase()) || ite.jobname?.toLowerCase().includes(search.toLowerCase())}).map((item,index) => {
return   <>
  <Flexed key={index} margin="0" className="normalrow" onClick={e => window.location.replace(`https://highgroundsoftware.com/dash/dashboard/?des=777&q=${item.quotenum}&c=${item.customerid}&j=${item.job}`)} style={{borderTop:"1px solid black",padding:"4px" }}>
  <Flex>{item.job}</Flex>
<Flex>{item.po}</Flex>
<Flex >{item.quantity}</Flex>
<Flex flex="3">{item.jobname}</Flex>
<Flex><Moment format="MM/DD/YYYY">{item.dateentered}</Moment></Flex>
<Flex><Moment format="MM/DD/YYYY">{item.datedue}</Moment></Flex>
<Flex fontWeight="bold" fontSize="10pt" flex=".1"></Flex>
      </Flexed>
 </> 

})}
</Scroll>
</>

<Flexed gap="30px">
 {/*   <Flex> 
    <Flexed bgColor="#8398AB" padding="3px 0px" fontWeight="bold" color="white" margin="30px 0px 10px 0px">
        <Flex fontSize="var(--headerMedium)">RFQ(s)&nbsp; &nbsp;&nbsp;{poslist.length}</Flex><IoMdAddCircle  style={{fontSize:"2rem"}} onClick={e => window.location.replace(`https://highgroundsoftware.com/dash/dashboard/?des=10300`)}/>
    </Flexed>


    <Flexed style={{borderTop:"1px solid black",padding:"2px",backgroundColor:"white",color:"var(--plsBlue)",fontWeight:"bold"}}>
    <Flex fontWeight="bold"  fontSize="var(--headerMedium)">RFQ#</Flex>
<Flex fontWeight="bold" fontSize="10pt">Name</Flex>
<Flex fontWeight="bold" fontSize="10pt" >Qty</Flex>
<Flex fontWeight="bold" fontSize="10pt" flex="3">Entered</Flex>
<Flex fontWeight="bold" fontSize="10pt" flex=".1"></Flex>
</Flexed>
    
  <Scroll height="135px" style={{minHeight:"135px"}}>
{//jobs.map((item,index) => {
//return  
 <>

      <Flexed /* onClick={e => window.location.replace(`https://highgroundsoftware.com/dash/dashboard/?des=800&job=${item.job}`)}*//* margin="0" className="normalrow" style={{borderTop:"1px solid black",padding:"4px" }}>
      <Flex></Flex>
<Flex></Flex>
<Flex ></Flex>
<Flex flex="3"></Flex>
<Flex ></Flex>

      </Flexed>

 </> 

//})
}
</Scroll>
</Flex>
*/}

    <Flex> 
    <Flexed bgColor="#8398AB" padding="5px 0px" fontWeight="bold" color="white" margin="30px 0px 10px 0px">
        <Flex fontSize="var(--headerMedium)">QUOTES&nbsp; &nbsp;&nbsp;{poslist.length}</Flex>
    </Flexed>
    <Flexed margin="0px 0px 20px 0px"><Flex><Input type="text" value={searchquote} placeholder="Search Quotes" onChange={e => setSearchquote(e.target.value)} /></Flex>
      </Flexed>

    <Flexed style={{borderTop:"1px solid black",padding:"2px",backgroundColor:"white",color:"var(--plsBlue)",fontWeight:"bold"}}>
    <Flex fontWeight="bold" fontSize="10pt">Quote#</Flex>
<Flex fontWeight="bold" fontSize="10pt" flex="3">Name</Flex>
<Flex fontWeight="bold" fontSize="10pt" ></Flex>
<Flex fontWeight="bold" fontSize="10pt" >Approved</Flex>
<Flex fontWeight="bold" fontSize="10pt" flex=".1"></Flex>

</Flexed>
    
  <Scroll height={jobs?.length > 1 ? "250px" : "135px"}>
{quotes.filter(item => {return searchquote.toLowerCase() === '' ? item : item?.quote.toLowerCase().includes(searchquote.toLowerCase())  || item.pname.toLowerCase().includes(searchquote)}).map((item,index) => {
return   <>
     {/*window.location.replace(`https://highgroundsoftware.com/dash/dashboard/?des=2075&j=${item?.quote}`)*/}
      <Flexed key={index} margin="0" className="normalrow" onClick={e => nav(`/quote/letter/${item?.quote}`)} style={{borderTop:"1px solid black",padding:"4px" }}>
      <Flex>{item?.quote}</Flex>
<Flex flex="3">{item?.pname}</Flex>
<Flex ></Flex>
<Flex >{item?.jobname}</Flex>

      </Flexed>

 </> 

})}
</Scroll>
</Flex>
</Flexed>



    <br /><br /><br /><br /><br /><br /><br /><br /><br />
    </MainWrap>
  )
}

export default Customer



