import React from 'react'
import {MainWrap} from './Shared'
import DraggableGrid from 'ruuri';

const TEST = () => {

  const hh = [
    {
        "customer": "4",
        "Company": "Trividia Health",
        "Contact": "Julie Harrison",
        "Email": "jharrison@trividiahealth.com",
        "Phone": "(800) 342-7226",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "Pamela Brodeur",
        "": ""
    },
    {
        "customer": "5",
        "Company": "American Safety & Health",
        "Contact": "Carolyn Claman",
        "Email": "cclaman@hsi.com",
        "Phone": "(800) 447-3177",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "Pamela Brodeur",
        "": ""
    },
    {
        "customer": "6",
        "Company": "Ramada Plaza Resorts",
        "Contact": "",
        "Email": "",
        "Phone": "(954) 630-9449",
        "Sales Rep": "OUT OF BUS",
        "CSR": "OUT OF BUS",
        "": ""
    },
    {
        "customer": "7",
        "Company": "Time Customer Service",
        "Contact": "Receiving",
        "Email": "TI-TCS-Sabal.receiving@tcs-timeinc.com",
        "Phone": "(813) 903-3271",
        "Sales Rep": "OUT OF BUS",
        "CSR": "OUT OF BUS",
        "": ""
    },
    {
        "customer": "8",
        "Company": "MEDIC FIRST AID INC.",
        "Contact": "Bill Lucas",
        "Email": "blucas@hsi.com",
        "Phone": "(800) 447-3177",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "Pamela Brodeur",
        "": ""
    },
    {
        "customer": "9",
        "Company": "Direct Mail Systems, Inc.",
        "Contact": "Mike Milligan",
        "Email": "MikeMilligan@earthlink.net",
        "Phone": "(727) 573-1985",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "Pamela Brodeur",
        "": ""
    },
    {
        "customer": "11",
        "Company": "CCS Medical",
        "Contact": "Steve Wiehagen",
        "Email": "steve.wiehagen@ccsmed.com",
        "Phone": "(727) 507-2238",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "12",
        "Company": "Paradise, INC.",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 752-1155",
        "Sales Rep": "OUT OF BUS",
        "CSR": "OUT OF BUS",
        "": ""
    },
    {
        "customer": "13",
        "Company": "Tribute Technology / MKJ Marketing",
        "Contact": "Ben Chasnov",
        "Email": "bchasnov@mkjmarketing.com",
        "Phone": "(888) 655-1566",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "14",
        "Company": "AAMP Global",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 572-9255",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "Pamela Brodeur",
        "": ""
    },
    {
        "customer": "15",
        "Company": "GROW Financial Federal CU",
        "Contact": "Paul Boraske",
        "Email": "paul.boraske@growfinancial.org",
        "Phone": "(813) 837-2451",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "Pamela Brodeur",
        "": ""
    },
    {
        "customer": "16",
        "Company": "Cherry and Company",
        "Contact": "Liz Cherry",
        "Email": "",
        "Phone": "(727) 393-7737",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "17",
        "Company": "Cryo Cell International, Inc",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 749-2140",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "19",
        "Company": "Achieva Credit Union",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 431-7424",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "20",
        "Company": "Valpak",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 399-3000",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "21",
        "Company": "Signature Announcements",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 489-2050",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "22",
        "Company": "Exponential",
        "Contact": "",
        "Email": "",
        "Phone": "(866) 342-5264",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "23",
        "Company": "Bisk Education",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 621-6200",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "24",
        "Company": "Superior Uniform Group",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 397-9611",
        "Sales Rep": "OUT OF BUS",
        "CSR": "OUT OF BUS",
        "": ""
    },
    {
        "customer": "25",
        "Company": "MADICO",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 327-2544",
        "Sales Rep": "JJ Brenelli",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "26",
        "Company": "GBS Document Solutions",
        "Contact": "",
        "Email": "",
        "Phone": "(800) 390-0786",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "Pamela Brodeur",
        "": ""
    },
    {
        "customer": "27",
        "Company": "Colorfast Printing",
        "Contact": "Terry Mohamed",
        "Email": "",
        "Phone": "(727) 531-9506",
        "Sales Rep": "Celeste Hall",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "28",
        "Company": "SCP Pool Corp.",
        "Contact": "",
        "Email": "",
        "Phone": "(985) 892-5521",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "29",
        "Company": "IVIE and Associates, Inc",
        "Contact": "",
        "Email": "",
        "Phone": "(972) 899-5000",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "31",
        "Company": "DIRECT MARKETING SE",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 454-9566",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "Pamela Brodeur",
        "": ""
    },
    {
        "customer": "32",
        "Company": "Neighborly Care Network",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 573-9444",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "33",
        "Company": "Pure Solutions",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 925-1098",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "34",
        "Company": "PSCU Financial Services",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 318-6721",
        "Sales Rep": "Celeste Hall",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "35",
        "Company": "Citrus Hills Investment Properties, Inc",
        "Contact": "",
        "Email": "",
        "Phone": "(352) 746-3994",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "36",
        "Company": "American Express",
        "Contact": "",
        "Email": "",
        "Phone": "(866) 352-5919",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "Pamela Brodeur",
        "": ""
    },
    {
        "customer": "37",
        "Company": "Sogeval Laboratories",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 548-8345",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "38",
        "Company": "Florida College",
        "Contact": "JOCELYN SLONE",
        "Email": "SloneJ@FloridaCollege.edu",
        "Phone": "(813) 988-5131",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "39",
        "Company": "Island Packet Yachts",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 535-6431",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "40",
        "Company": "Rocketprint and Mail",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 442-6440",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "41",
        "Company": "DIRECT CHECK MARKETING",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "OUT OF BUS",
        "CSR": "OUT OF BUS",
        "": ""
    },
    {
        "customer": "42",
        "Company": "William Siart",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "43",
        "Company": "Hillsborough Community Co",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 253-7069",
        "Sales Rep": "JJ Brenelli",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "44",
        "Company": "Bikers Pocket Guide",
        "Contact": "",
        "Email": "",
        "Phone": "(396) 871-6180",
        "Sales Rep": "OUT OF BUS",
        "CSR": "OUT OF BUS",
        "": ""
    },
    {
        "customer": "45",
        "Company": "Sun Dental Labs",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 561-9777",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "46",
        "Company": "EVOLUTION DESIGN GRAPHICS",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 814-0110",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "47",
        "Company": "Coast Dental/Intelident Solutions",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 288-6249",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "49",
        "Company": "Wendover Art Group",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 573-1417",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "50",
        "Company": "Precision Performance",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 526-9165",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "51",
        "Company": "New College of Florida Admissions",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 487-4150",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "52",
        "Company": "The Joy FM",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 848-9150",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "53",
        "Company": "The Visual Spectrum",
        "Contact": "",
        "Email": "",
        "Phone": "(352) 597-4395",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "54",
        "Company": "Instrument Transformers",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 298-2000",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "55",
        "Company": "Robin Photo",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 577-0911",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "56",
        "Company": "Think Direct Marketing",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 914-0171",
        "Sales Rep": "Celeste Hall",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "57",
        "Company": "Journeys Crossing",
        "Contact": "",
        "Email": "",
        "Phone": "(301) 972-2756",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "58",
        "Company": "International Society of Pharmaceutical",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 960-2105",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "59",
        "Company": "Hillsborough County Publi",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 272-4327",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "60",
        "Company": "TECO Peoples Gas",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 228-4663",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "61",
        "Company": "BICSI",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 979-1991",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "62",
        "Company": "Concrete Sawing and Drilling",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 577-5004",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "63",
        "Company": "DDS Lab",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 288-1999",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "65",
        "Company": "IMG Academy",
        "Contact": "",
        "Email": "",
        "Phone": "(800) 872-6425",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "66",
        "Company": "Clearwater Marine Aquarium",
        "Contact": "TYLER ROWLAND",
        "Email": "trowland@cmaquarium.org",
        "Phone": "(727) 441-1790",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "67",
        "Company": "Metro Quality Printing",
        "Contact": "",
        "Email": "",
        "Phone": "(214) 347-6694",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "69",
        "Company": "City of Pinellas Park",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 369-5743",
        "Sales Rep": "Celeste Hall",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "70",
        "Company": "PSTA",
        "Contact": "Edith Randle",
        "Email": "ERandle@psta.net",
        "Phone": "(727) 540-1828",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "72",
        "Company": "4-AAF",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 988-0737",
        "Sales Rep": "JJ Brenelli",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "73",
        "Company": "PCOMS",
        "Contact": "Lisa Belliveau (HCOMS)",
        "Email": "belliveau@aol.com",
        "Phone": "(727) 581-9069",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "74",
        "Company": "MityMo Design, LLC",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 686-2146",
        "Sales Rep": "OUT OF BUS",
        "CSR": "OUT OF BUS",
        "": ""
    },
    {
        "customer": "75",
        "Company": "FWRI",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 896-8626",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "76",
        "Company": "IS Marketing / Infosalon",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 877-2389",
        "Sales Rep": "OUT OF BUS",
        "CSR": "OUT OF BUS",
        "": ""
    },
    {
        "customer": "77",
        "Company": "Hillsbourough County Plan",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 276-8393",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "78",
        "Company": "Allen Shuffleboard",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 399-8877",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "79",
        "Company": "The Mallard Group",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 572-4665",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "80",
        "Company": "Pinch Penny Press",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 327-7468",
        "Sales Rep": "Celeste Hall",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "81",
        "Company": "TransAmerica / Western Re",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 299-1800",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "82",
        "Company": "CCC HS",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 531-1449",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "83",
        "Company": "Chocachatti Elementary",
        "Contact": "",
        "Email": "",
        "Phone": "(352) 797-7067",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "84",
        "Company": "Paradise Advertising and",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 821-5155",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "85",
        "Company": "Abe Brown Ministries",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 247-3285",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "91",
        "Company": "Metal Industries",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 441-2651",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "92",
        "Company": "Postcard Mania",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 442-6657",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "93",
        "Company": "WWW",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 522-7777",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "94",
        "Company": "MicroMo Electronics",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 572-0131",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "96",
        "Company": "Adjust-A-Brush",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 571-1234",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "99",
        "Company": "Best Buddies Tampa Bay",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 254-9025",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "100",
        "Company": "Fleming Advertising & Des",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 374-6845",
        "Sales Rep": "Celeste Hall",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "101",
        "Company": "IQPC",
        "Contact": "",
        "Email": "",
        "Phone": "(646) 378-6054",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "103",
        "Company": "Biddiscombe International",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 299-9287",
        "Sales Rep": "House",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "104",
        "Company": "TBOF",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 392-4021",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "Pamela Brodeur",
        "": ""
    },
    {
        "customer": "105",
        "Company": "St. Petersburg Free Clinic",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 821-1200",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "106",
        "Company": "Visionary Center for Womem",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 540-0414",
        "Sales Rep": "OUT OF BUS",
        "CSR": "OUT OF BUS",
        "": ""
    },
    {
        "customer": "107",
        "Company": "Knight Litho Service, Inc",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 342-3136",
        "Sales Rep": "OUT OF BUS",
        "CSR": "OUT OF BUS",
        "": ""
    },
    {
        "customer": "108",
        "Company": "Health and Safety Institute",
        "Contact": "Timmarie Robertson",
        "Email": "trobertson@hsi.com",
        "Phone": "(800) 447-3177",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "Pamela Brodeur",
        "": ""
    },
    {
        "customer": "109",
        "Company": "New Life Solutions",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 216-1402",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "110",
        "Company": "Rumex International Corp",
        "Contact": "Ulyana Lyadova",
        "Email": "u.lyadova@rumex.com",
        "Phone": "(727) 568-0909",
        "Sales Rep": "Celeste Hall",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "111",
        "Company": "Misty Meadows Studios",
        "Contact": "Jeanne Matheny",
        "Email": "mathenync@gmail.com",
        "Phone": "(727) 474-5750",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "113",
        "Company": "Dermazone",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "115",
        "Company": "Lake Park Dental",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 960-9500",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "116",
        "Company": "Adsource",
        "Contact": "",
        "Email": "",
        "Phone": "(239) 495-0007",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "117",
        "Company": "Spooner Design",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 748-6598",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "118",
        "Company": "Big Brothers Big Sisters",
        "Contact": "Amy Neff Hollington",
        "Email": "AmyH@bbbstampabay.org",
        "Phone": "(813) 769-3636",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "119",
        "Company": "Fourth Door Creative Group",
        "Contact": "Brandon Alexander",
        "Email": "brandon.alexander@tampamagazines.com",
        "Phone": "(813) 600-4000",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "120",
        "Company": "Empath Health",
        "Contact": "Lauryn Barry",
        "Email": "lbarry@trustbridge.com",
        "Phone": "(727) 523-3200",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "121",
        "Company": "Molex",
        "Contact": "",
        "Email": "",
        "Phone": "(630) 527-4433",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "122",
        "Company": "Country Meats",
        "Contact": "",
        "Email": "",
        "Phone": "(800) 277-8989",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "123",
        "Company": "American School of Nutritional Health",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 445-4202",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "124",
        "Company": "Frank Brunckhorst",
        "Contact": "Michael Prevost",
        "Email": "MICHAEL.PREVOST@BOARSHEAD.COM",
        "Phone": "(765) 518-4200",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "125",
        "Company": "Gulfside Healthcare Services, Inc",
        "Contact": "CARLA ARMSTRONG",
        "Email": "CARLA.ARMSTRONG@GHPPC.ORG",
        "Phone": "(800) 561-4883",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "127",
        "Company": "Micon",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 855-4651",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "128",
        "Company": "Lee County Electric",
        "Contact": "Karen Ryan",
        "Email": "karen.ryan@lcec.net",
        "Phone": "(239) 656-2122",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "129",
        "Company": "Lightning Print",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 321-0785",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "130",
        "Company": "Florida Shuffle Board Association",
        "Contact": "",
        "Email": "",
        "Phone": "(239) 599-8155",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "131",
        "Company": "Continuing Ed",
        "Contact": "Sandra Barnhart",
        "Email": "sandra@continuingeducation.net",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "132",
        "Company": "ConMed/Linvatec",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 399-5306",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "133",
        "Company": "iPrint Digital",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 572-7258",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "134",
        "Company": "NOPE of Pinellas",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 424-9606",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "135",
        "Company": "Solace Insurance",
        "Contact": "",
        "Email": "",
        "Phone": "(800) 915-0969",
        "Sales Rep": "Celeste Hall",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "136",
        "Company": "Wiper Wiz The Innovative Shop Inc",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 795-2422",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "137",
        "Company": "Nickel Communications",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 954-0600",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "138",
        "Company": "GSP Marketing",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 437-5810",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "139",
        "Company": "Aqua Marketing and Communications",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 480-4802",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "140",
        "Company": "Omnicell",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 576-6311",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "141",
        "Company": "Eckerd College",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 864-8874",
        "Sales Rep": "House",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "142",
        "Company": "Pro-Con Services",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "143",
        "Company": "New Day Church",
        "Contact": "",
        "Email": "",
        "Phone": "(301) 268-1411",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "144",
        "Company": "Salem Media Group",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 349-8244",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "146",
        "Company": "Harwood Financial",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 524-1427",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "149",
        "Company": "USF CUTR",
        "Contact": "Dr Siwon Jang",
        "Email": "sjang2@usf.edu",
        "Phone": "(813) 974-2049",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "150",
        "Company": "Somebody Cares Tampa Bay",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 536-2273",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "151",
        "Company": "Precision Ceramics USA",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 785-4360",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "152",
        "Company": "Mason Creek Marketing",
        "Contact": "",
        "Email": "",
        "Phone": "(352) 628-9963",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "153",
        "Company": "Pittsburgh Pirates",
        "Contact": "CRAIG WARZECHA",
        "Email": "CRAIG.WARZECHA@PIRATES.COM",
        "Phone": "(941) 747-3031",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "154",
        "Company": "E.A.T. St Pete",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 894-1000",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "156",
        "Company": "Rapid Security Solutions",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 219-4190",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "157",
        "Company": "Pivotal Sign & Graphics",
        "Contact": "Bill Waszak",
        "Email": "Bill@pivotalsign.com",
        "Phone": "(727) 462-2266",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "158",
        "Company": "Creative Printing",
        "Contact": "",
        "Email": "",
        "Phone": "(863) 385-8383",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "159",
        "Company": "Impact Campus Ministries",
        "Contact": "Brian Truschinger",
        "Email": "brian.truschinger@impacttheu.com",
        "Phone": "(518) 229-6676",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "161",
        "Company": "GlycoMark",
        "Contact": "",
        "Email": "",
        "Phone": "(212) 355-2458",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "162",
        "Company": "Cardno TBE",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 531-3505",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "163",
        "Company": "Green Forest Industries",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 721-0504",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "165",
        "Company": "Abilities of Florida",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "166",
        "Company": "Sargasso Apparel",
        "Contact": "",
        "Email": "",
        "Phone": "(305) 453-6052",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "167",
        "Company": "First Christian Church",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 799-0612",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "168",
        "Company": "KR Management",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 489-0857",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "169",
        "Company": "Alfonso Architects",
        "Contact": "Mary Margaret Ferguson",
        "Email": "mmf@alfonsoarchitects.com",
        "Phone": "(813) 247-3333",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "170",
        "Company": "C&D Printing",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "171",
        "Company": "DIGITAL LIGHTBRIDGE",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 863-7806",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "172",
        "Company": "Bon Posh",
        "Contact": "",
        "Email": "",
        "Phone": "(407) 248-1299",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "173",
        "Company": "Strops Marketing",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 462-0019",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "174",
        "Company": "The Benefit Group",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 823-9999",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "175",
        "Company": "BrandMark Advertising",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 474-0445",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "176",
        "Company": "Goodwill Industries - SUN",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 450-5215",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "177",
        "Company": "Caring People Ministries",
        "Contact": "",
        "Email": "",
        "Phone": "(863) 375-3377",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "178",
        "Company": "US Apparel",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 492-4684",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "179",
        "Company": "Christian Living Publications",
        "Contact": "Pastor Hans Gondosch",
        "Email": "",
        "Phone": "(352) 875-4485",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "180",
        "Company": "Paradise Press",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 447-1871",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "181",
        "Company": "David and Goliath",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 462-6205",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "183",
        "Company": "Simpson Communications",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 789-1505",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "184",
        "Company": "Custom Nails",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "187",
        "Company": "Gahagan + Bryant",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 831-4408",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "188",
        "Company": "Spectra Biopharma",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 851-0952",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "189",
        "Company": "Graphic Art Production",
        "Contact": "Mark Prince",
        "Email": "mariusprince@mac.com",
        "Phone": "(727) 455-9028",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "192",
        "Company": "Southern Winding",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 621-6555",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "193",
        "Company": "Tradex International",
        "Contact": "",
        "Email": "",
        "Phone": "(216) 651-4788",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "194",
        "Company": "Giving Hope Through Faith",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 685-6773",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "195",
        "Company": "Piedmont National",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 888-6061",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "196",
        "Company": "Independent Resources",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 237-0945",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "198",
        "Company": "DySIS",
        "Contact": "",
        "Email": "",
        "Phone": "(844) 397-4763",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "199",
        "Company": "The Fulfillment Lab",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "201",
        "Company": "Rearden/Killion",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 896-3435",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "202",
        "Company": "Florida Hospital",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "203",
        "Company": "The Printers Edge",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "205",
        "Company": "Acquire Direct Marketing",
        "Contact": "",
        "Email": "",
        "Phone": "(800) 771-9898",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "207",
        "Company": "MS Family Harley Davidson",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "208",
        "Company": "Eye Site of Tampa Bay",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 799-3772",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "209",
        "Company": "Premier Outdoor Lighting",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 672-4911",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "210",
        "Company": "Navigators CDM Ministries",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 851-6100",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "211",
        "Company": "In Place Marketing",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 933-1810",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "212",
        "Company": "True Fire",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 898-8866",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "213",
        "Company": "Our Town",
        "Contact": "",
        "Email": "",
        "Phone": "(800) 497-8360",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "214",
        "Company": "SSI Realty",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "215",
        "Company": "Haynor Garden Design",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 585-6171",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "217",
        "Company": "Electro Tape",
        "Contact": "Cameron Ellinwood",
        "Email": "cameron@electrotape.com",
        "Phone": "(813) 433-2100",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "218",
        "Company": "Beach Drive Inn",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "219",
        "Company": "Ken Bucklew",
        "Contact": "",
        "Email": "",
        "Phone": "(812) 829-3415",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "220",
        "Company": "Isla Del Sol Yacht & Coun",
        "Contact": "",
        "Email": "",
        "Phone": "(703) 989-4745",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "221",
        "Company": "Florida State Fair Author",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 627-4319",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "222",
        "Company": "Pat Baker / Remax Realty",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 727-7345",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "223",
        "Company": "BGS Graphic Solutions",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 798-6434",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "225",
        "Company": "Sky View Elementary",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 547-7857",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "226",
        "Company": "VDR - Bay Meadows",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 573-4601",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "228",
        "Company": "Grand Shores West",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 397-5594",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "229",
        "Company": "Pitney Bowes",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "231",
        "Company": "Lab Logic Systems, Inc",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 626-6848",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "232",
        "Company": "Really Professional Cooling",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 251-9852",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "233",
        "Company": "Hart Indutres",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "234",
        "Company": "State of Florida, Department",
        "Contact": "",
        "Email": "",
        "Phone": "(850) 345-8794",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "235",
        "Company": "Solar Arts Window Film",
        "Contact": "",
        "Email": "",
        "Phone": "(949) 770-8969",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "236",
        "Company": "Colt Laboratories",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 725-2323",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "237",
        "Company": "Suncoast Cabinet Supplies",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 561-9699",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "238",
        "Company": "LaPure Water Products",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 397-5594",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "240",
        "Company": "C.F.R.A. LLC (dba IHOP)",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "Kelly Applegate",
        "": ""
    },
    {
        "customer": "241",
        "Company": "Linvatec (Conmed)",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 392-6464",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "244",
        "Company": "First United Methodist Church",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 894-4661",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "245",
        "Company": "Dosatron International",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 443-5404",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "246",
        "Company": "Christian Research",
        "Contact": "",
        "Email": "",
        "Phone": "(585) 865-2897",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "248",
        "Company": "Market Arts",
        "Contact": "",
        "Email": "",
        "Phone": "(615) 750-5255",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "249",
        "Company": "Color Spectrum",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 572-0115",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "250",
        "Company": "USF Federal Credit Union",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 569-2000",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "251",
        "Company": "Merlin Law Group",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 229-1000",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "252",
        "Company": "Beck Graphics",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 443-3803",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "253",
        "Company": "L.R.E. Ground Services",
        "Contact": "",
        "Email": "",
        "Phone": "(352) 796-0229",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "254",
        "Company": "Funky Monkey Lifestyle Wear",
        "Contact": "",
        "Email": "",
        "Phone": "(345) 943-8659",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "255",
        "Company": "Clarity Services",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 400-4627",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "256",
        "Company": "Wakaari",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 458-1955",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "258",
        "Company": "Shaffer Designs",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 657-5037",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "259",
        "Company": "Thomas Sign",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 573-7757",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "261",
        "Company": "ProCorp Inc",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 285-3669",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "262",
        "Company": "Sarasota Opera",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 366-8450",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "263",
        "Company": "St. Pete Country Club",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 864-1595",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "264",
        "Company": "Harborside Christian Church",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 726-8903",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "265",
        "Company": "Planet Financial Group/Planet Home Lendi",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 782-8623",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "266",
        "Company": "Carrollwood Baptist Church",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 962-3214",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "267",
        "Company": "CJ Publishing",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 521-6277",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "269",
        "Company": "Sensoria Wellness",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 216-397",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "270",
        "Company": "Steel City Brewhouse",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 822-2337",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "276",
        "Company": "Common Ground Church",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 232-1400",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "277",
        "Company": "Crest Cabinets",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 572-5590",
        "Sales Rep": "House ",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "278",
        "Company": "Don Sprouse",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 433-1836",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "279",
        "Company": "Doug Towne",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 452-8132",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "280",
        "Company": "Nope Hillsborough",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 293-4447",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "281",
        "Company": "Phoenix Home Care",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 532-9900",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "283",
        "Company": "Vernon Temple AME Church",
        "Contact": "",
        "Email": "",
        "Phone": "(441) 238-1178",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "285",
        "Company": "Healthful Revolution, LLC",
        "Contact": "",
        "Email": "",
        "Phone": "(417) 425-3148",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "289",
        "Company": "Oscor, Inc",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 937-2511",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "290",
        "Company": "All Faiths Food Bank",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 379-6333",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "291",
        "Company": "Hollywood Smile Center",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 781-2424",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "292",
        "Company": "Haneke Design",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 377-2470",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "293",
        "Company": "Tampa Bay Times",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "295",
        "Company": "Northshore",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 455-5751",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "296",
        "Company": "Print Harmony",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 455-2766",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "297",
        "Company": "PanCornea",
        "Contact": "",
        "Email": "",
        "Phone": "(216) 215-3619",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "298",
        "Company": "Freedom Boat Club",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 940-3408",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "299",
        "Company": "Treasure Bay Hotel & Marine",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 360-4400",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "300",
        "Company": "Most Insurance",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 347-5555",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "301",
        "Company": "Lake Aurora Christian Cam",
        "Contact": "",
        "Email": "",
        "Phone": "(863) 696-1102",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "302",
        "Company": "Discount Tickets & Tours",
        "Contact": "",
        "Email": "",
        "Phone": "(714) 454-3372",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "303",
        "Company": "Sunseeker Yachts",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 966-9230",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "304",
        "Company": "AmeriMex",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 213-0723",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "306",
        "Company": "Assured RX Compounding",
        "Contact": "",
        "Email": "",
        "Phone": "(602) 228-6090",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "318",
        "Company": "Treasure Island Mad Beach",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 360-4121",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "319",
        "Company": "Diversified",
        "Contact": "",
        "Email": "",
        "Phone": "(770) 441-5175",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "320",
        "Company": "Poynter Institute",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 553-4701",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "321",
        "Company": "Intertape Polymer Corp",
        "Contact": "KRISTI HUMPHREY",
        "Email": "KHUMPHRE@ITAPE.COM",
        "Phone": "(800) 474-8273",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "322",
        "Company": "Fast Track Shipping and Exporting",
        "Contact": "",
        "Email": "",
        "Phone": "(646) 309-5509",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "323",
        "Company": "International Diamond Center",
        "Contact": "Madison Culver",
        "Email": "madison.culver@shopidc.com",
        "Phone": "(727) 317-6695",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "324",
        "Company": "PLS Print",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 573-1763",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "326",
        "Company": "Waterfront Galleries",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 713-7246",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "327",
        "Company": "AAA Auto Club Group",
        "Contact": "Marion LaBudde",
        "Email": "mlabudde@aaasouth.com",
        "Phone": "(813) 289-1327",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "330",
        "Company": "The Artistic Whisk",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 560-5662",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "331",
        "Company": "Mail Medic",
        "Contact": "",
        "Email": "",
        "Phone": "(239) 244-1332",
        "Sales Rep": "Celeste Hall",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "335",
        "Company": "Onicon",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 447-6140",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "336",
        "Company": "Instant Print Service",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 546-8036",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "337",
        "Company": "Web Mason",
        "Contact": "",
        "Email": "",
        "Phone": "(410) 785-1111",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "338",
        "Company": "Irongate Creative",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "343",
        "Company": "Elite Marketing, Inc",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 884-3545",
        "Sales Rep": "Don Bonin",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "344",
        "Company": "Hyperline Systems Canada",
        "Contact": "",
        "Email": "",
        "Phone": "(613) 736-8500",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "346",
        "Company": "Village of the Arts",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 747-9397",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "348",
        "Company": "Truth Faith and Deliverance",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 821-7202",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "349",
        "Company": "Sam Slusak Fund",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "350",
        "Company": "Coupon Master",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "354",
        "Company": "USF College of Engineering",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 480-0569",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "355",
        "Company": "Lab Logic Systems Limited Paradigm House",
        "Contact": "",
        "Email": "",
        "Phone": "(441) 142-667",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "356",
        "Company": "Generation Next Foundatio",
        "Contact": "",
        "Email": "",
        "Phone": "(747) 900-1480",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "357",
        "Company": "Canyon Inn",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "358",
        "Company": "Push",
        "Contact": "",
        "Email": "",
        "Phone": "(407) 841-2299",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "359",
        "Company": "Florida Watercolor Society",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "363",
        "Company": "Caribe SUP",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 902-6078",
        "Sales Rep": "JJ Brenelli",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "364",
        "Company": "Emerson Law",
        "Contact": "Cody Emerson",
        "Email": "acodyemerson@gmail.com",
        "Phone": "(727) 536-0022",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "366",
        "Company": "MacFarlane Park PTA",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "367",
        "Company": "RATEC LLC",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 363-7732",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "368",
        "Company": "Pelican Print",
        "Contact": "",
        "Email": "",
        "Phone": "(561) 310-0428",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "369",
        "Company": "Pure Air Control Services",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 572-4550",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "371",
        "Company": "Yeager Flooring",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 918-2737",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "372",
        "Company": "Ziggi Marketing",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 216-3859",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "374",
        "Company": "L Shaffer Portraits",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "375",
        "Company": "Contronex",
        "Contact": "",
        "Email": "",
        "Phone": "(239) 649-7836",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "376",
        "Company": "RX Care Compounding",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "380",
        "Company": "The Mercantile",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 709-1722",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "382",
        "Company": "Boars Head Provisions Co.",
        "Contact": "Michael Cleary",
        "Email": "Michael.Cleary@Boarshead.com",
        "Phone": "(941) 955-0994",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "388",
        "Company": "All American Enterprises",
        "Contact": "",
        "Email": "",
        "Phone": "(205) 836-8991",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "389",
        "Company": "FCCI Insurance Group",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 907-7676",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "393",
        "Company": "Hydro-Dyne",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "394",
        "Company": "Dental Care Alliance",
        "Contact": "",
        "Email": "",
        "Phone": "(888) 876-4531",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "395",
        "Company": "Skanska USA",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 281-3287",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "396",
        "Company": "IASIS Intergrated Care SO",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 321-7196",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "397",
        "Company": "Central Christian Church",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 446-0988",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "399",
        "Company": "Gulfcoast Imprinting",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 683-0050",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "Kelly Applegate",
        "": ""
    },
    {
        "customer": "401",
        "Company": "EcoShield",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 855-8900",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "402",
        "Company": "Tampa Bay Buccaneers",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 998-3802",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "404",
        "Company": "Pasadena Elem School",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "405",
        "Company": "Weepeggles LLC",
        "Contact": "Nancy Bennett",
        "Email": "maggiewill27@hotmail.com",
        "Phone": "(727) 560-4303",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "407",
        "Company": "US Postal Service / Marc",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 889-3968",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "408",
        "Company": "Bradenton Country Club",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 792-1600",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "409",
        "Company": "R3 Derma Health",
        "Contact": "",
        "Email": "",
        "Phone": "(604) 889-7850",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "410",
        "Company": "Suncoast Safety Council",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 608-2946",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "411",
        "Company": "Fibre Tech, Inc.",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 539-0844",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "412",
        "Company": "Julius K9 LLC",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 956-4392",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "413",
        "Company": "Angels of the Amazon, Inc",
        "Contact": "",
        "Email": "",
        "Phone": "(800) 262-9669",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "414",
        "Company": "Perfect Copy and Print",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 914-3976",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "415",
        "Company": "Kelley Crane Rental",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 571-4100",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "416",
        "Company": "Priority Project Resource",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 752-0880",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "418",
        "Company": "Robbins Group",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 252-8217",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "419",
        "Company": "FrankCrum",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 726-2786",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "421",
        "Company": "Print Solutions & Promos",
        "Contact": "Hassan Maziad",
        "Email": "",
        "Phone": "(727) 463-8888",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "423",
        "Company": "American Express Loyalty Express",
        "Contact": "",
        "Email": "",
        "Phone": "(212) 640-6549",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "Kelly Applegate",
        "": ""
    },
    {
        "customer": "427",
        "Company": "Mokulele Airlines",
        "Contact": "",
        "Email": "",
        "Phone": "(602) 800-9901",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "429",
        "Company": "Teknia TDS",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 918-8417",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "431",
        "Company": "Aegea",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 331-9017",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "432",
        "Company": "Manasota Family & Kids",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 773-5978",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "433",
        "Company": "Sani-Chem",
        "Contact": "",
        "Email": "",
        "Phone": "(800) 983-8280",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "434",
        "Company": "Genesis Direct",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 855-4274",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "437",
        "Company": "Kays Hallmark Post Office",
        "Contact": "",
        "Email": "",
        "Phone": "(239) 989-6377",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "438",
        "Company": "National Exemption Svc",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 638-6223",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "439",
        "Company": "The Earth Friendly Nook",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 458-9007",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "443",
        "Company": "MDMSI",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 814-1993",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "444",
        "Company": "Consolidated Mailing Service",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 888-5003",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "445",
        "Company": "Minuteman Press",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 719-2111",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "446",
        "Company": "Leading Response",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 855-3000",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "447",
        "Company": "Clearwater Cadiovascular",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 445-1911",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "449",
        "Company": "Doran & Beam, PA",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 846-1000",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "454",
        "Company": "Helinger Advertising",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 327-3333",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "455",
        "Company": "Madison Avenue Furniture",
        "Contact": "SIMON T LEVELL",
        "Email": "SLEVELL@MADISONAVEFURNITURE.COM",
        "Phone": "(941) 355-2677",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "456",
        "Company": "Lowes",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 457-9335",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "458",
        "Company": "SCF - State College of FL",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 752-5225",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "460",
        "Company": "Nordis Direct",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "462",
        "Company": "SMYL GROUP",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 792-7777",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "464",
        "Company": "Dennis Grubbs Catering Inc",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "466",
        "Company": "Boulevard Tire Center",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "468",
        "Company": "Dunedin Golf & Country Cl",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 733-2134",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "469",
        "Company": "Matlacha Off Support Team",
        "Contact": "",
        "Email": "",
        "Phone": "(239) 283-0924",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "472",
        "Company": "Tampa Electric Company",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 228-4269",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "474",
        "Company": "Venus Concept",
        "Contact": "",
        "Email": "",
        "Phone": "(888) 907-0115",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "476",
        "Company": "FP Mailing Solutions",
        "Contact": "",
        "Email": "",
        "Phone": "(331) 431-3491",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "478",
        "Company": "Lutz, Bobo & Telfair, PA",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 951-1800",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "482",
        "Company": "Train Up First",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 489-9367",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "483",
        "Company": "Strategic Image Mngmt***",
        "Contact": "",
        "Email": "",
        "Phone": "(772) 261-8100",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "484",
        "Company": "Clearwater Free Clinic",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 331-8149",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "Kelly Applegate",
        "": ""
    },
    {
        "customer": "485",
        "Company": "Vijilan",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 424-0200",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "486",
        "Company": "S&H Printing",
        "Contact": "",
        "Email": "",
        "Phone": "(863) 660-6958",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "488",
        "Company": "Church of Scientology Flag Service Organization",
        "Contact": "Naomi McKinnon",
        "Email": "naomi.m@cos.flag.org",
        "Phone": "(727) 461-1282",
        "Sales Rep": "Celeste Hall",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "489",
        "Company": "Allegra | Marketing & Print",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 664-1129",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "491",
        "Company": "Ekonomical LTd",
        "Contact": "",
        "Email": "",
        "Phone": "(800) 043-3122",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "492",
        "Company": "Modern Mailing",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 572-6245",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "493",
        "Company": "Valor Fitness",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 895-9525",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "495",
        "Company": "Don Kobasky",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 565-3387",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "Kelly Applegate",
        "": ""
    },
    {
        "customer": "496",
        "Company": "Global Retail Marketing A",
        "Contact": "Pat Dermody",
        "Email": "pat@thegrma.com",
        "Phone": "(727) 481-0230",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "498",
        "Company": "RW Events",
        "Contact": "",
        "Email": "",
        "Phone": "(407) 697-2268",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "501",
        "Company": "Community Brands",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 329-4446",
        "Sales Rep": "OUT OF BUS",
        "CSR": "OUT OF BUS",
        "": ""
    },
    {
        "customer": "502",
        "Company": "Educational Symposia",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 806-1000",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "504",
        "Company": "PARC",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 345-9111",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "505",
        "Company": "Accurate Telecom Inc.",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 532-4400",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "506",
        "Company": "Data Targeting***",
        "Contact": "",
        "Email": "",
        "Phone": "(352) 281-4330",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "507",
        "Company": "Wyndham Ocean Walk",
        "Contact": "",
        "Email": "",
        "Phone": "(386) 323-4800",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "509",
        "Company": "Jack Latvala Campaign",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "510",
        "Company": "Graphics & Design",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 254-9444",
        "Sales Rep": "Donald Bonin",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "511",
        "Company": "CTC Legal Media",
        "Contact": "",
        "Email": "",
        "Phone": "(447) 718-278",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "514",
        "Company": "Downey Glass",
        "Contact": "",
        "Email": "",
        "Phone": "(954) 972-0026",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "515",
        "Company": "Londos Fine Cabinetry",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 517-6275",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "517",
        "Company": "American Fundraising Services",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 882-0775",
        "Sales Rep": "Tom Marzo",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "519",
        "Company": "Doran & Beam",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 846-1000",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "520",
        "Company": "CFE Federal Credit Union",
        "Contact": "",
        "Email": "",
        "Phone": "(407) 896-9411",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "522",
        "Company": "AME Intl",
        "Contact": "",
        "Email": "",
        "Phone": "(352) 799-1111",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "523",
        "Company": "RF Country Meats",
        "Contact": "",
        "Email": "",
        "Phone": "(678) 429-9097",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "524",
        "Company": "Mid-America Festivals",
        "Contact": "",
        "Email": "",
        "Phone": "(952) 445-7361",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "527",
        "Company": "Clear-Tech Pools",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 347-6770",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "528",
        "Company": "Ameri Life",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 471-0079",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "530",
        "Company": "Deluxe Plastic Card Co.",
        "Contact": "",
        "Email": "",
        "Phone": "(239) 302-3700",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "532",
        "Company": "The Crush Agency",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 397-6181",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "534",
        "Company": "Bulldog Marketing",
        "Contact": "",
        "Email": "",
        "Phone": "(561) 689-7887",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "536",
        "Company": "Visit Pasco",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 847-8129",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "538",
        "Company": "Platinum Plus Printing",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 290-9619",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "540",
        "Company": "FNBR Inc",
        "Contact": "",
        "Email": "",
        "Phone": "(888) 988-8141",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "541",
        "Company": "Lakewood Place Apartments",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 681-5900",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "543",
        "Company": "Allegra Prtg. - Bradenton",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 792-4453",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "544",
        "Company": "P&J Graphics",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 626-3243",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "546",
        "Company": "Sir Speedy-Spring Hill",
        "Contact": "",
        "Email": "",
        "Phone": "(352) 683-8757",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "548",
        "Company": "H&T Global Circuits",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 327-6236",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "550",
        "Company": "Smith & Associates Real",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 839-3800",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "551",
        "Company": "National Readerboard Supp",
        "Contact": "",
        "Email": "",
        "Phone": "(719) 539-9626",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "554",
        "Company": "Mercury Printing",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 378-3311",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "556",
        "Company": "Tom Marzo",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 432-2020",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "557",
        "Company": "Big Storm Brewery",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 201-41",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "558",
        "Company": "American Postcard Printer",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 639-3808",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "559",
        "Company": "Valspar Championship",
        "Contact": "Emilie Clay",
        "Email": "eclay@thecopperheads.org",
        "Phone": "(508) 733-1965",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "Pamela Brodeur",
        "": ""
    },
    {
        "customer": "560",
        "Company": "Florida Lawn & Pest Pros",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 848-5296",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "562",
        "Company": "De Lage Landen Financial",
        "Contact": "",
        "Email": "",
        "Phone": "(610) 386-5886",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "564",
        "Company": "DSG, Group Practice Solut",
        "Contact": "",
        "Email": "",
        "Phone": "(714) 396-8401",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "565",
        "Company": "Mediagistic",
        "Contact": "",
        "Email": "",
        "Phone": "(800) 747-5601",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "566",
        "Company": "Jackie Toledo Campaign",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "569",
        "Company": "Marketing Resource",
        "Contact": "",
        "Email": "",
        "Phone": "(336) 510-7523",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "570",
        "Company": "Allegra Printing-Tampa",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 664-1129",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "571",
        "Company": "Personal Pharmacist LLC",
        "Contact": "",
        "Email": "",
        "Phone": "(802) 345-8804",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "572",
        "Company": "Carter Printing Co.",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 254-9444",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "574",
        "Company": "International Swim Coaches Association",
        "Contact": "",
        "Email": "",
        "Phone": "(540) 904-2666",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "577",
        "Company": "RAM Services, Inc.",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 486-0676",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "579",
        "Company": "Tampa Bay Digital Design Experts",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 916-8533",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "581",
        "Company": "Mail Works Marketing",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 643-4200",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "582",
        "Company": "Design 4 Marketing Commun",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 849-0076",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "Kelly Applegate",
        "": ""
    },
    {
        "customer": "584",
        "Company": "Prime Medical",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 999-2070",
        "Sales Rep": "House ",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "585",
        "Company": "Building Health Check",
        "Contact": "",
        "Email": "",
        "Phone": "(800) 422-7873",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "587",
        "Company": "Knellinger Dental",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 785-3383",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "588",
        "Company": "Frontline Strategies Inc",
        "Contact": "",
        "Email": "",
        "Phone": "(850) 222-8156",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "590",
        "Company": "Manatee County Purchasing",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 749-3044",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "591",
        "Company": "American Imaging Systems",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 657-3350",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "593",
        "Company": "Data Imaging",
        "Contact": "",
        "Email": "",
        "Phone": "(843) 300-0092",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "595",
        "Company": "Performance AC",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 323-8088",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "596",
        "Company": "Bike Florida",
        "Contact": "",
        "Email": "",
        "Phone": "(352) 224-8601",
        "Sales Rep": "OUT OF BUS",
        "CSR": "OUT OF BUS",
        "": ""
    },
    {
        "customer": "597",
        "Company": "Community Magazine",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 682-9364",
        "Sales Rep": "House ",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "598",
        "Company": "Custom Trade Printing",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "600",
        "Company": "Trion Technology",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 461-1888",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "601",
        "Company": "Enlightened Entrepreneurs",
        "Contact": "",
        "Email": "",
        "Phone": "(352) 410-2650",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "602",
        "Company": "Davidoff of Geneva",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 828-5400",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "603",
        "Company": "Moringa Angels",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 458-9007",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "604",
        "Company": "City of Clearwater",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 562-4011",
        "Sales Rep": "JJ Brenelli",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "606",
        "Company": "Health & Psychiatry",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 386-5014",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "607",
        "Company": "Marketing by Rick",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 278-9378",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "609",
        "Company": "First Lutheran Church",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 462-8000",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "610",
        "Company": "Tampa Bay Bone & Joint Center",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 960-3228",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "612",
        "Company": "Lowry Park Zoo",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "613",
        "Company": "Shawn Harrison Campaign",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "616",
        "Company": "Florida Mail & Print",
        "Contact": "",
        "Email": "",
        "Phone": "(239) 482-0110",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "618",
        "Company": "Churchill Stateside Group",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 461-2200",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "619",
        "Company": "Walsingham Elementary PTA",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 588-3519",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "620",
        "Company": "Fort Oral Surgery",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 525-5455",
        "Sales Rep": "House ",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "622",
        "Company": "Giraffe Ranch",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "623",
        "Company": "Cordova Apartment Homes",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 972-3668",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "624",
        "Company": "Lincoln Property Company",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 558-9800",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "625",
        "Company": "Echo Lake at Lakewood Ran",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 803-30",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "626",
        "Company": "DeMert Brands Inc.",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 903-0434",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "627",
        "Company": "Momentum Strategy Group",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 500-8055",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "628",
        "Company": "Simply Gourmet Caterers",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 929-0066",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "629",
        "Company": "LaShante Keys",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 459-1302",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "632",
        "Company": "Babbs Supervalu",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "635",
        "Company": "Dragonfly Gallery",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "637",
        "Company": "Adare Lexicon House",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "638",
        "Company": "Hoot's Publishing LLC",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 403-3924",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "640",
        "Company": "Conimar Group, LLC",
        "Contact": "",
        "Email": "",
        "Phone": "(352) 732-7235",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "641",
        "Company": "Park Lane Apartments",
        "Contact": "",
        "Email": "",
        "Phone": "(352) 337-29",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "644",
        "Company": "Summit",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "646",
        "Company": "Keys Caldwell",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 408-8293",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "648",
        "Company": "Mariner International Sun",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 614-7334",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "650",
        "Company": "Roberts Printing",
        "Contact": "Will Brown",
        "Email": "will.brown@robpri.com",
        "Phone": "(727) 442-5527",
        "Sales Rep": "Robert Hamilton",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "651",
        "Company": "Palm Printing/Printers Ink, Corp.",
        "Contact": "",
        "Email": "",
        "Phone": "(239) 691-1814",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "652",
        "Company": "Lighthouse Ministries",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "653",
        "Company": "Trinity Life Church",
        "Contact": "",
        "Email": "",
        "Phone": "(863) 687-1942",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "654",
        "Company": "Management Solutions",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "655",
        "Company": "B & D Wholesaler",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 289-9303",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "656",
        "Company": "Freedom Point",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "657",
        "Company": "Outdoor Cabinets Direct",
        "Contact": "Tom Skipper",
        "Email": "",
        "Phone": "(727) 540-9182",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "659",
        "Company": "Cordelia Cabinets Inc.",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 540-9182",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "661",
        "Company": "RME 360",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 885-8268",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "663",
        "Company": "Stanley Gray for HC School",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "664",
        "Company": "Public Concepts",
        "Contact": "",
        "Email": "",
        "Phone": "(561) 688-0061",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "665",
        "Company": "Peterson ADR",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 631-7919",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "666",
        "Company": "TradeStar",
        "Contact": "",
        "Email": "",
        "Phone": "(281) 991-0100",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "667",
        "Company": "Shannon Johnson Networth",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 282-7444",
        "Sales Rep": "House ",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "668",
        "Company": "AMA Medical Group",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 331-8740",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "669",
        "Company": "E-Office Solutions",
        "Contact": "",
        "Email": "",
        "Phone": "(863) 687-1844",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "670",
        "Company": "A French Tradition",
        "Contact": "",
        "Email": "",
        "Phone": "(866) 238-1677",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "671",
        "Company": "Wolf Brand Scooters",
        "Contact": "SARA SEAL",
        "Email": "admin@wolfbrandscooters.com",
        "Phone": "(727) 954-3957",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "672",
        "Company": "Tinsley Creative",
        "Contact": "",
        "Email": "",
        "Phone": "(863) 583-0081",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "673",
        "Company": "Suncoast Pathology",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 483-3319",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "676",
        "Company": "JMS Advisory Group",
        "Contact": "",
        "Email": "",
        "Phone": "(404) 474-7733",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "678",
        "Company": "Coldwell Banker Realtor LLC",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 629-3211",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "679",
        "Company": "Aesthreadics",
        "Contact": "",
        "Email": "",
        "Phone": "(707) 500-2358",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "680",
        "Company": "Reliant Insurance",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 924-1588",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "681",
        "Company": "Florida Classic Citrus Gr",
        "Contact": "Ciera Gomez",
        "Email": "",
        "Phone": "(863) 439-8811",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "682",
        "Company": "Gabe Diaz Graphic Design,",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 263-4205",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "683",
        "Company": "Category 5 Design",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 351-3323",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "685",
        "Company": "Alpha Laser & Imaging",
        "Contact": "",
        "Email": "",
        "Phone": "(812) 475-1644",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "687",
        "Company": "ChromaTech Printing",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 528-4711",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "688",
        "Company": "Care Free Air",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 504-2865",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "690",
        "Company": "Uncommon Touch",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 433-2089",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "691",
        "Company": "Hiepe Law",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 898-2700",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "692",
        "Company": "Bicycle Tour Network",
        "Contact": "",
        "Email": "",
        "Phone": "(858) 717-1404",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "694",
        "Company": "American Legion",
        "Contact": "",
        "Email": "",
        "Phone": "(407) 295-2631",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "696",
        "Company": "Church El Olam",
        "Contact": "",
        "Email": "",
        "Phone": "(954) 625-9568",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "697",
        "Company": "Rooms to Go",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 623-5400",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "699",
        "Company": "Gentry Printing",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 441-1914",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "700",
        "Company": "Holcomb & Leung, PA",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 258-5835",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "702",
        "Company": "Florida Aquarium",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 367-4024",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "703",
        "Company": "Hope Effect Day School",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "707",
        "Company": "Hillsborough County Sheriff",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 318-5317",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "708",
        "Company": "Brother Mobile Solutions",
        "Contact": "",
        "Email": "",
        "Phone": "(303) 460-1617",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "709",
        "Company": "Charlotte County Purchasing",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 743-1527",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "710",
        "Company": "Now Press Agency",
        "Contact": "",
        "Email": "",
        "Phone": "(888) 506-9669",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "712",
        "Company": "Radius Marketing Group, Inc",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 434-4103",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "714",
        "Company": "Van Steel, Inc.",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 561-9199",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "716",
        "Company": "1st United Methodist Church",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 747-4406",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "717",
        "Company": "Essentra",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 485-0963",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "718",
        "Company": "Chapel on the Hill United Church of Chri",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "719",
        "Company": "ION LABS",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 350-0179",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "720",
        "Company": "Manatee County Clerk of the Court",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 741-4073",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "721",
        "Company": "Stavros Institute",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 588-3746",
        "Sales Rep": "House ",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "723",
        "Company": "Shelton McKean",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 316-6332",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "725",
        "Company": "Anything Office",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 372-3312",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "727",
        "Company": "All American Hose",
        "Contact": "",
        "Email": "",
        "Phone": "(814) 438-1939",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "729",
        "Company": "Chouinard Cuisine",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 329-8717",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "730",
        "Company": "Amsive",
        "Contact": "Anne Sullivan",
        "Email": "asullivan@genesisdirect.com",
        "Phone": "(813) 855-4272",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "732",
        "Company": "Broker Educational/Training",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 372-7733",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "733",
        "Company": "Society of News Design",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 366-0128",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "734",
        "Company": "Bridgepoint Church FL",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 384-3400",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "735",
        "Company": "Delta Tau Delta",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 385-4045",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "736",
        "Company": "Sebring International Rac",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "738",
        "Company": "Deluxe Corp (Impact Mktg Specialist)",
        "Contact": "",
        "Email": "",
        "Phone": "(949) 348-2292",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "741",
        "Company": "Wahoo Printing",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 873-3965",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "743",
        "Company": "Finance C.A.P.E.",
        "Contact": "",
        "Email": "",
        "Phone": "(800) 296-2602",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "744",
        "Company": "Joyce Reid Capital",
        "Contact": "",
        "Email": "",
        "Phone": "(407) 440-3673",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "745",
        "Company": "Wexler Insurance",
        "Contact": "",
        "Email": "",
        "Phone": "(786) 433-4474",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "746",
        "Company": "L5 Parcel, LLC",
        "Contact": "",
        "Email": "",
        "Phone": "(866) 910-4004",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "747",
        "Company": "Center of Revival",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 907-7788",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "748",
        "Company": "St. Luke's Cataract & Las",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 943-3353",
        "Sales Rep": "House ",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "750",
        "Company": "Performance Publications",
        "Contact": "",
        "Email": "",
        "Phone": "(416) 922-7526",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "Kelly Applegate",
        "": ""
    },
    {
        "customer": "751",
        "Company": "Marsha Costa",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "752",
        "Company": "Gunter and Gunter Insuran",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 994-3900",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "753",
        "Company": "Blackwell Auctions",
        "Contact": "Shannon Bailey",
        "Email": "",
        "Phone": "(727) 546-0200",
        "Sales Rep": "House ",
        "CSR": "Kelly Applegate",
        "": ""
    },
    {
        "customer": "754",
        "Company": "Electro Technik Industrie",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 536-7861",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "757",
        "Company": "Millers Minuteman Press",
        "Contact": "",
        "Email": "",
        "Phone": "(443) 548-3429",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "758",
        "Company": "Alexander P. Williams",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 418-1570",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "760",
        "Company": "CSI Worldwide LLC",
        "Contact": "",
        "Email": "",
        "Phone": "(610) 558-4500",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "762",
        "Company": "BlackHawk",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 623-9931",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "764",
        "Company": "Feeder Repair Center",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 330-6502",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "765",
        "Company": "Parlament Roofing",
        "Contact": "Matt Rayl",
        "Email": "mattrayl@proofco.com",
        "Phone": "(727) 571-4110",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "766",
        "Company": "NCLL",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "768",
        "Company": "City of Winter Park",
        "Contact": "",
        "Email": "",
        "Phone": "(407) 599-3238",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "769",
        "Company": "BayCare Physician Partner",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 519-1238",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "770",
        "Company": "Full Throttle Intermedia",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "OUT OF BUS",
        "CSR": "OUT OF BUS",
        "": ""
    },
    {
        "customer": "771",
        "Company": "Gulf Publishing",
        "Contact": "",
        "Email": "",
        "Phone": "(772) 759-6286",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "774",
        "Company": "Angelo & Banta, PA",
        "Contact": "",
        "Email": "",
        "Phone": "(954) 766-9930",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "775",
        "Company": "Quesnic Health",
        "Contact": "",
        "Email": "",
        "Phone": "(844) 783-6742",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "776",
        "Company": "Garden of Life",
        "Contact": "",
        "Email": "",
        "Phone": "(561) 748-2477",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "778",
        "Company": "The Oaks Club",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 441-1879",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "779",
        "Company": "CompuLink",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 579-1500",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "780",
        "Company": "JC the Printer",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 607-9602",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "781",
        "Company": "Sofwerx",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 693-5599",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "784",
        "Company": "Minuteman Press - Brandon",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "785",
        "Company": "Cooperative Real Estate",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "786",
        "Company": "Largo Family EyeCare",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 586-5888",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "788",
        "Company": "Kemco Systems",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 573-2323",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "789",
        "Company": "AhVanguard Creative Solution",
        "Contact": "Michelle Holguin",
        "Email": "michelle@ahvanguard.com",
        "Phone": "(714) 396-8401",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "791",
        "Company": "Laurence Leavy & Assoc.",
        "Contact": "",
        "Email": "",
        "Phone": "(954) 797-5297",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "792",
        "Company": "Apyx Medical",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "793",
        "Company": "Superior Dancesport Shoes",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 458-9007",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "795",
        "Company": "Excellance Capital LLC",
        "Contact": "",
        "Email": "",
        "Phone": "(407) 668-9427",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "796",
        "Company": "Marketing Productions",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 772-6618",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "798",
        "Company": "Kruse Corp.",
        "Contact": "",
        "Email": "",
        "Phone": "(316) 838-7885",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "799",
        "Company": "Trotta Wine & Spirits",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 755-1226",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "800",
        "Company": "Nathan Perkins",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 510-8651",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "802",
        "Company": "Kymberly Group Payroll Solutions",
        "Contact": "",
        "Email": "",
        "Phone": "(407) 228-6428",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "803",
        "Company": "Claudia Mathews",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 289-1494",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "Kelly Applegate",
        "": ""
    },
    {
        "customer": "805",
        "Company": "Mariner International The",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 614-7356",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "806",
        "Company": "Leader Tech, Inc.",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 341-0410",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "807",
        "Company": "Clearwater Environmental Technologies",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "808",
        "Company": "Mariner International Leo",
        "Contact": "Zoe Bell",
        "Email": "zoe.bell@travelopia.com",
        "Phone": "(727) 614-7333",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "809",
        "Company": "Trekr Adventures",
        "Contact": "",
        "Email": "",
        "Phone": "(267) 414-9040",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "810",
        "Company": "Forward Pinellas",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 464-5649",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "811",
        "Company": "Arjay Printing Company",
        "Contact": "",
        "Email": "",
        "Phone": "(904) 764-6070",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "812",
        "Company": "City of Seminole Recreation",
        "Contact": "Alex Koagel",
        "Email": "akoagel@myseminole.com",
        "Phone": "(727) 391-8345",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "813",
        "Company": "Beber Silverstein Group",
        "Contact": "",
        "Email": "",
        "Phone": "(305) 856-9800",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "815",
        "Company": "MailPost Systems",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 325-0648",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "816",
        "Company": "Lily Monk",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 417-7448",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "818",
        "Company": "Basilica of the National",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "819",
        "Company": "Randolph Reid",
        "Contact": "",
        "Email": "",
        "Phone": "(757) 894-8785",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "820",
        "Company": "Blue Green Vacations",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 803-4872",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "821",
        "Company": "Peltz Shoes",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 498-8520",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "822",
        "Company": "Dedicated Marketing",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "823",
        "Company": "Smith & Associates-Janell",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 380-5465",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "825",
        "Company": "Infinity Employment Solutions, Inc",
        "Contact": "",
        "Email": "",
        "Phone": "(407) 228-6428",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "826",
        "Company": "Kris Parker",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 469-3012",
        "Sales Rep": "JJ Brenelli",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "828",
        "Company": "We Do Mail",
        "Contact": "",
        "Email": "",
        "Phone": "(949) 680-4091",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "829",
        "Company": "Overlake Hospital Medical Center",
        "Contact": "",
        "Email": "",
        "Phone": "(425) 688-5512",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "830",
        "Company": "Bulwark Exterminating",
        "Contact": "",
        "Email": "",
        "Phone": "(480) 539-4933",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "831",
        "Company": "Florida Dream Center",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 851-9074",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "832",
        "Company": "Sarasota County",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 773-7138",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "834",
        "Company": "Tow Bros. Co. LTD",
        "Contact": "",
        "Email": "",
        "Phone": "(806) 376-5488",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "835",
        "Company": "Richard A Brown",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 232-3421",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "836",
        "Company": "Smith & Associates Debbie",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 865-8326",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "837",
        "Company": "Florida Hospital West FL",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 803-4035",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "838",
        "Company": "Chiropractor On Wheels",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "839",
        "Company": "Helicon USA",
        "Contact": "John Topa",
        "Email": "John.Topa@heliconusa.com",
        "Phone": "(0) -",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "841",
        "Company": "Wall Titus",
        "Contact": "",
        "Email": "",
        "Phone": "(863) 683-0708",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "842",
        "Company": "NLM Solutions Group",
        "Contact": "",
        "Email": "",
        "Phone": "(954) 993-7695",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "843",
        "Company": "Boyd Industries",
        "Contact": "Kerrie Fairburn",
        "Email": "KFairburn@boydind.com",
        "Phone": "(727) 561-9292",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "844",
        "Company": "Bailey Law",
        "Contact": "",
        "Email": "",
        "Phone": "(812) 955-0899",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "846",
        "Company": "The Alaska Frontier",
        "Contact": "",
        "Email": "",
        "Phone": "(253) 569-7711",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "848",
        "Company": "McCormick's Creek & Gospo",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "849",
        "Company": "Better Homes & Gardens - Vincent Arcuri",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 846-2368",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "850",
        "Company": "Tampa Bay Center for Comm",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 888-0173",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "851",
        "Company": "EMS Merchant Srvcs.",
        "Contact": "",
        "Email": "",
        "Phone": "(856) 637-3600",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "852",
        "Company": "Solon Manufacturing",
        "Contact": "",
        "Email": "",
        "Phone": "(440) 279-4801",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "853",
        "Company": "Ashley Homestores Limited",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "854",
        "Company": "Neuve Interior Design",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 752-4433",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "857",
        "Company": "Jiffy Print",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 938-4007",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "858",
        "Company": "Discipleship.org",
        "Contact": "",
        "Email": "",
        "Phone": "(615) 790-0104",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "860",
        "Company": "NewLook Marketing",
        "Contact": "",
        "Email": "",
        "Phone": "(954) 240-2242",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "861",
        "Company": "PROHS",
        "Contact": "Seth Rittenhouse",
        "Email": "seth@prohs.com",
        "Phone": "(0) -",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "863",
        "Company": "Quantum Servicing",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 333-7620",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "864",
        "Company": "Showorks",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "865",
        "Company": "National Council for Workforce Education",
        "Contact": "",
        "Email": "",
        "Phone": "(603) 714-1918",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "866",
        "Company": "The Joint - Carrollwood",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "867",
        "Company": "GFWC North Pinellas Women",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 545-9566",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "868",
        "Company": "Firefly Marketing",
        "Contact": "",
        "Email": "",
        "Phone": "(904) 616-1373",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "869",
        "Company": "Florida Grand Opera",
        "Contact": "",
        "Email": "",
        "Phone": "(800) 741-1010",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "870",
        "Company": "Bollenback Builders",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 855-2656",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "871",
        "Company": "Victoria Coffee",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 251-4987",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "872",
        "Company": "ARCHON Fitness LLC",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 433-1836",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "874",
        "Company": "Family and Court Srvcs.",
        "Contact": "",
        "Email": "",
        "Phone": "(352) 343-3991",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "875",
        "Company": "John Jerue Truck Brokers",
        "Contact": "",
        "Email": "",
        "Phone": "(863) 607-5653",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "876",
        "Company": "Amy Rossi & Assoc.",
        "Contact": "",
        "Email": "",
        "Phone": "(407) 843-3333",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "877",
        "Company": "Key West Film Society, INC dba Tropic Ci",
        "Contact": "",
        "Email": "",
        "Phone": "(305) 396-4944",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "879",
        "Company": "Widell Industries",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 848-1811",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "880",
        "Company": "Government Ins. Corp.",
        "Contact": "",
        "Email": "",
        "Phone": "(954) 727-2999",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "881",
        "Company": "Now SC Press, Inc",
        "Contact": "",
        "Email": "",
        "Phone": "(888) 506-9669",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "883",
        "Company": "Merritt Realty Corp.",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 320-6079",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "884",
        "Company": "Aloha Print Group",
        "Contact": "",
        "Email": "",
        "Phone": "(312) 542-1300",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "885",
        "Company": "JBraine Agency LLC",
        "Contact": "",
        "Email": "",
        "Phone": "(405) 482-1020",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "886",
        "Company": "United Graphics & Mailing",
        "Contact": "",
        "Email": "",
        "Phone": "(312) 408-2407",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "887",
        "Company": "ACTS Florida",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 246-4899",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "889",
        "Company": "Service Bay Builder",
        "Contact": "",
        "Email": "",
        "Phone": "(703) 297-139",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "890",
        "Company": "The Institute of InternalAuditors",
        "Contact": "",
        "Email": "",
        "Phone": "(407) 937-1186",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "891",
        "Company": "Marvin Hewatt Ent.",
        "Contact": "",
        "Email": "",
        "Phone": "(770) 685-7326",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "892",
        "Company": "ACLU of Florida",
        "Contact": "",
        "Email": "",
        "Phone": "(786) 363-2701",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "893",
        "Company": "Gunn Printing",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 870-6490",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "895",
        "Company": "American Printing Services",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 485-3623",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "897",
        "Company": "Towne Properties",
        "Contact": "",
        "Email": "",
        "Phone": "(239) 596-1031",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "898",
        "Company": "General Dynamics",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 578-8100",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "900",
        "Company": "Blue Ocean Holdings",
        "Contact": "",
        "Email": "",
        "Phone": "(202) 909-5670",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "901",
        "Company": "First Home Bank",
        "Contact": "",
        "Email": "",
        "Phone": "(274) 406-848",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "902",
        "Company": "Steve Perry",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 573-1763",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "904",
        "Company": "Healthy @ Work",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 377-5920",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "906",
        "Company": "Wirz & Company",
        "Contact": "",
        "Email": "",
        "Phone": "(909) 825-6970",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "907",
        "Company": "Sawgrass Plantation",
        "Contact": "",
        "Email": "",
        "Phone": "(407) 601-5280",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "908",
        "Company": "Off Hands Trivia",
        "Contact": "",
        "Email": "",
        "Phone": "(803) 834-9507",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "911",
        "Company": "Fitlife Foods",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 280-1733",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "912",
        "Company": "Leadership Pinellas",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 585-8889",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "914",
        "Company": "Aquaman Inc.",
        "Contact": "",
        "Email": "",
        "Phone": "(305) 698-0990",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "915",
        "Company": "AssureGreen Property Service",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 797-4040",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "916",
        "Company": "Advanced Auto & Truck USA",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 531-2312",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "918",
        "Company": "Commercial Printing Inc",
        "Contact": "",
        "Email": "",
        "Phone": "(509) 663-4772",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "919",
        "Company": "Committee to Elect Dave Ellis",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 469-3012",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "921",
        "Company": "The News Leader",
        "Contact": "",
        "Email": "",
        "Phone": "(731) 847-6354",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "922",
        "Company": "U.S. Government Publishing",
        "Contact": "",
        "Email": "",
        "Phone": "(404) 605-9160",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "923",
        "Company": "Smith & Assoc Richardson",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 641-4537",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "924",
        "Company": "Double R Reprographics",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 342-3273",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "925",
        "Company": "Michelle Ruiz",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 205-8807",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "926",
        "Company": "Smith & Assoc Olympia Hio",
        "Contact": "",
        "Email": "",
        "Phone": "(614) 561-5996",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "927",
        "Company": "GS Lawn & Landscape",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "928",
        "Company": "City of Seminole",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 391-0204",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "929",
        "Company": "Bay Tech Label",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 572-9311",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "930",
        "Company": "A One Fire",
        "Contact": "",
        "Email": "",
        "Phone": "(407) 415-7391",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "931",
        "Company": "Hashtag Creative",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 480-0883",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "932",
        "Company": "The Florida Federation of Womens Clubs",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 365-7112",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "934",
        "Company": "Joseph Perlman",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 536-2711",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "935",
        "Company": "Dimension Printing",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Celeste Hall",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "937",
        "Company": "Promotion Marketing",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 626-2700",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "938",
        "Company": "Miss Latina Tampa/Global Mktg. Media",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 486-0762",
        "Sales Rep": "JJ Brenelli",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "941",
        "Company": "Coriolis Consulting Group",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 971-1303",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "942",
        "Company": "Pinellas County Sheriff's",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 582-5959",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "943",
        "Company": "ComDesign, Inc.",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 579-1600",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "946",
        "Company": "Florida Medical Clinic",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 780-2643",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "947",
        "Company": "INT'L Leonard Peltier DEF",
        "Contact": "",
        "Email": "",
        "Phone": "(218) 790-7667",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "949",
        "Company": "Great America Financial Services Corpora",
        "Contact": "",
        "Email": "",
        "Phone": "(800) 685-6888",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "950",
        "Company": "Santa Rosa County, BOCC",
        "Contact": "",
        "Email": "",
        "Phone": "(850) 981-2018",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "952",
        "Company": "NSI-SC Mgmt.",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 286-6203",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "953",
        "Company": "Postalogic, LLC",
        "Contact": "",
        "Email": "",
        "Phone": "(561) 471-7000",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "954",
        "Company": "Great Gay 5K",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 624-1340",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "955",
        "Company": "L L DESIGN INC",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 993-0874",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "956",
        "Company": "Statement Peace",
        "Contact": "",
        "Email": "",
        "Phone": "(239) 265-0549",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "957",
        "Company": "Ultimate 3D Printing Stor",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 205-8848",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "959",
        "Company": "Skipper's Smokehouse",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 971-0666",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "960",
        "Company": "Dynamic Imaging & Distribution LLC",
        "Contact": "",
        "Email": "",
        "Phone": "(480) 629-4375",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "961",
        "Company": "Incomm",
        "Contact": "",
        "Email": "",
        "Phone": "(801) 945-0536",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "962",
        "Company": "NuView Marketing",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 754-9743",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "963",
        "Company": "Intrepid Power Boats",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 548-1260",
        "Sales Rep": "JJ Brenelli",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "964",
        "Company": "Bouchercon",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 278-6442",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "970",
        "Company": "SCAN",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 325-2955",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "971",
        "Company": "Dreambrands",
        "Contact": "",
        "Email": "",
        "Phone": "(602) 354-7640",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "972",
        "Company": "Magic Ballroom",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 481-6789",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "975",
        "Company": "Iraq-A-Ribs",
        "Contact": "Sammy Davis Jr.",
        "Email": "sammy@davis.com",
        "Phone": "(727) 573-1573",
        "Sales Rep": "House ",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "979",
        "Company": "Majority Strategies",
        "Contact": "",
        "Email": "",
        "Phone": "(904) 567-2008",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "980",
        "Company": "Smart Meter",
        "Contact": "",
        "Email": "",
        "Phone": "(415) 722-9365",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "997",
        "Company": "Tampa International Airport",
        "Contact": "Nina Mahoney",
        "Email": "NMahoney@TampaAirport.com",
        "Phone": "(813) 554-1479",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "999",
        "Company": "212 Group",
        "Contact": "",
        "Email": "",
        "Phone": "(215) 901-6440",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "1001",
        "Company": "Florida Southern College",
        "Contact": "",
        "Email": "",
        "Phone": "(863) 680-3883",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1014",
        "Company": "Leaders Casual Furniture",
        "Contact": "Edward Frederick",
        "Email": "ed.frederick@leadersfurniture.com",
        "Phone": "(727) 538-5577",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1016",
        "Company": "Clearwater Jolley Trolley",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 445-1200",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1022",
        "Company": "Sarasota Magazine",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 487-1116",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1029",
        "Company": "Diamond Media Solutions, Inc.",
        "Contact": "",
        "Email": "",
        "Phone": "(888) 942-7117",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1034",
        "Company": "PLS Print Thom",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 573-1763",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1035",
        "Company": "Pinellas Park Fire Department",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 369-5808",
        "Sales Rep": "Celeste Hall",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1037",
        "Company": "Pirate Jonnys",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 461-4505",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1055",
        "Company": "The Dali Museum",
        "Contact": "Dianne Birmingham",
        "Email": "DBirmingham@TheDali.org",
        "Phone": "(727) 823-3767",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1089",
        "Company": "Metropolitan Ministries",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 209-1000",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1099",
        "Company": "University of South Florida Purchasing",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 627-4218",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1102",
        "Company": "Independent Financial Partners",
        "Contact": "Michelle Peatee",
        "Email": "michelle.peatee@ifpartners.com",
        "Phone": "(813) 341-0960",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1103",
        "Company": "SARASOTA ORCHESTRA",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 487-2736",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1104",
        "Company": "Laura's Design Studio",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 416-2687",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1107",
        "Company": "Voodoo DM",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 426-5137",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "1108",
        "Company": "Yaeger Law",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1112",
        "Company": "Ringling College of Art and Design",
        "Contact": "DAVID FOOTE",
        "Email": "dfoote1@ringling.edu",
        "Phone": "(941) 955-2985",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1113",
        "Company": "UPrintPRO,LLC",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 288-3467",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1120",
        "Company": "NATIONAL SENIOR GAMES ASSOCIATION",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 475-4057",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1135",
        "Company": "Retro Christmas Card Company",
        "Contact": "Diane Dempsey",
        "Email": "info@retrochristmascardcompany.com",
        "Phone": "(727) 303-0250",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1142",
        "Company": "Eaton Power Quality Division",
        "Contact": "",
        "Email": "",
        "Phone": "(919) 870-3171",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1144",
        "Company": "McMullen Oil Company",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 573-0016",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "1150",
        "Company": "Creative Pinellas",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 582-2174",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1151",
        "Company": "Jabil",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1153",
        "Company": "Professional Resource Press",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 343-9601",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1156",
        "Company": "Stephanie Combs",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1158",
        "Company": "Greater Pinellas County Fair",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 544-4777",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1159",
        "Company": "TRACY MINK DESIGN",
        "Contact": "",
        "Email": "",
        "Phone": "(513) 884-2377",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1161",
        "Company": "Movement2819",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1162",
        "Company": "A Walk Around The Block",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 483-4554",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "1163",
        "Company": "NATIONAL SENIOR GAMES ASSOCIATION",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 475-4036",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1167",
        "Company": "Creative Printing and Publishing",
        "Contact": "",
        "Email": "",
        "Phone": "(407) 302-9147",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1169",
        "Company": "THE B3 METHOD INSTITUTE",
        "Contact": "",
        "Email": "",
        "Phone": "(513) 486-6485",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1171",
        "Company": "Florida Print Solutions",
        "Contact": "Norm Hart",
        "Email": "orders@flprintsolutions.com",
        "Phone": "(727) 327-5500",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1175",
        "Company": "Thunder Beach Productions",
        "Contact": "",
        "Email": "",
        "Phone": "(850) 249-7627",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "1176",
        "Company": "Simpatico.",
        "Contact": "",
        "Email": "",
        "Phone": "(914) 356-3812",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1191",
        "Company": "Swiss Cove Christian Church",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1199",
        "Company": "Nicole Morris Creative",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "1204",
        "Company": "Samoset 1st Baptist Church",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 746-8117",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1206",
        "Company": "Keene Terrace Baptist Church",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 249-9958",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1212",
        "Company": "Grupo Folklorico Mahetzi",
        "Contact": "hermenegildo pioquinto",
        "Email": "merehgo@hotmail.com",
        "Phone": "(727) 657-3360",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1214",
        "Company": "The Driven Ziggy LLC",
        "Contact": "",
        "Email": "",
        "Phone": "(703) 625-8495",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1216",
        "Company": "Lizard Juice",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1221",
        "Company": "PrimeNet Direct Marketing Solutions",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 812-5187",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1227",
        "Company": "Area Printing",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 734-5444",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1229",
        "Company": "Straz Center",
        "Contact": "Randy Gilmore",
        "Email": "Randy.gilmore@strazcenter.org",
        "Phone": "(813) 222-",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1232",
        "Company": "Okaloosa County-Destin-Ft. Walton Beach",
        "Contact": "",
        "Email": "",
        "Phone": "(850) 689-5960",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1234",
        "Company": "AMERX Health Care",
        "Contact": "Josh Trujillo",
        "Email": "jxt@amerxhc.com",
        "Phone": "(727) 443-0530",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1248",
        "Company": "The Walsh Group",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "1249",
        "Company": "Patrick Buckley",
        "Contact": "",
        "Email": "",
        "Phone": "(407) 274-3772",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1252",
        "Company": "Professional Media Group",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 807-7121",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1253",
        "Company": "Current Hotel - Tampa",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 742-1096",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "1264",
        "Company": "Dex Imaging",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 570-8868",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1276",
        "Company": "Sunshine Health",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 286-6262",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1283",
        "Company": "ALL LED",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 524-4270",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1295",
        "Company": "Jeanne Netherton",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "1296",
        "Company": "Florida State Fire Fighters Association",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 601-5202",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "1297",
        "Company": "Heather Lauter, PA",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 342-3800",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "1298",
        "Company": "Vero Christian Church",
        "Contact": "",
        "Email": "",
        "Phone": "(772) 321-3175",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1299",
        "Company": "DNS Enterprises",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 766-8132",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "1302",
        "Company": "Cushman & Wakefield",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 204-5339",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "1303",
        "Company": "Ryan Mcardle",
        "Contact": "",
        "Email": "",
        "Phone": "(253) 209-5443",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1306",
        "Company": "Access Ready",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1310",
        "Company": "Bula Nation",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 498-8913",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "1313",
        "Company": "Access Mail",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1314",
        "Company": "TrYumph Functional Fitness",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 394-3151",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "1315",
        "Company": "Braman Group",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 785-4507",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1317",
        "Company": "Better Homes & Gardens - Beimei Kohler",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 520-9070",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1318",
        "Company": "Better Homes & Gardens - Larry Manning",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 957-4493",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1319",
        "Company": "FN Sharp",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "1320",
        "Company": "Christina Arcuri",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 774-1441",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1321",
        "Company": "HBT DIGITAL CONSULTING",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 695-2982",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1325",
        "Company": "PASCO COUNTY PURCHASING DEPT",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 847-8194",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1326",
        "Company": "Better Homes & Gardens - Mark Hausherr",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1331",
        "Company": "Biosound Technologies",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1332",
        "Company": "The GO Agency",
        "Contact": "",
        "Email": "",
        "Phone": "(866) 926-2636",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "1333",
        "Company": "Better Homes & Gardens - Tracey Pines",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 245-7912",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1336",
        "Company": "DO YOUR GIN",
        "Contact": "",
        "Email": "",
        "Phone": "(647) 550-3042",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1338",
        "Company": "TAMPA BAY ULTIMATE, INC.",
        "Contact": "",
        "Email": "",
        "Phone": "(267) 210-2948",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1346",
        "Company": "ANDRICK & ASSOCIATES",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 351-6565",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1347",
        "Company": "Miya Guten, LCSW",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 687-7969",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "1349",
        "Company": "Alaska Bible College",
        "Contact": "",
        "Email": "",
        "Phone": "(352) 797-9797",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1351",
        "Company": "The Woods & Wanton Chapter Inc",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 289-1494",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1352",
        "Company": "Howard W. Blake High School",
        "Contact": "Wilson Lester",
        "Email": "pennsylvaniapop51@gmail.com",
        "Phone": "(813) 240-2186",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1354",
        "Company": "PERFORMANCE COPYING & PRINTING",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 351-5500",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1356",
        "Company": "Fit & Finish Installations",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 286-0778",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "1359",
        "Company": "David Marble",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1360",
        "Company": "CITY OF LARGO",
        "Contact": "KATE OYER",
        "Email": "koyer@largo.com",
        "Phone": "(727) 587-6740",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1362",
        "Company": "Better Homes & Gardens - Fran Winston",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1363",
        "Company": "Innovative Properties Worldwide, Inc",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 642-6685",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1365",
        "Company": "High-Water Flood",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1366",
        "Company": "St. Marks Episcopal School",
        "Contact": "",
        "Email": "",
        "Phone": "(321) 639-5771",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "1367",
        "Company": "Better Homes & Gardens - Tammy Liebchen",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1369",
        "Company": "Van Hillo Printing",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 873-7000",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1378",
        "Company": "Singha Law Group",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 327-3219",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "1380",
        "Company": "SS White",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1389",
        "Company": "GPS",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 458-3478",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1390",
        "Company": "McCloskey Audit Services",
        "Contact": "",
        "Email": "",
        "Phone": "(678) 863-6630",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "1391",
        "Company": "RCM Utilities",
        "Contact": "Karen Eckles",
        "Email": "karen@rcmutilities.com",
        "Phone": "(352) 561-2990",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "1392",
        "Company": "One Brand Marketing",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 321-0156",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1395",
        "Company": "Better Homes & Gardens - Ali Lund",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1396",
        "Company": "ImpelUP",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1397",
        "Company": "Tom Stroligo",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "1399",
        "Company": "Interdezign",
        "Contact": "",
        "Email": "",
        "Phone": "(863) 640-3413",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1406",
        "Company": "Clear Channel Outdoor",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 299-2462",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1408",
        "Company": "Stonehill Innovation",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 444-1984",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1411",
        "Company": "Florida Trend",
        "Contact": "Connie Greenblatt",
        "Email": "",
        "Phone": "(727) 892-2654",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1412",
        "Company": "Jared Barr",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 952-8123",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1418",
        "Company": "Rogers Blue Jays Baseball Partnership",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 738-7024",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1420",
        "Company": "Heartland Dental",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1421",
        "Company": "RENEW",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1423",
        "Company": "Better Homes & Gardens - Clay Brown",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1424",
        "Company": "Bay City Rehab & Wellness",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1427",
        "Company": "Calabash Restaurant",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 350-3176",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1429",
        "Company": "INCM",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1430",
        "Company": "Pinellas Community Foundation",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1431",
        "Company": "PARTNERS IN PRINTING",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 996-7785",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1432",
        "Company": "Hawks Point HOA",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "1434",
        "Company": "The Hollis Co.",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1435",
        "Company": "Berkshire Hathaway Home Services - Jodie Southern",
        "Contact": "Jodie Southern",
        "Email": "jsouthern@bhhsflpg.com",
        "Phone": "(727) 484-6429",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1436",
        "Company": "Better Homes & Gardens - David Lawrence",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1439",
        "Company": "DELUVIA SKINCARE & MINERAL COSMETICS",
        "Contact": "",
        "Email": "",
        "Phone": "(877) 335-8842",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1441",
        "Company": "Better Homes & Gardens - Gipsy Perry",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1442",
        "Company": "Business Card Express",
        "Contact": "Samantha Tassillo",
        "Email": "samantha@bceonline.com",
        "Phone": "(727) 812-7367",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1445",
        "Company": "Book + Bottle",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 460-7667",
        "Sales Rep": "JJ Brenelli",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1447",
        "Company": "Lighthouse of Pinellas",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 544-4433",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1454",
        "Company": "Media Lab Printing",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 549-9886",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1457",
        "Company": "Better Homes & Gardens - Jackie Brazil",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1460",
        "Company": "B & L INVESTMENTS",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 230-2449",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1465",
        "Company": "GenoxUSA",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 960-1146",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1466",
        "Company": "EMS Safety",
        "Contact": "",
        "Email": "",
        "Phone": "(800) 447-3177",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1467",
        "Company": "J & K Enterprises Printing",
        "Contact": "Doug Cotton",
        "Email": "JK.enterprises.followup@gmail.com",
        "Phone": "(813) 948-0048",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1468",
        "Company": "Flip 'n Tasty Filipino Food Truck",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 709-3655",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "1470",
        "Company": "Sir Speedy - Largo",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 584-7136",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1472",
        "Company": "LIFT FL ACADEMY",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 560-5806",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1475",
        "Company": "Better Homes & Gardens - Victoria McGuir",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1476",
        "Company": "Better Homes & Gardens - Victoria McGuir",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1477",
        "Company": "Legacy Hotel at IMG Academy",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 245-1900",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1481",
        "Company": "Family First",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 222-8300",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "1482",
        "Company": "Unlimited Printing – Permanently close",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 273-0240",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1483",
        "Company": "Wellness Meetings",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 522-5833",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1485",
        "Company": "CARE NET",
        "Contact": "",
        "Email": "",
        "Phone": "(703) 554-8727",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1486",
        "Company": "Robby Thurston, PA",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 201-4097",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1487",
        "Company": "Better Homes & Gardens - Lina Awadallah",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1488",
        "Company": "TOHO WATER AUTHORITY",
        "Contact": "",
        "Email": "",
        "Phone": "(407) 944-5180",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1489",
        "Company": "Crossman & Co",
        "Contact": "",
        "Email": "",
        "Phone": "(407) 581-6246",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1490",
        "Company": "Leonard Wealth",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1491",
        "Company": "Gray & Co",
        "Contact": "Joe Weinert",
        "Email": "jmweiner@cherryman.com",
        "Phone": "(0) -",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1492",
        "Company": "Advanced Cataract & Glaucoma Care, PLLC",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1495",
        "Company": "Pascals Artisan Bistro and Gourmet Coffe",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 513-5478",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1497",
        "Company": "Pioneer Printing",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 232-1722",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1499",
        "Company": "St. Michael's Eye & Laser Institute",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 500-2020",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1502",
        "Company": "Collier County/ Naples, FL",
        "Contact": "",
        "Email": "",
        "Phone": "(239) 252-5852",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1508",
        "Company": "Lightning Moving",
        "Contact": "",
        "Email": "",
        "Phone": "(888) 272-2031",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1510",
        "Company": "Maverick Marketing Group",
        "Contact": "",
        "Email": "",
        "Phone": "(321) 313-5068",
        "Sales Rep": "Celeste Hall",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1511",
        "Company": "Trxade / Community Specialty Pharmacy",
        "Contact": "",
        "Email": "",
        "Phone": "(800) 805-8705",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1512",
        "Company": "Dalton Wade Real Estate Group - Alan Pra",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1513",
        "Company": "PASCO COUNTY PUBLIC WORKS",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 834-3611",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1514",
        "Company": "Envelope Printing Supplier LLC",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1515",
        "Company": "CDR MAGUIRE",
        "Contact": "",
        "Email": "",
        "Phone": "(305) 409-1223",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1519",
        "Company": "Unifirst First Aid Corp. (dba Medique Pr",
        "Contact": "",
        "Email": "",
        "Phone": "(573) 291-7221",
        "Sales Rep": "House ",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1521",
        "Company": "Robotic Parking",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 539-7275",
        "Sales Rep": "JJ Brenelli",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1526",
        "Company": "Sutter Roofing Company",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 377-1000",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1527",
        "Company": "Leadership St. Pete",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1530",
        "Company": "Starling Printing",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 452-0050",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1532",
        "Company": "FLURO-Gelenklager GmbH",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 743-6872",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1538",
        "Company": "Print Right Technologies",
        "Contact": "Monique Harrison",
        "Email": "monique@prthome.com",
        "Phone": "(813) 254-2144",
        "Sales Rep": "House ",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1541",
        "Company": "Iron Hill Plastic Cards",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 474-9900",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1542",
        "Company": "CITY OF COCOA",
        "Contact": "",
        "Email": "",
        "Phone": "(321) 433-8775",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1545",
        "Company": "Boxes4Products",
        "Contact": "",
        "Email": "",
        "Phone": "(863) 337-6900",
        "Sales Rep": "OUT OF BUS",
        "CSR": "OUT OF BUS",
        "": ""
    },
    {
        "customer": "1546",
        "Company": "Empart USA",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1550",
        "Company": "Better Homes & Gardens - Roilan Vazquez",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1551",
        "Company": "Burnett Law P.A",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 220-5000",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "1556",
        "Company": "Catholic Foundation of Central Florida",
        "Contact": "",
        "Email": "",
        "Phone": "(407) 246-7188",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "1559",
        "Company": "Transact Payment Systems",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 568-7075",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1560",
        "Company": "Symmetry Surgical, Inc.",
        "Contact": "Joy Sanderson",
        "Email": "Joy.Sanderson@aspensurgical.com",
        "Phone": "(502) 802-7356",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1564",
        "Company": "Sunshine Graphics",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "1566",
        "Company": "Better Homes & Gardens - Roberto Rodrigu",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1568",
        "Company": "CENTRO DE LA FAMILIA CRISTIANA DE TAMPA",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 771-5412",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1572",
        "Company": "FLORIDA FRONTIERSMEN, INC,",
        "Contact": "JOY MILFORD JOY",
        "Email": "joym78889@gmail.com",
        "Phone": "(727) 488-9676",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1574",
        "Company": "Better Homes & Gardens - Will Curtis",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1575",
        "Company": "Franklin Davis Printing Enterprise",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 368-2607",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1577",
        "Company": "Homes2Value",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1582",
        "Company": "Better Homes & Gardens - Nicole Leicht",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1583",
        "Company": "Cigar City Marketing",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 798-7561",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1584",
        "Company": "FKQ Advertising",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 539-8800",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1585",
        "Company": "VIP Eyecare",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 295-0500",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "1592",
        "Company": "Sports Collectibles",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 452-8665",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1597",
        "Company": "SLOAN DESIGN",
        "Contact": "",
        "Email": "",
        "Phone": "(586) 292-4706",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1598",
        "Company": "Sir Speedy - Tampa",
        "Contact": "Jackie Weinel",
        "Email": "jackie@sirspeedytampa.com",
        "Phone": "(813) 623-5478",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1600",
        "Company": "Postillion Wealth Management, LLC",
        "Contact": "",
        "Email": "",
        "Phone": "(833) 342-5796",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1601",
        "Company": "Calev Systems",
        "Contact": "",
        "Email": "",
        "Phone": "(305) 672-2900",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1602",
        "Company": "Harvest Harmonics",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1608",
        "Company": "University of Tampa",
        "Contact": "Josh Napier",
        "Email": "jnapier@ut.edu",
        "Phone": "(813) 257-3852",
        "Sales Rep": "JJ Brenelli",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1610",
        "Company": "IatricSystems",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1618",
        "Company": "Sure Med Compliance",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 773-7138",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1620",
        "Company": "BathMasters",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1621",
        "Company": "Better Homes & Gardens - Ben McCartney",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1626",
        "Company": "Belle Isle Realty",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1628",
        "Company": "Pinellas County School Board",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 588-6387",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1630",
        "Company": "H.I. Development",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 229-6686",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1631",
        "Company": "Island in the Sun Realty",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1634",
        "Company": "Pyper Inc",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 873-1210",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1636",
        "Company": "Better Homes & Gardens - Nichole Moody",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1637",
        "Company": "Propel Florida",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1642",
        "Company": "Florida Resurrection House",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1646",
        "Company": "Gary Puckett",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 709-0099",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1647",
        "Company": "Protect My Car",
        "Contact": "",
        "Email": "",
        "Phone": "(716) 720-0448",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1648",
        "Company": "Calista Cabinets",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 977-1100",
        "Sales Rep": "House ",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1649",
        "Company": "TASTIC PHOTO INC.",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 742-9459",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1651",
        "Company": "ACUMEN MARKETING GROUP",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 290-6742",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1653",
        "Company": "Better Homes & Gardens - Aviree Jordan",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1654",
        "Company": "Better Homes & Gardens - Renee Gingold",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1655",
        "Company": "Florida Executive Realty",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1656",
        "Company": "PCG Companies",
        "Contact": "Dawn Pasch",
        "Email": "dawn.pasch@pcgcompanies.com",
        "Phone": "(732) 450-8200",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1657",
        "Company": "Incite Strategies",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "Juliana Ainslie",
        "": ""
    },
    {
        "customer": "1658",
        "Company": "HOUSE OF TRUTH",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 412-5414",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1659",
        "Company": "AKMG",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1660",
        "Company": "The Pineapple Projects",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1663",
        "Company": "Calle Creative",
        "Contact": "",
        "Email": "",
        "Phone": "(678) 603-7295",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1665",
        "Company": "Rainsford & Associates",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 686-2836",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1670",
        "Company": "Venture Outdoors",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 290-6742",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1671",
        "Company": "Sports Facilities Companies",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 474-3845",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1673",
        "Company": "Better Homes & Gardens - Gabrielle Palaz",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1678",
        "Company": "CU Recovery & The Loan Service Center",
        "Contact": "",
        "Email": "",
        "Phone": "(800) 377-1798",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1680",
        "Company": "Future Home Realty",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1681",
        "Company": "Florida Academy of Pain Medicine (FAPM)",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 808-4131",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1682",
        "Company": "The Medicare Basics",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 205-7245",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "1686",
        "Company": "Priority One Financial",
        "Contact": "Stephani Eberline",
        "Email": "SEberline@p1fs.com",
        "Phone": "(727) 812-7421",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1689",
        "Company": "Finesse Unique Corporation",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 238-4539",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1696",
        "Company": "COUNTRYSIDE CHRISTIAN CENTER",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 799-1618",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1701",
        "Company": "SNEED STUDIOS",
        "Contact": "",
        "Email": "",
        "Phone": "(256) 702-7787",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1702",
        "Company": "IQVIA",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 218-7971",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1703",
        "Company": "Brent Palmisano",
        "Contact": "",
        "Email": "",
        "Phone": "(407) 625-0303",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1705",
        "Company": "24 Home Buyer LLC",
        "Contact": "",
        "Email": "",
        "Phone": "(856) 304-6316",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1707",
        "Company": "KVK Tech, Inc",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1708",
        "Company": "Gold N Diamond",
        "Contact": "Ali Shah",
        "Email": "alishah@gndatlanta.com",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1711",
        "Company": "Benchmark International",
        "Contact": "Brian Halsor",
        "Email": "BHalsor@benchmarkintl.com",
        "Phone": "(813) 898-2350",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1712",
        "Company": "AMB Group",
        "Contact": "",
        "Email": "",
        "Phone": "(305) 414-4135",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1713",
        "Company": "APG Electric",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 530-0077",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1716",
        "Company": "A.L. Commercial, Inc.",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1718",
        "Company": "Better Homes & Gardens - Agnieszka Pisar",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1720",
        "Company": "Better Homes & Gardens - Jennie Demas",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1721",
        "Company": "Charter Foods",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 798-7561",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1723",
        "Company": "Spectrum Paint",
        "Contact": "",
        "Email": "",
        "Phone": "(918) 894-5841",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1724",
        "Company": "Roberto Rodriguez - Keller Williams",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1725",
        "Company": "MedRx",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 584-9600",
        "Sales Rep": "JJ Brenelli",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1726",
        "Company": "Southeast QSR, LLC",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 409-4914",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1727",
        "Company": "GASPARILLA FESTIVAL OF THE ARTS, INC",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 957-7496",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1728",
        "Company": "Piper's Angels Foundation",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1730",
        "Company": "Best Value HealthCare LLC",
        "Contact": "",
        "Email": "",
        "Phone": "(239) 285-1324",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1732",
        "Company": "Dependable Driveshaft, Inc.",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 573-9107",
        "Sales Rep": "Celeste Hall",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1733",
        "Company": "Stetson Law",
        "Contact": "Sandy Cromp",
        "Email": "scromp@law.stetson.edu",
        "Phone": "(727) 562-7912",
        "Sales Rep": "JJ Brenelli",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1734",
        "Company": "GRAND RAPIDS SYMPHONY",
        "Contact": "",
        "Email": "",
        "Phone": "(616) 454-9451",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1737",
        "Company": "Floridata",
        "Contact": "Jeannette Adelman",
        "Email": "jeannette@floridata.net",
        "Phone": "(813) 777-0113",
        "Sales Rep": "Donald Bonin",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1738",
        "Company": "Better Homes & Gardens - Leslie Chrysoch",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nate",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1742",
        "Company": "CoreRX",
        "Contact": "",
        "Email": "",
        "Phone": "(732) 266-4899",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1743",
        "Company": "Florida Utilities Coordinating Committee",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "JJ Brenelli",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1747",
        "Company": "USF Contemporary Art Museum",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1759",
        "Company": "LPT Realty",
        "Contact": "Vince Arcuri",
        "Email": "vince@headofrealestate.com",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1760",
        "Company": "REALIZE BRADENTON, INC",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 350-8563",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1761",
        "Company": "Landon Korabek Foundation",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1762",
        "Company": "AurabyOra",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 286-7036",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1764",
        "Company": "PASCO COUNTY EMERGENCY MANAGEMENT",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 847-8114",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1768",
        "Company": "Kathleen Hessinger for Judge",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 667-3142",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1769",
        "Company": "Lindsay Blaylock",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1770",
        "Company": "WEDU PBS",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 739-2940",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1772",
        "Company": "Genia USA",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 785-5320",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1773",
        "Company": "Purity Bay",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 286-8374",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1775",
        "Company": "Wallace Welch and Willingham",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1780",
        "Company": "LPT Realty - Nicole Leicht",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1782",
        "Company": "FAMILY RESOURCES",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 741-3575",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1783",
        "Company": "Asolo Repertory Theatre",
        "Contact": "",
        "Email": "",
        "Phone": "(404) 353-1081",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1786",
        "Company": "Linda Hansen",
        "Contact": "",
        "Email": "",
        "Phone": "(314) 775-7776",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1787",
        "Company": "Shore Lake Dental",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1794",
        "Company": "Verified Label, Print and Promotions, In",
        "Contact": "Ray Sikorski",
        "Email": "ray@verifiedlabel.com",
        "Phone": "(813) 290-7721",
        "Sales Rep": "Robert Hamilton",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1796",
        "Company": "Nina Ibrahimbegovic",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 380-1077",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1806",
        "Company": "ARTS and CULTURAL ALLIANCE of Sarasota C",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 365-5118",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1810",
        "Company": "ARTS and CULTURAL ALLIANCE of Sarasota C",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 365-5118",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1811",
        "Company": "LPT Realty - Jackie Brazil",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1812",
        "Company": "COASTAL CARE STAFFING",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 365-5079",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1816",
        "Company": "Tampa Bay Regional Planning Council",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 570-5151",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1818",
        "Company": "Palm Printing",
        "Contact": "Charles Zweil",
        "Email": "Charles@PalmPrinting.com",
        "Phone": "(941) 907-0090",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1819",
        "Company": "Metro Inclusive Health",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 321-3854",
        "Sales Rep": "Celeste Hall",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1820",
        "Company": "Conversa",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 579-2157",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1823",
        "Company": "76 Group",
        "Contact": "",
        "Email": "",
        "Phone": "(720) 420-4250",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1832",
        "Company": "Gravesandy & Associates, LLC",
        "Contact": "Tamika Gravesandy",
        "Email": "graves.assoc.llc@gmail.com",
        "Phone": "(0) -",
        "Sales Rep": "Robert Hamilton",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1834",
        "Company": "Tribute technology",
        "Contact": "",
        "Email": "",
        "Phone": "(303) 717-3774",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1836",
        "Company": "PMCC 4th Watch",
        "Contact": "",
        "Email": "",
        "Phone": "(310) 800-8991",
        "Sales Rep": "Celeste Hall",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1837",
        "Company": "Tampa Electric Co",
        "Contact": "Marian Munday",
        "Email": "MRMunday@tecoenergy.com",
        "Phone": "(0) -",
        "Sales Rep": "Celeste Hall",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1839",
        "Company": "Ronati",
        "Contact": "",
        "Email": "",
        "Phone": "(866) 739-8343",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1840",
        "Company": "DISCOVERY MAP INTERNATIONAL",
        "Contact": "",
        "Email": "",
        "Phone": "(802) 316-4060",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1841",
        "Company": "PharmaLink",
        "Contact": "",
        "Email": "",
        "Phone": "(800) 257-3527",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1842",
        "Company": "Lawton Printers",
        "Contact": "",
        "Email": "",
        "Phone": "(877) 330-1900",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "1844",
        "Company": "F45 - Land O Lakes",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1850",
        "Company": "Friends of Chabad Lubavitch of Tampa & C",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 963-2317",
        "Sales Rep": "Celeste Hall",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1853",
        "Company": "St. Petersburg College",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 302-6537",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1854",
        "Company": "National Safe Boating Council",
        "Contact": "",
        "Email": "",
        "Phone": "(703) 361-4294",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1855",
        "Company": "UNITED WORLD MISSION",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 392-6103",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1857",
        "Company": "Salvo Technologies",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 544-3736",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1858",
        "Company": "Spectrio",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 249-7775",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1863",
        "Company": "Frank Miller",
        "Contact": "",
        "Email": "",
        "Phone": "(201) 744-6699",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1865",
        "Company": "Mail Marketing Printing",
        "Contact": "Debbie Stewart",
        "Email": "dstewart4@tampabay.rr.com",
        "Phone": "(727) 560-7174",
        "Sales Rep": "Robert Hamilton",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1870",
        "Company": "SWEL EVENTS",
        "Contact": "",
        "Email": "",
        "Phone": "(610) 306-7664",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1876",
        "Company": "MedTrainer",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1877",
        "Company": "St. Petersburg Audubon Society",
        "Contact": "Ron Smith",
        "Email": "rsmith@gmail.com",
        "Phone": "(727) 657-9154",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1881",
        "Company": "Miami-Dade Family Learning Partnership",
        "Contact": "",
        "Email": "",
        "Phone": "(305) 974-2422",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1882",
        "Company": "MARKET AMERICA",
        "Contact": "",
        "Email": "",
        "Phone": "(336) 389-5848",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1883",
        "Company": "NATURE CURE OF SOUTH FLORIDA, INC.",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 314-9814",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1884",
        "Company": "The Children's Forum, Inc.",
        "Contact": "Brenda Romuald",
        "Email": "bromuald@thechildrensforum.com",
        "Phone": "(850) 487-6331",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "Pamela Brodeur",
        "": ""
    },
    {
        "customer": "1886",
        "Company": "LPT Realty - Alan Prather",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1887",
        "Company": "AlphaOmega Bible Study",
        "Contact": "",
        "Email": "",
        "Phone": "(616) 915-4525",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1890",
        "Company": "Coping Block Skateboarding Magazine, LLC",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 677-0911",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1891",
        "Company": "Judge Public Relations",
        "Contact": "Christy Monahan",
        "Email": "christy.monahan@judgepr.com",
        "Phone": "(813) 279-8335",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1892",
        "Company": "Astaras",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 546-9600",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1893",
        "Company": "Straighten Up Orthodontics",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1899",
        "Company": "Unify Financial LLC",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 306-0299",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1900",
        "Company": "Loggerhead Insurance",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 755-3972",
        "Sales Rep": "Celeste Hall",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1902",
        "Company": "Renew Movement",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1904",
        "Company": "AMERICAN FREEDOM DISTILLERY",
        "Contact": "Rachel Gunther",
        "Email": "rachel@horsesolderbourbon.com",
        "Phone": "(305) 968-7771",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1906",
        "Company": "The Dysautonomia Project",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 215-6845",
        "Sales Rep": "Celeste Hall",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1908",
        "Company": "Strange Cloudz",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1909",
        "Company": "Stone Mart",
        "Contact": "Diana Zuniga",
        "Email": "Diana@stone-mart.com",
        "Phone": "(813) 885-6900",
        "Sales Rep": "Celeste Hall",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1910",
        "Company": "Polish to Shine Cleaning Services",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 698-2258",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1912",
        "Company": "B.O.F. Innovative Marketing",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1913",
        "Company": "AUDREY BROOKS",
        "Contact": "",
        "Email": "",
        "Phone": "(239) 689-0906",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1915",
        "Company": "Alyssa Brodnax",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Celeste Hall",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1916",
        "Company": "Adrenaline Media, Inc.",
        "Contact": "Margie Birriel",
        "Email": "mbirriel@goadmi.com",
        "Phone": "(407) 347-3007",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1917",
        "Company": "Childrens Home Society of Florida",
        "Contact": "",
        "Email": "",
        "Phone": "(352) 315-6670",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1920",
        "Company": "EAGLE LABS INC.",
        "Contact": "Rosalie Winger",
        "Email": "rosalie.winger@eaglelabsinc.com",
        "Phone": "(614) 530-1485",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1922",
        "Company": "Radiant Massage",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1925",
        "Company": "Vently",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1929",
        "Company": "ASHEVILLE ART MUSEUM",
        "Contact": "",
        "Email": "",
        "Phone": "(828) 253-3227",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1932",
        "Company": "Pinellas County Supervisor of Elections",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 464-5719",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1933",
        "Company": "Route 1804 Foundation",
        "Contact": "Sandy Dorsainvil",
        "Email": "sandydorsainvil2023@gmail.com",
        "Phone": "(954) 260-8109",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1934",
        "Company": "Hillsborough County Supervisor Of Electi",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 367-8827",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1937",
        "Company": "The Florida Aquarium",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 367-4040",
        "Sales Rep": "Celeste Hall",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1939",
        "Company": "Socket Buddy",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1940",
        "Company": "Printing Depot",
        "Contact": "",
        "Email": "",
        "Phone": "(813) 855-6758",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1941",
        "Company": "Naples Envelope",
        "Contact": "",
        "Email": "",
        "Phone": "(239) 592-9377",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1943",
        "Company": "Observer Media Group",
        "Contact": "Robin Lankton",
        "Email": "rlankton@yourobserver.com",
        "Phone": "(0) -",
        "Sales Rep": "Celeste Hall",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1947",
        "Company": "Champion Labels",
        "Contact": "John Carter",
        "Email": "jcarter@championlabel.com",
        "Phone": "(321) 414-3434",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1949",
        "Company": "BS Biomedical Services LLC",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1950",
        "Company": "LPT Realty - Jeff Yglesias",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1951",
        "Company": "NDB Marketing, Inc",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 488-8409",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1952",
        "Company": "Donna Wilhelm",
        "Contact": "",
        "Email": "",
        "Phone": "(407) 579-3801",
        "Sales Rep": "Celeste Hall",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1955",
        "Company": "BRADENTON AREA CVB",
        "Contact": "",
        "Email": "",
        "Phone": "(941) 742-4057",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1956",
        "Company": "THE URBAN STILLHOUSE",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 440-8040",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1959",
        "Company": "Prints2go",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 239-6366",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1960",
        "Company": "EndoGastric Solutions, Inc.",
        "Contact": "Amy Spirides",
        "Email": "aspirides@endogastricsolutions.com",
        "Phone": "(425) 307-9200",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1961",
        "Company": "Keswick Christian School",
        "Contact": "Andrew Maddux",
        "Email": "amaddux@keswickchristian.org",
        "Phone": "(727) 329-7226",
        "Sales Rep": "Celeste Hall",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1962",
        "Company": "Unlimited DPI",
        "Contact": "",
        "Email": "",
        "Phone": "(863) 647-0074",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1967",
        "Company": "City of St. Petersburg",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 893-7221",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1969",
        "Company": "Help Me Grow Northwest Florida",
        "Contact": "",
        "Email": "",
        "Phone": "(850) 373-7761",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1970",
        "Company": "Newsom Eye",
        "Contact": "",
        "Email": "",
        "Phone": "(0) -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1975",
        "Company": "MEN OF THE WORD PRISON MINISTRY",
        "Contact": "",
        "Email": "",
        "Phone": "(727) 580-8819",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1980",
        "Company": "brandz agency",
        "Contact": "Sammy Veinger",
        "Email": "sammy@justbrandz.com",
        "Phone": "(866) 466-8565",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1981",
        "Company": "cornerstone insurance",
        "Contact": "Don Myers",
        "Email": "",
        "Phone": "(727) 631-1672",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "1986",
        "Company": "Enwright Rimes",
        "Contact": "",
        "Email": "",
        "Phone": "",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1987",
        "Company": "Florida Department of Health - Childrens Medical Services",
        "Contact": "Anna Dungey",
        "Email": "anna.dungey@flhealth.gov",
        "Phone": "(850) 841-8629",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1992",
        "Company": "Print Solutions",
        "Contact": "Joe Slater",
        "Email": "printsolutionstampa@gmail.com",
        "Phone": "(727) 457-6822",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1993",
        "Company": "Innovative Ink",
        "Contact": "Chuck Parkinson",
        "Email": "chuck@innovativeink.biz",
        "Phone": "(863) 688-7900",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1998",
        "Company": "Austin Marketing",
        "Contact": "Toni Austin",
        "Email": "",
        "Phone": "(813) 494-7930",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "1999",
        "Company": "Kaleidoscope Services",
        "Contact": "Brian",
        "Email": "",
        "Phone": "(727) 945-0548",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2001",
        "Company": "Water Medic of Cape Coral",
        "Contact": "Scott Hanft",
        "Email": "",
        "Phone": "(239) 541-2581",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2015",
        "Company": "Custom Covers",
        "Contact": "David Wiley",
        "Email": "david.customcovers@gmail.com",
        "Phone": "",
        "Sales Rep": "Celeste Hall",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2016",
        "Company": "Suzuki Marine USA, LLC",
        "Contact": "Deanna Burgess",
        "Email": "",
        "Phone": "(813) 687-7200",
        "Sales Rep": "Celeste Hall",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2017",
        "Company": "Joey D's",
        "Contact": "Tom Santangelo",
        "Email": "",
        "Phone": "() -",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2018",
        "Company": "LAUNCHGLOBAL",
        "Contact": "KATRINA WATTERS",
        "Email": "katrina.watters@launchglobal.org",
        "Phone": "(813) 545-7017",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2019",
        "Company": "Presstige Printing",
        "Contact": "Wes Weidenmiller",
        "Email": "",
        "Phone": "(239) 949-8518",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2021",
        "Company": "Medipac (US) International, Inc.",
        "Contact": "Paula McGovern",
        "Email": "",
        "Phone": "(416) 441-7038",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2022",
        "Company": "Complete Management Solutions",
        "Contact": "Randy Schulz",
        "Email": "rschulz@cms-fm.com",
        "Phone": "",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2024",
        "Company": "EWE Demand",
        "Contact": "JoAnn Klay",
        "Email": "jklay@ewedemand.com",
        "Phone": "(813) 205-7653",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2025",
        "Company": "Clear PH",
        "Contact": "Lindsey Humburg",
        "Email": "lindseyhumburg@clearph.com",
        "Phone": "(727) 851-9596",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2026",
        "Company": "McGraphics Printing",
        "Contact": "Suzi McKay",
        "Email": "",
        "Phone": "(727) 399-0801",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2027",
        "Company": "Royal Alliance",
        "Contact": "Lisa Whittye",
        "Email": "LWhittye@royalalliances.com",
        "Phone": "",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2028",
        "Company": "Ultimate Medical Academy",
        "Contact": "Rachel Wheeler",
        "Email": "",
        "Phone": "",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2029",
        "Company": "Kanes Furniture",
        "Contact": "Tom Burgess",
        "Email": "tburge@kanesfurniture.com",
        "Phone": "",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2030",
        "Company": "Monin Inc",
        "Contact": "Kim Gabriel",
        "Email": "kgabriel@monin.com",
        "Phone": "(727) 461-3033",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2031",
        "Company": "Braille Works International",
        "Contact": "Sarah Litchfield",
        "Email": "sarah@brailleworks.com",
        "Phone": "",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2035",
        "Company": "L&S Bait Co, Inc",
        "Contact": "Eric Bachnik",
        "Email": "ebacknik@mirrolure.com",
        "Phone": "(727) 584-7691",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2037",
        "Company": "Hope Community Church",
        "Contact": "Matt Wish",
        "Email": "matt.wish@gethope.net",
        "Phone": "",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2038",
        "Company": "Skyway Moving",
        "Contact": "Steve Brown",
        "Email": "steve@skywaymoving.com",
        "Phone": "",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2039",
        "Company": "test",
        "Contact": "test",
        "Email": "",
        "Phone": "",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2040",
        "Company": "test company",
        "Contact": "me",
        "Email": "",
        "Phone": "",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2042",
        "Company": "All Florida Fire Equipment",
        "Contact": "Brittany Ellsworth-Robertson",
        "Email": "Brittany@allfloridafire.com",
        "Phone": "",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2043",
        "Company": "BlueSky Innovations",
        "Contact": "Charles Larson",
        "Email": "charles@blueskymast.com",
        "Phone": "",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2044",
        "Company": "Mail Processing Associates",
        "Contact": "Robbie Yelvington",
        "Email": "robbie@mailpro.org",
        "Phone": "",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2046",
        "Company": "Rosette Media",
        "Contact": "Wendy Saunders",
        "Email": "wendy@rosettemediagroup.com",
        "Phone": "(727) 510-0600",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2047",
        "Company": "Mendez Foundation",
        "Contact": "Stuart Pheil",
        "Email": "spheil@mendezfoundation.org",
        "Phone": "",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2050",
        "Company": "Home Shopping Network",
        "Contact": "Alina Hernandez",
        "Email": "Alina.Hernandez@hsn.net",
        "Phone": "",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2051",
        "Company": "Duke Energy Center for the Arts",
        "Contact": "Kaylee Walker",
        "Email": "kwalker@themahaffeytheater.com",
        "Phone": "",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2052",
        "Company": "Museum of Fine Arts",
        "Contact": "Darcy Schuller",
        "Email": "DSchuller@mfastpete.org",
        "Phone": "(727) 896-2667",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2053",
        "Company": "The Stuart Society",
        "Contact": "Jane Sharrow",
        "Email": "",
        "Phone": "(727) 896-2667",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2054",
        "Company": "TCB Printing",
        "Contact": "Marvin Bailey",
        "Email": "mbailey@tcbprint.com",
        "Phone": "(727) 455-7579",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2055",
        "Company": "Excel Printing Inc.",
        "Contact": "Keith or Mike",
        "Email": "info@excelprintinginc.com",
        "Phone": "",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2056",
        "Company": "Institute of Reflexology",
        "Contact": "Gail Byers",
        "Email": "gailbyers@reflexology-usa.net",
        "Phone": "",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2057",
        "Company": "Brundage Medical",
        "Contact": "Patricia Brundage",
        "Email": "pbrundage@brundagegroup.com",
        "Phone": "(727) 674-8208",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2058",
        "Company": "PCI Media",
        "Contact": "Julie Bostick",
        "Email": "jbostick@pcimedia.org",
        "Phone": "",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2059",
        "Company": "Texas Custom Lures",
        "Contact": "Lowell Odom",
        "Email": "lowell@odomoutdoors.com",
        "Phone": "",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2061",
        "Company": "Influx Marketing",
        "Contact": "Max Baybak",
        "Email": "max@influxmarketing.com",
        "Phone": "(818) 254-7269",
        "Sales Rep": "Celeste Hall",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2062",
        "Company": "Wollinka Wikle Title Agency",
        "Contact": "Tina Babnik",
        "Email": "tina@wwtia.com",
        "Phone": "",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2063",
        "Company": "Bill Edwards Foundation for the Arts",
        "Contact": "Lori Belvedere / Amy Miller",
        "Email": "lbelvedere@billedwardsfoundationforthearts.org",
        "Phone": "",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2064",
        "Company": "Christina Creative",
        "Contact": "Christina Arasmo Beymer",
        "Email": "christinabeymer@gmail.com",
        "Phone": "(727) 754-9874",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2065",
        "Company": "Women of Grace",
        "Contact": "Thea Parsons",
        "Email": "tparsons@womenofgrace.com",
        "Phone": "(727) 492-2885",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2066",
        "Company": "Northeast Presbyterian Church",
        "Contact": "Scott Will",
        "Email": "scott@skaught.com",
        "Phone": "",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2067",
        "Company": "Biltmore Construction Co.",
        "Contact": "Don Bannister",
        "Email": "dbannister@biltmorefl.com",
        "Phone": "",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2068",
        "Company": "Mark E Industries",
        "Contact": "Paul Tuttle",
        "Email": "paul@markeindusties.com",
        "Phone": "(727) 771-9470",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2069",
        "Company": "Our Savior Lutheran Church",
        "Contact": "Diane",
        "Email": "",
        "Phone": "",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2070",
        "Company": "Faour Glass Technologies",
        "Contact": "Beverly Becker",
        "Email": "",
        "Phone": "",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2071",
        "Company": "Newport International",
        "Contact": "Carlos Velez",
        "Email": "c.velez@newportintl.com",
        "Phone": "",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2072",
        "Company": "Florida Eye Specialists",
        "Contact": "Chris Hill",
        "Email": "chris.hill@floridaeye.org",
        "Phone": "",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2073",
        "Company": "Dialysis at Sea",
        "Contact": "Brandie DeBroux",
        "Email": "brandie@dialysisatsea.com",
        "Phone": "",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2074",
        "Company": "CMSA Advertising",
        "Contact": "Mary Devine / Jeff Story",
        "Email": "",
        "Phone": "",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2075",
        "Company": "Armadillo Enterprises, Inc.",
        "Contact": "Chris Conella",
        "Email": "chris@deanguitars.com",
        "Phone": "",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2076",
        "Company": "Mote Marine laboratory and Aquarium",
        "Contact": "Alexis Crabtree",
        "Email": "acrabtree@mote.org",
        "Phone": "",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2077",
        "Company": "AOCUSA",
        "Contact": "Debbie Brett",
        "Email": "debbieb@amalie.com",
        "Phone": "",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2079",
        "Company": "Donovan Digital Marketing",
        "Contact": "Shawn Donovan",
        "Email": "shawn@donovan-digitalmarketing.com",
        "Phone": "",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2080",
        "Company": "St. Pete Catholic High School",
        "Contact": "Martin Rice",
        "Email": "",
        "Phone": "",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2084",
        "Company": "Creative Clay",
        "Contact": "Kim Dohrman",
        "Email": "kim@creativeclay.org",
        "Phone": "",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2087",
        "Company": "Celestes printing",
        "Contact": "",
        "Email": "",
        "Phone": "",
        "Sales Rep": "Celeste Hall",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2088",
        "Company": "JADE Design Concepts",
        "Contact": "Denise Mollfulleda",
        "Email": "denise@jadedesinconcepts.com",
        "Phone": "(813) 781-9995",
        "Sales Rep": "Celeste Hall",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2089",
        "Company": "Certified Water Solutions",
        "Contact": "Joe Rayl",
        "Email": "joerayl47@yahoo.com",
        "Phone": "",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2090",
        "Company": "Bausch and Lomb",
        "Contact": "",
        "Email": "",
        "Phone": "",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2093",
        "Company": "Iron Title Insurance",
        "Contact": "Ingrid Coulon",
        "Email": "ingrid.coulon@irontitle.com",
        "Phone": "",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2094",
        "Company": "Service Bindery",
        "Contact": "Kerri",
        "Email": "servicebindery@yahoo.com",
        "Phone": "",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "2095",
        "Company": "United Capital Funding",
        "Contact": "Alina Hall",
        "Email": "",
        "Phone": "",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2096",
        "Company": "Proof of the Pudding",
        "Contact": "Amanda Mazonkey",
        "Email": "amazonkey@proofpudding.com",
        "Phone": "",
        "Sales Rep": "Celeste Hall",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2097",
        "Company": "Innovative Concrete Technologies",
        "Contact": "Alyshia Liz",
        "Email": "alyshia@innovativeconcrete.com",
        "Phone": "",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2098",
        "Company": "Ruth Eckerd Hall",
        "Contact": "Debbie Lincoln",
        "Email": "dlincoln@rutheckerdhall.net",
        "Phone": "",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2100",
        "Company": "Lumber Plus",
        "Contact": "Ariana Usabal",
        "Email": "ariana.usabal@lumberplus.com",
        "Phone": "",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2106",
        "Company": "Clearwater Finishing and Packaging",
        "Contact": "Mark Heim",
        "Email": "mark@clearwaterfp.com",
        "Phone": "",
        "Sales Rep": "House ",
        "CSR": "House ",
        "": ""
    },
    {
        "customer": "2108",
        "Company": "U.S. Coast Guard",
        "Contact": "LT Ed Abma",
        "Email": "Edward.J.Abma@uscg.mil",
        "Phone": "",
        "Sales Rep": "Celeste Hall",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2109",
        "Company": "NextHome Gulf Coast",
        "Contact": "Tony Anderson",
        "Email": "tonyandersonrealtor@gmail.com",
        "Phone": "",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2118",
        "Company": "Seminole Community Library",
        "Contact": "Glenn Ferdman",
        "Email": "gferdman@myseminole.com",
        "Phone": "",
        "Sales Rep": "Celeste Hall",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2120",
        "Company": "THE RHOADS GROUP Advertising & Digital Marketing",
        "Contact": "PHIL BASILE",
        "Email": "pbasile@therhoadsgroup.com",
        "Phone": "",
        "Sales Rep": "Robert Hamilton",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2122",
        "Company": "Blue Lotus Treatment Center",
        "Contact": "Michelle Schuele",
        "Email": "mmschuele@gmail.com",
        "Phone": "",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2125",
        "Company": "World Advertising",
        "Contact": "Debbie Brooks",
        "Email": "worldad@msn.com",
        "Phone": "",
        "Sales Rep": "House ",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2127",
        "Company": "Thompson Safety Works",
        "Contact": "Brittany Ellsworth-Robertson",
        "Email": "Broberson@thompson-safety.com",
        "Phone": "",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2128",
        "Company": "Care-Net",
        "Contact": "Donna Clute",
        "Email": "dclute@care-net.org",
        "Phone": "",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2129",
        "Company": "Heart of Florida United Way",
        "Contact": "Jennifer Crenshaw",
        "Email": "jennifer.crenshaw@HFUW.org",
        "Phone": "",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2133",
        "Company": "DIME BANK",
        "Contact": "to come",
        "Email": "",
        "Phone": "",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2134",
        "Company": "America First Works",
        "Contact": "Bethany Torstenson",
        "Email": "btorstenson@americafirstworks.com",
        "Phone": "",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2135",
        "Company": "Premier Printing & Signs",
        "Contact": "Ted",
        "Email": "premierprint2016@gmail.com",
        "Phone": "",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2136",
        "Company": "Paramount Title",
        "Contact": "Ingrid Coulon",
        "Email": "Ingrid.Coulon@ptitlefl.com",
        "Phone": "",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2138",
        "Company": "St. Petersburg Opera Company",
        "Contact": "Carol Laughlin",
        "Email": "boxoffice@stpeteopera.org",
        "Phone": "",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2139",
        "Company": "Site Zeus",
        "Contact": "Jorge Hermez",
        "Email": "jhermez@sitezeus.com",
        "Phone": "",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2141",
        "Company": "World Of Westchase WOW",
        "Contact": "Karen Ring",
        "Email": "editor@westchasewow.com",
        "Phone": "",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2147",
        "Company": "AMDEN JEWELRY",
        "Contact": "Tess Lucas",
        "Email": "tess@amdeninc.com",
        "Phone": "",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2154",
        "Company": "Hansen Incarnati Dental Labs",
        "Contact": "Barbara Hansen",
        "Email": "barbarabritain@comcast.net",
        "Phone": "",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2156",
        "Company": "SOUTH FLORIDA SYMPHONY",
        "Contact": "CAROLINE MURRAY",
        "Email": "CMURRAY@SOUTHFLORIDASYMPHONY.ORG",
        "Phone": "",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2157",
        "Company": "Real Brokerage - Andrew Azzarello",
        "Contact": "Andrew Azzarello",
        "Email": "azzarellorealestate@gmail.com",
        "Phone": "",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2162",
        "Company": "FULLSTACK FULFILLMENT",
        "Contact": "ROSALIE WINGER",
        "Email": "ROSALIE.WINGER@EAGLELABSINC.COM",
        "Phone": "",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2163",
        "Company": "Pinellas Technical College",
        "Contact": "Alisha McGinnis",
        "Email": "mcginnisal@pcsb.org",
        "Phone": "",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2164",
        "Company": "Sand Hills EQM",
        "Contact": "Ines",
        "Email": "",
        "Phone": "",
        "Sales Rep": "Celeste Hall",
        "CSR": "",
        "": ""
    },
    {
        "customer": "2165",
        "Company": "New Jersey Business Forms Manufacturing Corporation",
        "Contact": "Denis Thellab",
        "Email": "dthellab@njbf.com",
        "Phone": "",
        "Sales Rep": "Robert Hamilton",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2166",
        "Company": "Island Companies",
        "Contact": "Leah Archibold​​​​",
        "Email": "Leah.Archibold@islandcompaniesltd.com",
        "Phone": "",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2169",
        "Company": "Spa Manufacturers Inc",
        "Contact": "Wendy",
        "Email": "wendy@spamanufacturers.com",
        "Phone": "",
        "Sales Rep": "Thomas Staudenmayer",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2172",
        "Company": "Bay Food Brokerage",
        "Contact": "Chris Chatterton",
        "Email": "operations@bayfoodbrokerage.com",
        "Phone": "",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2173",
        "Company": "Funeralwise",
        "Contact": "Evie Larson",
        "Email": "",
        "Phone": "",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2176",
        "Company": "First Baptist Church Ellijay",
        "Contact": "",
        "Email": "",
        "Phone": "",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2177",
        "Company": "Pelipost",
        "Contact": "Joe Calderon",
        "Email": "jcalderon2007@pelipost.me",
        "Phone": "",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2178",
        "Company": "College Ministry of Virginia",
        "Contact": "Josh Schmidt",
        "Email": "josh@cmov.org",
        "Phone": "",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2181",
        "Company": "Calev Systems",
        "Contact": "Jim Rath",
        "Email": "jrath@calevsystems.com",
        "Phone": "",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2184",
        "Company": "Warren Wilson College",
        "Contact": "Kevin Kehrberg, PhD",
        "Email": "",
        "Phone": "",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2185",
        "Company": "Gemini Graphics Printing",
        "Contact": "Jim Anderson",
        "Email": "geminigraphics30@gmail.com",
        "Phone": "",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2189",
        "Company": "Clerk of Circuit Court Printing & Mail Services",
        "Contact": "Todd Balika",
        "Email": "tbalika@mypinellasclerk.gov",
        "Phone": "",
        "Sales Rep": "Donald Bonin",
        "CSR": "Kelly Applegate",
        "": ""
    },
    {
        "customer": "2193",
        "Company": "Alphagraphics",
        "Contact": "Richard White",
        "Email": "richard.white@alphagraphics.com",
        "Phone": "",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2194",
        "Company": "Waterline Renewal",
        "Contact": "Autumn Gunter",
        "Email": "agunter@waterlinerenewal.com",
        "Phone": "",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2195",
        "Company": "Brady Schemerhorn",
        "Contact": "Brady",
        "Email": "bradybschermerhorn@gmail.com",
        "Phone": "",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2197",
        "Company": "Early Learning Coalition of Pinellas County",
        "Contact": "Wonetha Hall",
        "Email": "whall@elcpinellas.org",
        "Phone": "",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2199",
        "Company": "Fullstack Fulfillment",
        "Contact": "Rosalie WInger",
        "Email": "rosalie.winger@eaglelabsinc.com",
        "Phone": "",
        "Sales Rep": "Meredith Brown",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2203",
        "Company": "Blackhat Distillery",
        "Contact": "Shane Pendley",
        "Email": "shane@outsuite.com",
        "Phone": "",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2207",
        "Company": "Dalton Wade - Barbara Robinson",
        "Contact": "Barbara Robinson",
        "Email": "bwrobinson@hotmail.com",
        "Phone": "",
        "Sales Rep": "Nathan Blair",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2209",
        "Company": "Naples Print Source",
        "Contact": "Jason Olson",
        "Email": "jason@naplesprintsource.com",
        "Phone": "",
        "Sales Rep": "Pamela Brodeur",
        "CSR": "JJ Brenelli",
        "": ""
    },
    {
        "customer": "2211",
        "Company": "Back",
        "Contact": "Me",
        "Email": "Bryan Bradley",
        "Phone": "",
        "Sales Rep": "Celeste Hall",
        "CSR": "Celeste Hall",
        "": ""
    },
    {
        "customer": "2212",
        "Company": "Backwater Bandits Lure Co. LLC",
        "Contact": "Stephen Hamilton",
        "Email": "stephenhambone@gmail.com",
        "Phone": "",
        "Sales Rep": "Paul Vienuzis",
        "CSR": "Celeste Hall",
        "": ""
    }
]

console.log(hh)

const gether = () => {
  const j = []
  let g
  hh.map(item => {
  if(item.CSR === "Kelly Applegate"){j.push(item)}
  })
console.log(j)
}

  return (
    <MainWrap>

<div style={{width:"960px",height:"672px",border:"1px solid red"}} >
{
gether()
}
</div>
  
    </MainWrap>
  )
}

export default TEST