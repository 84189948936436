import React, { useRef,useEffect ,useState, useContext} from "react";
import Moment from 'react-moment';

import LOGO from '../Assets/plsprint.png'
import {AiFillPrinter} from 'react-icons/ai';
import styled from 'styled-components'
import ReactToPrint from "react-to-print";
import TextareaAutosize from 'react-textarea-autosize';
import { Button, Img, Label, Selectbox, TBody, THead, Table, Td, Th, Tr, currency } from "../utilities/Shared";
import { colorchange, employeeConvert } from "../utilities/Convertions";
import axios from "axios";
import UserContext from "../Context/UserContext";
import { getemployeebynamefromempid } from '../utilities/Convertions'

export const Wrapper = styled.div`
padding:20px 40px;
font-size:.8rem;

text-transform:capitalize;
font-family: Arial, Helvetica, sans-serif;
`;
export const Input = styled.input`

position: ${props => props.position || "relative"};

width:${props => props.width || "90%"};
padding:${props => props.padding || "5px 3px 0px 3px"};
max-width:${props => props.maxWidth || "100%"};
margin:${props => props.margin || "0px 0px 0px 0px"};
height: ${props => props.height || ""};
font-size:${props => props.fontSize || ".8rem"};
text-align: ${props => props.textalign || "center"};
font-weight: ${props => props.fontWeight || "bold"};

outline:none;
border:none;
border-bottom:1px solid grey;
border-radius:5px;
:focus{
  border:2px solid var(--plsGreen);
}
`;
export const Flexed = styled.div`
width:${props => props.width || "100%"};
gap:${props => props.gap || ""};
display:flex;
justify-content:${props => props.justifycontent || "flex-end"};
align-items:${props => props.alignItems || "flex-start"};
position: ${props => props.position || "relative"};
padding: ${props => props.padding || "3px 0px"};
bottom: ${props => props.bottom || ""};
font-size:${props => props.fontSize || ""};
margin:${props => props.margin || ""};
border-bottom: ${props => props.borderBottom || ""};
border-top: ${props => props.borderTop || ""};
border: ${props => props.border || ""};
background-color: ${props => props.bgColor || ""};
`;

export const Flex = styled.div`
width:${props => props.width || "100%"};
position: ${props => props.position || "relative"};
flex:${props => props.flex || "1"};
white-space: ${props => props.whiteSpace || "nowrap"};
flex: ${props => props.flex || "1"};
border-bottom: ${props => props.border || ""};
text-align:${props => props.textAlign || "left"};
padding: ${props => props.padding || "0"};
font-size:${props => props.fontSize || ""};
border-bottom: ${props => props.borderBottom || ""};
align-items:${props => props.alignItems || "flex-start"};
flex-wrap:wrap;

h2{
    margin:0;
    padding:0;
}
`;



export const Textarea = styled(TextareaAutosize)`
font-size:1.4rem;
width:100%;
border:none;
padding:10px 10px 40px 10px;
resize:none;
:focus{
    border:2px solid var(--plsGreen);
  }

`;
export const Headerspan = styled.div`
width:100%;
text-align:center;
font-size:1.1rem;
`;


export default function BinderySchedulePrint({jobs}) {
  let componentRef = useRef();
const [message, setMessage] = useState("")
const [total, setTotal] = useState(0)
const date = new Date()
const { employees } = useContext(UserContext)





  return (
    <>
      <Flex id="print_component" style={{textAlign:"right",paddingTop:"15px"}}>
        {/* button to trigger printing of target component */}
        <ReactToPrint
      
          trigger={() => <AiFillPrinter style={{fontSize:"calc(1.4rem + .5vw)"}} />}
          content={() => componentRef}
        />

        {/* component to be printed */}
        <Flex style={{ display: "none" }}>
          <ComponentToPrint jobs={jobs}  ref={(el) => (componentRef = el)} />
        </Flex>
      </Flex>
    </>
  );
}




// component to be printed
class ComponentToPrint extends React.Component {

  constructor(props) {
    super(props);

  }

  render() {
    return (
      
     <Wrapper style={{background:"white"}}>
<Flexed  margin="5px 0px 0px 0px"  color="black"  padding="5px"><Flex fontSize="calc(1.1rem + .4vw)">Bindery Active Jobs - {this.props.jobs?.length}</Flex>
 <Flex textAlign="right"></Flex>
        <Flex textAlign="right"></Flex>
        </Flexed>
       
<Table >
<THead gap="8px">
<Tr >
<Th flex=".6">&nbsp;</Th>
<Th flex=".8" >Job #</Th>
<Th flex="4" >Customer</Th>
<Th flex="4" >Job Name</Th>
<Th flex="1.5" >Press</Th>
<Th flex="1"  >Cut</Th>
<Th flex="1"  >Fold</Th>
<Th flex="1"  >Stitch</Th>
<Th flex="1"   >Quantity</Th>
<Th >Sales Rep</Th>
<Th flex="1.5" >Enter Date</Th>
<Th flex="1.5" >Due Date</Th>
<Th flex="2">Status</Th>

</Tr></THead>
 <TBody hbgcolor="#0E425A" gap="8px">
 {this.props.jobs?.map((job,index) => {
 return <Tr className="hand" hcolor="white">
<Td flex=".6"></Td>
<Td flex=".8">{job.job}</Td>
<Td flex="4">{job.customer}</Td>
<Td flex="4" padding="0px 5px 0px 0px">{job.jobname}</Td>
<Td flex="1.5">{job.pressname}</Td>
<Td flex="1">{job.cuts > 0 && <b>{job.cuts}</b>}</Td>
<Td flex="1">{job.folds > 0 && <b>{job.folds}</b>}</Td>
<Td flex="1">{job.stitchup > 0 && <b>Yes</b>}</Td>
<Td flex="1"  >{job.quantity}</Td>
<Td flex="1"></Td>
<Td flex="1.5"><Moment format="MM/DD/YYYY">{job.dateentered}</Moment></Td>
<Td flex="1.5"><Moment format="MM/DD/YYYY">{new Date(job.duedate)}</Moment></Td>
<Td flex="2" bgColor={colorchange(job.stat)} padding="3px">{job.stat}</Td>
</Tr>
 })
}
<Tr>
<Td flex=".6"></Td>
<Td flex=".8"></Td>
<Td flex="3"></Td>
<Td flex="1.5"></Td>
<Td ></Td>
<Td > </Td>
<Td ></Td>
<Td flex="1.5"></Td>
<Td flex="3"></Td>
<Td ></Td>
<Td flex="1.5"></Td>
<Td flex="1.5"></Td>
<Td flex="1.5"></Td>
</Tr>
 </TBody>
 </Table>





    </Wrapper>

  
    );
  }
}





