import { useEffect, useState } from 'react'
import { Flex, Flexed, Input, MainWrap, PhoneNumber, Wrap } from '../../../utilities/Shared'
import { LuEdit } from 'react-icons/lu'
import { MdDelete } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { sortit, sortnum} from '../../../utilities/Sort'


const VendorPanel = ({vendorlist,search,setVendorinfo,setEditVendor,setDeletevendor}) => {


    const nav = useNavigate()
    const [order, setOrder] = useState("ASC")
    

    const [newlist, setNewlist] = useState() 
   
    useEffect(() => {
      setNewlist(vendorlist)
    },[])
  return (
    <>
    <Flexed borderBottom="1px solid black">
    <Flex flex=".8" onClick={e => sortnum('company_id',order,newlist,setNewlist,setOrder)}>Vendor ID</Flex>
      <Flex flex="3" >Vendor</Flex>
      <Flex flex="2">Address</Flex>
      <Flex>Phone</Flex>
      <Flex flex=".5"></Flex>
      <Flex flex=".5"></Flex>
  
      </Flexed >
    
      {vendorlist?.filter(item => {return search.toLowerCase() === '' ? item : item.company?.name?.toLowerCase().includes(search.toLowerCase()) || item.company?.company_id?.toString().includes(search)}).map((customer,index) =>{
            return <>
      
            <Flexed className="mainrow hand" key={index} margin="0" padding="5px 0px"  borderBottom="1px solid lightgrey" >
            <Flex className="" flex=".8" onClick={e => nav(`vendor/${customer._id}`)}>{customer.company?.company_id}</Flex>
            <Flex className="" flex="3" onClick={e => nav(`vendor/${customer._id}`)}>{customer.company?.name} </Flex>
             <Flex className="" flex="2" onClick={e => nav(`vendor/${customer._id}`)}>
                <div>{customer.address?.address1}</div>
                {customer.address?.address2 > "" && <div>{customer.address?.address2}</div>}
                {customer.address?.city > "" && <div>{customer.address?.city},{customer.address?.state} {customer.address?.zip} </div>}
              </Flex>
             <Flex className="" onClick={e => nav(`vendor/${customer._id}`)}>{PhoneNumber(customer.numbers?.main)}</Flex>
             <Flex className="" flex=".5"><LuEdit className="edit" onClick={e => {setVendorinfo(customer);setEditVendor(true)}} /></Flex>
             <Flex className="" flex=".5"> <MdDelete style={{fontSize:"calc(.7rem + .4vw)",color:"rgb(255,0,0,.7)",zIndex:"10"}} onClick={e => {setVendorinfo(customer);setDeletevendor(true)}} /></Flex>
            </Flexed>
           
      
            </>
         })}
         </>
  )
}

export default VendorPanel