import React, { useContext, useEffect, useState } from 'react'
import { Flex, Flexed } from '../../utilities/Shared'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'
import { sortit, sortnum } from '../../utilities/Sort'
import UserContext from '../../Context/UserContext'

const JobSearch = ({term,csrreps,salesreps}) => {

const [newjobs, setNewjobs] = useState([])
const [oldjobs, setOldjobs] = useState([])
const [newerjobs, setNewerjobs] = useState([])
const [order, setOrder] = useState("ASC")
const {userInfo,logout } = useContext(UserContext)
const nav = useNavigate()
useEffect(() => {
    const getjobsbyname = async() => {
        const config = {
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin':'*' 
            }};    
          const tr = {
            searchitem:term
      }
     await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getquotes.php`,tr,config)
     .then(res => {console.log(res.data);setNewerjobs(res.data)})
     .catch(err => toast.error(err))   
    }
    getjobsbyname()
},[])




   
console.log(newerjobs)

  return (
    <>
  {newerjobs.length >0 &&  <div style={{border:"1px solid #303030",margin:"30px 0px"}}>
    <Flexed ><Flex  bgColor="#0E425A" color="white" fontSize="calc(1.5rem + .4vw)">QUOTE RESULTS &nbsp; &nbsp;{newerjobs.length}</Flex></Flexed>
    <Flexed bgColor="#8398AB" padding="3px 0px" fontWeight="bold" color="white" className="hand">
        <Flex  flex=".7" onClick={() => sortnum('quote',order,newerjobs,setNewerjobs,setOrder)}>Quote#</Flex>
        <Flex fontSize="var(--headerMedium)" flex="2.5" onClick={() => sortit('customer',order,newerjobs,setNewerjobs,setOrder)}>Customer</Flex>
        <Flex fontSize="var(--headerMedium)" onClick={() => sortnum('quantity',order,newerjobs,setNewerjobs,setOrder)}>Quantity</Flex>
        <Flex fontSize="var(--headerMedium)" flex="2.5" onClick={() => sortit('name',order,newerjobs,setNewerjobs,setOrder)}>Job Name</Flex>
        <Flex fontSize="var(--headerMedium)" onClick={() => sortit('salesrep',order,newerjobs,setNewerjobs,setOrder)}>Salesrep</Flex>
        <Flex fontSize="var(--headerMedium)" onClick={() => sortit('csr',order,newerjobs,setNewerjobs,setOrder)}>CSR</Flex>
    </Flexed>
   
    {newerjobs?.map(job => {
     return <Flexed className="normalrow" /*onClick={() => nav(`/customers/customer/${customer._id}`)}*/>
     <Flex  flex=".7" style={{fontSize:"var(--headerMedium)"}}>{userInfo.auth?.includes('600') || userInfo.auth?.includes('106') ? <a style={{fontSize:"var(--headerMedium)"}}  href={` https://highgroundsoftware.com/dash/dashboard/?des=15002&q=${job.quote}`} >{job.quote}</a> : <>{job.quote}</>}</Flex>
     <Flex  flex="2.5"  >{job.customer}</Flex>
     <Flex > {job.quantity}</Flex>
     <Flex fontSize="var(--headerMedium)" flex="2.5"> {job.pname}</Flex>
     <Flex > {salesreps?.filter(rep => rep.empId === job.salesrep).map(rep =>{return rep.firstName+" "+rep.lastName.substr(0,1)})}</Flex>
     <Flex > {csrreps?.filter(rep => rep.empId === job.csr).map(rep =>{return rep.firstName+" "+rep.lastName.substr(0,1)})}</Flex>
    
    </Flexed>
    })
       
    }
    </div>}
    </>
  )
}

export default JobSearch