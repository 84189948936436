
import { useContext, useEffect, useState } from 'react';
import './App.css';
import Dashboard from './pages/Dashboard';

import UserContext from './Context/UserContext'
import Login from './pages/Login';
import CacheBuster from 'react-cache-buster';

import { ChatProvider } from './Context/ChatContext';
import { JobticketProvider } from './Context/JobticketContext';

function App() {

  const [info, setInfo] = useState({})

  const {userInfo} = useContext(UserContext);
  const isProduction = process.env.NODE_ENV === 'production';

const version = "1-23"
  


  return (
    <CacheBuster
    currentVersion={version}
    isEnabled={isProduction} //If false, the library is disabled.
    isVerboseMode={false} //If true, the library writes verbose logs to console.
   // loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
    metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
  >

    <div className="App">
  
  {
 sessionStorage.getItem('trackuser') ? <ChatProvider><Dashboard /></ChatProvider> : <Login />
  }

    </div>
    </CacheBuster>
  );
}

export default App;


