import React, { useContext, useEffect, useState } from 'react'
import { Button, Datepick, Flex, Flexed, Input, Label, MainWrap, Selectbox, Textarea, Textauto,TinySquare } from '../../utilities/Shared'
import axios from 'axios'
import {colorchange, employeeConvert} from '../../utilities/Convertions'
import { useParams,useNavigate, Navigate, useLocation } from 'react-router-dom'
import Moment from 'react-moment'
import { CSRRepsByEmpid, SalesRepsByEmpid } from '../../utilities/Reps'
import { Prepress } from '../../utilities/Statuslist/Prepress'
import PrepressTicket from './jobticket/PrepressTicket'
import Modal from '../../modals/Modal'
import moment from"moment";
import Edittime from './jobticket/Edittime'
import { changeidtopress } from '../../utilities/Convertions'
import Selectpresses from '../../utilities/Selectpresses'
import { methods, presses, pullcutlist } from '../../utilities/Lists'
import { v4 as uuidv4 } from 'uuid';
import EstVact from './jobticket/EstVact'
import { toast } from 'react-toastify'
import PressTicket from './jobticket/PressTicket'

import { sortnum, sortnumbers } from '../../utilities/Sort'
import Jobticket from '../../Printing/Jobticket'
import Select from '../../utilities/Select'

const JobTicket1 = () => {

    const[customerinfo, setCustomerinfo] = useState([])
    const[operations, setOperations] = useState([])
    const[dateentered, setDateentered] = useState()
    const[duedate, setDuedate] = useState()
    const[edittime, setEdittime] = useState(false)
    const[editwhat, setEditwhat] = useState("")
  
    const[currentitem, setCurrentitem] = useState(null)
    const param = useParams()
    const[editprepressticket, setEditprepressticket] = useState(false)
    const[allocated, setAllocated] = useState([])
    const[poordered, setPoordered] = useState([])
    const[s2checked, SetS2checked] = useState(false)
    const [poinfo, setPoinfo] = useState([])
    const [creadted, setCreadted] = useState([])
    const [prepressticket, setPrepressticket] = useState(false)
    const [pressticket, setPressticket] = useState(false)
    const [setjobnumber, setSetjobnumber] = useState()
    const [status, setStatus] = useState()


    const[forms, setForms] = useState([])
    const[jobinfo, setJobinfo] = useState([])
    const[reset, setReset] = useState(false)
    const[jobnum, setJobnum] = useState(0)
    const[imposition, setImposition] = useState([])
    const [employeelist, setEmployeelist] = useState()
    const[jobs, setJobs] = useState()
    const[newjobinfo, setNewjobinfo] = useState()
    

const location = useLocation()
    const nav = useNavigate()
    const config = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin':'*' 
        }
      };

      let r = {
        job:param.job
      }

      useEffect(() => {
        setTimeout(function(){
         
          setReset(!reset)
      },4000);
      },[])



useEffect(() => {
  let csrname
 
    const getb = async() => {
        await axios.post('https://highgroundsoftware.com/dash/dashboard/php/getjobcustomerbyjob.php',r,config)
      .then(res => {
        setCustomerinfo(res.data[0]);setDateentered(new Date(res.data[0].dateentered));
        let u = new Date(res.data[0].duedate)
        u.setDate(u.getDate()+1); 
        setDuedate(u)
       
      })
      .catch(error => { console.log({...error});})
      }
     
        const getops = async() => {
          await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getalloperations.php`,r,config)
         .then(res => {console.log(res.data);setOperations(res.data);})
         .catch(err => console.log(err))
         }
      
     
      const getallocated = async() => {
     
        await axios.post('https://highgroundapi.herokuapp.com/inv/get/allocated',r)
      .then(res => {setAllocated(res.data)})
      .catch(error => { console.log({...error});})
      }
      const getpos = async() => {
       
        await axios.post('https://highgroundapi.herokuapp.com/po/get/job/po',r)
      .then(res => {setPoordered(res.data)})
      .catch(error => { console.log({...error});})
      }
      
       const getcurrent = async() => {
        let r = {'jobnum':param.job}
        await axios.post(`https://highgroundapi.herokuapp.com/jobs/find`,r)
       .then(res => {setJobs(res.data);})
       .catch(err => console.log(err))
       }
       const getimp = async() => {
        await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getimposition.php`,r,config)
        .then(res => {setImposition(res.data);})
        .catch(err => console.log(err))
       }
    
       
      
if(param.job > 0){
  getops()
  getpos()
      getallocated()
      getb()
      getimp()
   
      getcurrent()
      setSetjobnumber(param.job)
      
    }
    setJobnum(param.job)
    
},[reset])


useEffect(() => {
  const geta = async() => {
    await axios.post('https://highgroundsoftware.com/dash/dashboard/php/getjobbyjobnumber.php',r,config)
  .then(res => { 
    setforms(res.data)  
 })
.catch(error => { console.log({...error});})
}
geta()
},[operations])
 




console.log(forms)

const setforms = (forms) => {
 
  const madeforms = []
  imposition?.map((item,index) => {
  if(parseInt(item.numforms) > 1 && !isDigital(item.press)){
    let i
    let n = 1
   
    
    for(i=1;i<=item?.numforms;i++){
    
        const r = {
          item:item.id,
          totalpages:item.pages,
          pages:item.pages / item.numforms,
          numup:item.numup,
          versions:item.versions,
          pressid:item.press,
          pressname:getpress(item.press),
         width:item.sheetw,
         length:item.sheetl,
         method:item.method,
         numforms:item.numforms,
         name:`${n}) ${item.component}`,
         paper:item.paper,
         fink:item.fink,
         bink:item.bink,
         faq:item.faq,
         baq:item.baq,
         totalinkused:item.totalinkused,
         processtext:changetext(item.press),
  formid:uuidv4(),
  originalform:item.component,
  originalformid:item.id,
  digital:isDigital(item.press),
  stockid:item.paperid,
  allocated:isAllocted(item.paperid),
  partofid:`${item.id}${n}`,
  prepressStatus:getstatus(`${item.id}${n}`,'prepress','multi') || "Incoming",
  pressStatus:getstatus(`${item.id}${n}`,'press','multi',isDigital(item.press)),
  sett:'multi',
  topress:multitopress(item.mr,item.additionalmr,item.needed,item.mroverride,item.numup,item.versions,item.numforms)
        }                                       
        madeforms.push(r)
        n++ 
       
            }   
            
  }else{
  
    const t = {
      item:item.id,
      totalpages:item.pages,
      pages:item.pages / item.numforms,
      numup:item.numup,
      versions:item.versions,
      pressid:item.press,
      pressname:getpress(item.press),
     width:item.sheetw,
     length:item.sheetl,
     method:item.method,
     numforms:item.numforms,
     name:`${item.component}`,
     paper:item.paper,
     fink:item.fink,
     bink:item.bink,
     faq:item.faq,
     baq:item.baq,
     totalinkused:item.totalinkused || "",
     processtext:changetext(item.press),
     formid:uuidv4(),
  originalform:item.component,
  originalformid:item.id,
  digital:isDigital(item.press),
  stockid:item.paperid,
  allocated:isAllocted(item.paperid),
  partofid:0, 
  prepressStatus:getstatus(`${item.id}`,'prepress','single')  || "Incoming",
  pressStatus:getstatus(`${item.id}`,'press','single',isDigital(item.press)),
  sett:'single',
  topress:singletopress(item.mr,item.additionalmr,item.needed,item.mroverride,item.numup,item.versions,isDigital(item.press))
    }
    madeforms.push(t)
  }
setForms(madeforms)
  })

  
}


const changetext = (item) => {

  let text
switch(item){
  case "9":
    text= "Colormax"
    break;
  case "8":
  case "7":
  case "10":
    text= "Digital"
     break;
  case "1":
  case "2":
  case "3":
  case "4":
  case "5":
  case "6":
    text= "Press"
    break;
}
return text
}


const isDigital = (pressid) => {
  if(pressid == 9 || pressid == 7 || pressid == 8 || pressid == 10){
  return true
  }else{
    return false
  }
  }
  
  
  const isAllocted = (stockid) => {
   let yy = allocated.filter(item => item.forjobs.filter(filt => filt.paperid === stockid))
    
  if(yy.length > 0){
  return true
  }else{
  return false
  }
    }

    const getpress = (id) => {

      let y = presses?.filter(filt => filt.id == id)
      let r = y[0]?.name
      return r
    }

    const getstatus = (partofid,cat,itemtype,dig) => {
      let dept = 3
if(dig){
dept = 2
}
      let y
     
      if(itemtype === "multi"){
        switch(cat){
          case "prepress":
            y =  operations?.filter(filt => parseInt(filt.partofid) === parseInt(partofid) && filt.dept < 3)
            break;
            case "press":
              y =  operations?.filter(filt =>  parseInt(filt.partofid) ===  parseInt(partofid) &&  parseInt(filt.dept) === dept)
              break;
            }
           return(y[0]?.name)
      }
       
      if(itemtype === "single"){
        switch(cat){
         
          case "prepress":

            y =  operations?.filter(filt => parseInt(filt.partof) === parseInt(partofid) && parseInt(filt.dept) < 2)
            break;
            case "press":
              y =  operations?.filter(filt =>  parseInt(filt.partof) ===  parseInt(partofid) &&  parseInt(filt.dept) === dept)
              break;
            }
           return(y[0]?.name)
      
      }
      }

      const multitopress = (mr,additionalmr,needed,mroverride,numup,versions,forms) =>{
      
        let m = mroverride > 0 ? parseInt(mroverride) / parseInt(forms): parseInt(mr) / parseInt(forms)
        let to =  ((parseInt(needed) + parseInt(mr) + parseInt(additionalmr)) * parseInt(versions))  / parseInt(forms)
        let off = (parseInt(to) -parseInt(m)) 
        let totm = mroverride > 0 ? parseInt(mroverride) : parseInt(mr)
        let totto =  (parseInt(needed) + parseInt(mr) + parseInt(additionalmr)) * parseInt(numup) * parseInt(versions) 
        let totoff = parseInt(totto) -parseInt(totm)
         /* $poundset = $c['sheetl'] * $c['sheetw'] * $paper['weight'] * 2;
          $poundset = ceil($poundset / $paper['divide']);
          $mradded = $topress/$c['parentout'];
           $sneed = number_format(ceil(($poundset * $mradded) / 1000));
           */
        let y = {
         totm,
         totto,
         totoff,
          mr:m,
          to,
          off
        }
        return y
        }


      const singletopress = (mr,additionalmr,needed,mroverride,numup,versions,digital) =>{


        let m = mroverride > 0 ? parseInt(mroverride) : parseInt(mr)
        let to =  ((parseInt(needed) + parseInt(m) + parseInt(additionalmr)) * parseInt(versions))
        let off = parseInt(to) -parseInt(m)

        if(digital){

        }
         /* $poundset = $c['sheetl'] * $c['sheetw'] * $paper['weight'] * 2;
          $poundset = ceil($poundset / $paper['divide']);
          $mradded = $topress/$c['parentout'];
           $sneed = number_format(ceil(($poundset * $mradded) / 1000));
           */
        let y = {
   
          mr:m,
          to,
          off
        }
        return y
        }



const changeDate = (date) => {
  let r = {
    job:param.job,
     value:date.toLocaleString()
   }
   const getops = async() => {
     await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/updateDueDate.php`,r,config)
    .then(res => {  setDuedate(date)})
    .catch(err => console.log(err))
    }
   getops()
}















  const getpo = async() => {
    await axios.post(`https://highgroundapi.herokuapp.com/po/get/job/po`,{"job":param.job})
    .then(res => {setPoinfo(res.data)})
    .catch(err => console.log(err))
  }


  const newinfo = (item) => {
    console.log(item)
      console.log(customerinfo[item])
      let r = {
       job:param.job,
        item:item,
        value:customerinfo[item]
      }
      console.log(r)
      const getops = async() => {
        await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/updateJobDescriptions.php`,r,config)
       .then(res => {})
       .catch(err => console.log(err))
       }
       getops()
    }

    const setcheckbox = (e,item) => {
      switch(item){
      case "s2":
        if(s2checked){setCustomerinfo({...customerinfo,[item]:"0"})}else{setCustomerinfo({...customerinfo,[item]:"1"})}
      SetS2checked(!s2checked)
      newinfo('s2')
      
      break;
      }
      }

const sorted = (list) => {
  list?.sort((id1,id2) => {
    if(id1 > id2) return 1;
    if(id2 > id1) return 0;
  })
}


console.log(imposition)
console.log(forms)





  

  return (
    <MainWrap >
      <Modal show={editprepressticket} header={`Pre Press Info`} onCancel={() => {setEditprepressticket(false)}} ><PrepressTicket onCancel={() => {setEditprepressticket(false)}}  reset={reset} setReset={setReset} /></Modal>

      <Modal show={edittime} header={`Time Punches & Estimated Times For Job ${param.job}`} onCancel={() => {setEdittime(false)}} ><EstVact onCancel={() => {setEdittime(false)}}  reset={reset} setReset={setReset} jobinfo={jobinfo} forms={forms}/></Modal>
     
      <Modal show={prepressticket} header={<Flexed><Flex fontSize="calc(1.4rem + .2vw)" flex="3">Pre-Press Times For Job {param.job}</Flex><Flex>On <TinySquare bgcolor="var(--on)" /></Flex><Flex>Off <TinySquare bgcolor="var(--off)" /></Flex><Flex>Incomplete <TinySquare bgcolor="var(--incomplete)" /></Flex><Flex>AA Changes <TinySquare bgcolor="var(--aa)" /></Flex><Flex>PE Changes <TinySquare bgcolor="var(--pe)" /></Flex></Flexed>} onCancel={() => {setPrepressticket(false)}} ><PrepressTicket onCancel={() => {setPrepressticket(false)}}  reset={reset} setReset={setReset} job={param.job} jobinfo={jobinfo} forms={forms} /></Modal>

      <Modal show={pressticket} header={<Flexed><Flex fontSize="calc(1.4rem + .2vw)" flex="3">Press Times For Job {param.job}</Flex><Flex>On <TinySquare bgcolor="var(--on)" /></Flex><Flex>Off <TinySquare bgcolor="var(--off)" /></Flex><Flex>Incomplete <TinySquare bgcolor="var(--incomplete)" /></Flex><Flex>AA Changes <TinySquare bgcolor="var(--aa)" /></Flex><Flex>PE Changes <TinySquare bgcolor="var(--pe)" /></Flex></Flexed>} onCancel={() => {setPressticket(false)}} ><PressTicket onCancel={() => {setPressticket(false)}}  reset={reset} setReset={setReset} jobinfo={jobinfo} forms={forms} ops={operations} job={param.job} /></Modal>
        <div style={{background:"#EAE6E1",padding:"0px 5px 5px 5px"}}>
        <Flexed padding="7px 0px"  bgColor="#0E425A" color="white" margin="20px 0px 0px 0px">
            <Flex textAlign="center" fontSize="var(--headerLarge)">Job Ticket</Flex>
        </Flexed>
       
       
<Flexed>
    <Flex flex="2"><Input type="text" height="50px" fontWeight="bold" fontSize="18pt" value={customerinfo?.customer} disabled /><br /><Label>Customer</Label></Flex>
    <Flex flex=".5"><Input fontWeight="bold" type="text" value={customerinfo?.customerid} disabled/><br /><Label>Cust###</Label></Flex>
    <Flex flex=".5"><Datepick fontWeight="bold" selected={dateentered}   /><br /><Label>Entered</Label></Flex>
    <Flex flex=".5"><Datepick fontWeight="bold" selected={duedate}  onChange={(date) => changeDate(date)} /><br /><Label>Due</Label></Flex>
    <Flex flex="0" ><form onSubmit={e => nav(`/jobticket/${setjobnumber}`)}><Input onChange={e => setSetjobnumber(e.target.value)} fontWeight="bold" type="text" textAlign="center" width="150px" height="65px" value={setjobnumber} fontSize="32pt"/></form><br /><Label>Job</Label></Flex>
</Flexed>

<Flexed  margin="20px 0px 0px 0px">
    <Flex  flex="1.5"><Input fontWeight="bold" type="text" value={customerinfo?.jobname} onChange={e => setCustomerinfo({...customerinfo,jobname:e.target.value})} onBlur={e => newinfo('jobname')}/><br /><Label>Job Name</Label></Flex>
    <Flex><Input fontWeight="bold" type="text" value={customerinfo?.jobtype} /><br /><Label>Job Type</Label></Flex>
    <Flex flex=".5"></Flex>
    <Flex  flex=".5"><Input fontWeight="bold" type="text" value={customerinfo?.quote} /><br /><Label>Quote#</Label></Flex>
    <Flex  flex=".5"><Input fontWeight="bold" type="text" value={customerinfo?.prevjob} onChange={e => setCustomerinfo({...customerinfo,prevjob:e.target.value})} onBlur={e => newinfo('prevjob')} /><br /><Label>Prev Job#</Label></Flex>
    <Flex  flex=".5"><Input fontWeight="bold" type="text" value={customerinfo?.custpo} onChange={e => setCustomerinfo({...customerinfo,custpo:e.target.value})} onBlur={e => newinfo('custpo')}/><br /><Label>PO#</Label></Flex>
</Flexed>

<Flexed  margin="20px 0px 20px 0px">
    <Flex><SalesRepsByEmpid value={customerinfo?.salesrep} style={{fontWeight:"bold"}}/><br /><Label>Salers Rep</Label></Flex>
    <Flex><CSRRepsByEmpid  value={customerinfo?.csr} style={{fontWeight:"bold"}}/><Label>CSR</Label></Flex>
    <Flex><Input type="text" /><br /><Label>Contact</Label></Flex>
    <Flex ></Flex>
    <Flex textAlign="right">
      <a href={`https://highgroundsoftware.com/dash/dashboard/?des=779&job=${setjobnumber}`}>
      <Button bgcolor="buttonface" padding="8px 25px" fontSize="calc(1.1rem + .3vw)" border="1px solid Black">White Sheet</Button>
      </a>
      </Flex>
 
</Flexed>
<hr />
<Flexed>
    <Flex padding="20px 0px" fontSize="1.9rem" fontWeight="bold">Quantity Ordered:&nbsp;{customerinfo?.origquantity}</Flex>
    <Flex><Button onClick={e => {setEdittime(true)}}>EVA</Button></Flex>
    <Flex textAlign="right" alignItems="flex-end" display="flex" ><Jobticket  imposition={imposition} customerinfo={customerinfo} jobinfo={jobinfo} presses={presses} methods={methods} forms={forms} title="Print Ticket" /></Flex>
</Flexed>
<Flexed gap="30px">
    <Flex fontSize="1.3rem">Description 1<br /><Textauto value={customerinfo?.pnotes} minRows="8" onBlur={e => newinfo('pnotes')} onChange={e => setCustomerinfo({...customerinfo,pnotes:e.target.value})}/></Flex>
    <Flex fontSize="1.3rem">Description 2<br /><Textauto value={customerinfo?.desc2} minRows="8" onBlur={e => newinfo('desc2')} onChange={e => setCustomerinfo({...customerinfo,desc2:e.target.value})} /></Flex>
    <Flex fontSize="1.3rem">Description 3<br /><Textauto value={customerinfo?.desc3} minRows="8" onBlur={e => newinfo('desc3')} onChange={e => setCustomerinfo({...customerinfo,desc3:e.target.value})} /></Flex>
</Flexed>
<Flexed padding="7px 0px"  bgColor="#0E425A" color="white" margin="20px 0px 0px 0px">
    <Flex flex="2" fontSize="1.3rem" fontWeight="bold">Pre-Press</Flex>
    <Flex style={{display:"flex",alignItems:"center",fontSize:"1.1rem",fontWeight:"bold"}}>S2&nbsp;&nbsp;<Input width="20px" type="checkbox" onClick={e => setcheckbox(e,'s2')}  checked={s2checked}/></Flex>
    <Flex style={{display:"flex",alignItems:"center",fontSize:"1.1rem",fontWeight:"bold"}}>S44&nbsp;&nbsp;<Input  width="20px"  type="checkbox" /></Flex>
    <Flex style={{display:"flex",alignItems:"center",fontSize:"1.1rem",fontWeight:"bold"}}>1st Off&nbsp;&nbsp;<Input  width="20px"  type="checkbox" /></Flex>
    <Flex style={{display:"flex",alignItems:"center",fontSize:"1.1rem",fontWeight:"bold"}}>PDF&nbsp;&nbsp;<Input  width="20px"  type="checkbox" /></Flex>
    <Flex style={{display:"flex",alignItems:"center",fontSize:"1.1rem",fontWeight:"bold"}}>Press Check&nbsp;&nbsp;<Input  width="20px"  type="checkbox" /></Flex>
    <Flex flex=".3"></Flex>
    <Flex display="flex" justifycontent="flex-end"><Button color="black" hcolor="white" onClick={e => setPrepressticket(true)} >Punches</Button></Flex>
</Flexed>
<Flexed style={{marginTop:"0px"}}>
<Textauto value={customerinfo?.prepressnotes} placeholder="Pre-Press Notes" minRows="1" onBlur={e => newinfo('prepressnotes')} onChange={e => setCustomerinfo({...customerinfo,prepressnotes:e.target.value})}/>
</Flexed>
<Flexed padding="3px">
<Flex fontSize="1.3rem" flex="2">Component</Flex>
<Flex flex=".4" fontSize="1.3rem">Pages </Flex>
<Flex flex=".4" fontSize="1.3rem">#Up</Flex>
<Flex flex=".4" fontSize="1.3rem">Forms</Flex>
<Flex flex=".4" fontSize="1.3rem">Ver's</Flex>
<Flex fontSize="1.3rem">Press</Flex>
<Flex flex=".8" fontSize="1.3rem">Sheet Size</Flex>
<Flex fontSize="1.3rem">Method</Flex>
<Flex fontSize="1.3rem">Status</Flex>
</Flexed>
{imposition.map(imp => {
return forms.filter(filt => parseInt(filt.item) === parseInt(imp.id)).map((newitem,index) => {
  console.log(newitem.name)
return <>
      <Flexed key={index} >
      <Flex flex="2"><Input type="text" fontSize="1rem" value={newitem.name} name="component" /></Flex>
          <Flex flex=".4"><Input type="text" fontSize="1rem" value={newitem.pages} /> </Flex>
          <Flex flex=".4"><Input type="text" fontSize="1rem" value={newitem.numup} /></Flex>
          <Flex flex=".4"><Input type="text" fontSize="1rem" value={newitem.numforms} /></Flex>
          <Flex flex=".4"><Input type="text" fontSize="1rem" value={newitem.versions} /></Flex>
          <Flex >
          <Selectbox >
        {
          presses.map(item => {
      
            return <option value={item.id} selected={item.id == newitem.pressid ? true : false}>{item.name}</option>
          })
        }
        </Selectbox>
            </Flex>
          <Flex flex=".4"><Input type="text" fontSize="1rem" value={newitem.length} /></Flex>
          <Flex flex=".4"><Input type="text" fontSize="1rem" value={newitem.width} /></Flex>
          <Flex >
          <Selectbox >
        {
          methods.map(item => {
      
            return <option value={item.id} selected={item.name == newitem.method ? true : false}>{item.name}</option>
          })
        }
        </Selectbox> 
            </Flex>
          <Flex fontSize="1.2rem" bgColor={colorchange(newitem.prepressStatus)}padding="5px" height="27px" top="3px">
      {newitem.prepressStatus}
          </Flex>
          </Flexed>


      </>
  

})}

)}




{/*/////////////////////////////////////////////////////////PRESS///////////////////////////////////////////////////////////////////////////////////////////*/}


<Flexed padding="7px 0px"  bgColor="#0E425A"  margin="20px 0px 0px 0px">
    <Flex color="white" flex="3" fontSize="1.3rem" fontWeight="bold">Press</Flex>  <Flex display="flex" justifycontent="flex-end"><Button hcolor="white" onClick={e => setPressticket(true)} >Punches</Button></Flex>
</Flexed>
<Flexed style={{marginTop:"-5px"}}>
<Textauto value={customerinfo?.pressnotes} placeholder="Press Notes" minRows="1" rows="1"  onBlur={e => newinfo('pressnotes')} onChange={e => setCustomerinfo({...customerinfo,pressnotes:e.target.value})}/>
</Flexed>

{imposition.map((item,index) => {
return <>
<Flexed bgColor="rgb(14,66,90,.4)" padding="3px" alignItems="center" >
  <Flex fontSize="calc(1rem + .2vw)" fontWeight="bold" flex="7.5">{item.component} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{item.numforms} Forms&nbsp; - &nbsp;{item.versions} version &nbsp;&nbsp; {item.numforms} - {item.pages / item.numforms} page(s) running {item.numup}up on a {item.length} X {item.width} {item.method}</Flex>
  {parseInt(item.numforms) > 1 &&   <><Flex  fontSize="1.1rem">Total To Press: <Input type="text" fontSize="1rem" value={(parseInt(item.needed) + parseInt(item.additionalmr) + parseInt(item.mroverride)) * parseInt(item.versions)} /></Flex>
       <Flex  fontSize="1.1rem">Total M/R: <Input type="text" fontSize="1rem" value={parseInt(item.mroverride)} /></Flex>
       <Flex  fontSize="1.1rem">Total Off Press: <Input type="text" fontSize="1rem" value={(parseInt(item.needed) + parseInt(item.additionalmr) + parseInt(item.mroverride) - parseInt(item.mroverride)) * parseInt(item.versions)} /></Flex></>}
   </Flexed>
  {forms.filter(filt => filt.item == item.id).map((newitem,index) => {
return <>
   <Flexed bgColor="rgb(14,66,90,.2)" padding="3px" >
  <Flex top="21px" fontSize="calc(1rem + .2vw)" fontWeight="bold" flex="1" >{newitem.name}</Flex>
  <Flex  fontSize="1.1rem" >Pages:<br /> <Input type="text" fontSize="1rem" value={parseInt(newitem.pages)} /></Flex>
  <Flex  fontSize="1.1rem" >Up:<br /> <Input type="text" fontSize="1rem" value={newitem.numup} /></Flex>
    <Flex  fontSize="1.1rem" >Forms:<br /> <Input type="text" fontSize="1rem" value={parseInt(newitem.numforms)}/></Flex>
    <Flex  fontSize="1.1rem" >Ver's:<br /> <Input type="text" fontSize="1rem" value={newitem.versions} /></Flex>
    <Flex >
   Press:<br />
    <Selectbox >
  {
    presses.map(press => {
      return <option value={press.id} selected={parseInt(press.id) === parseInt(newitem.pressid) ? true : false}>{press.name}</option>
    })
  }
  </Selectbox>
      </Flex>
      <Flex >Method:<br />
     <Selectbox >
   {
     methods.map(method => {

       return <option value={method.id} selected={method.name == newitem.method ? true : false}>{method.name}</option>
     })
   }
   </Selectbox> 
       </Flex>
       <Flex bgColor={colorchange(newitem.pressStatus)} height="30px" fontSize="1.3rem" top="12px" display="flex" alignItems="center" >{newitem.pressStatus}</Flex>

  </Flexed>
  <Flexed bgColor="rgb(14,66,90,.2)" padding="8px" borderBottom="1px solid grey">
 <Flex  flex="1.5" fontSize="1.1rem" >Paper:<br /> <Input type="text" fontSize="1rem" value={newitem.paper} /></Flex>
   
     <Flex flex="1">
       Size:<br />
       <Flexed>
       <Flex><Input type="text" fontSize="1rem" value={newitem.length} /></Flex>
     <Flex ><Input type="text" fontSize="1rem" value={newitem.width} /></Flex>
       </Flexed>
      </Flex>
      <Flex flex=".5" fontSize="1.1rem">Front Inks: <Input type="text" fontSize="1rem" value={newitem.fink} /></Flex>
     <Flex flex=".5" fontSize="1.1rem">Back Inks: <Input type="text" fontSize="1rem" value={newitem.bink} /></Flex>
     <Flex flex=".5" fontSize="1.1rem">Front Coat: <Input type="text" fontSize="1rem" value={newitem.faq} /></Flex>
     <Flex flex=".5" fontSize="1.1rem">Back Coat: <Input type="text" fontSize="1rem" value={newitem.baq} /></Flex>
     </Flexed>
     <Flexed bgColor="rgb(14,66,90,.2)" padding="3px" borderBottom="1px solid grey" margin="0px 0px 15px 0px">
     <Flex >Stock Status
  <br />
 <Selectbox >
 <option value="">Select Status</option>
 <option value="Allocated" selected={item.allocated}>Allocated</option>
 <option value="Ordered">Ordered</option>
 <option value="">Inventory</option>
 </Selectbox>
   </Flex>
   <Flex >Pull / Cut
  <br />
 <Selectbox >
 <option value="">Select Assigner</option>
 <option value="Robert H">Robert H</option>
 <option value="Pam B">Pam B</option>
 <option value="Duane H">Duane H</option>
 <option value="Kevin C">Kevin C</option>
 <option value="Laura N">Laura N</option>
 </Selectbox>
   </Flex>
   <Flex flex="2"></Flex>
     <Flex  fontSize="1.1rem">To Press: <Input type="text" fontSize="1rem" value={newitem.topress.to} /></Flex>
       <Flex  fontSize="1.1rem">M/R: <Input type="text" fontSize="1rem" value={newitem.topress.mr} /></Flex>
       <Flex  fontSize="1.1rem">Off Press: <Input type="text" fontSize="1rem" value={newitem.topress.off} /></Flex>
 
     </Flexed>


</>

}
  )}

</>

}
)}



{/*/////////////////////////////////////////////////////////Bindery///////////////////////////////////////////////////////////////////////////////////////////*/}




<br /><br /><br /><br /><br /><br /><br /><br /><br />
<br /><br /><br /><br /><br /><br /><br /><br /><br />
<br /><br /><br /><br /><br /><br /><br /><br /><br />

</div>
    </MainWrap>
  
)}

export default JobTicket1

