import React, { useRef,useEffect ,useState} from "react";
import Moment from 'react-moment';

import LOGO from '../Assets/plsprint.png'
import {AiFillPrinter} from 'react-icons/ai';
import styled from 'styled-components'
import ReactToPrint from "react-to-print";
import TextareaAutosize from 'react-textarea-autosize';
import { Img, Label, Textauto, currency } from "../utilities/Shared";



export const Wrapper = styled.div`
padding:20px 60px;
font-size:.8rem;

background-color: white;
`;
export const Input = styled.input`

position: ${props => props.position || "relative"};

width:${props => props.width || "90%"};
padding:${props => props.padding || "5px 3px 0px 3px"};
max-width:${props => props.maxWidth || "100%"};
margin:${props => props.margin || "0px 0px 0px 0px"};
height: ${props => props.height || ""};
font-size:${props => props.fontSize || ".8rem"};
text-align: ${props => props.textalign || "center"};
font-weight: ${props => props.fontWeight || "bold"};

outline:none;
border:none;
border-bottom:1px solid grey;
border-radius:5px;
:focus{
  border:2px solid var(--plsGreen);
}
`;
export const Flexed = styled.div`
width:${props => props.width || "100%"};
display:flex;
justify-content:${props => props.justifycontent || "flex-end"};
align-items:${props => props.alignItems || "flex-start"};
position: ${props => props.position || "relative"};
padding: ${props => props.padding || "3px 0px"};
bottom: ${props => props.bottom || ""};
font-size:${props => props.fontSize || ""};
margin:${props => props.margin || ""};
border-bottom: ${props => props.borderBottom || ""};
border: ${props => props.border || ""};
gap: ${props => props.gap || ""};
margin-top:${props => props.marginTop || ""};
`;

export const Flex = styled.div`
width:${props => props.width || "100%"};
position: ${props => props.position || "relative"};
flex:${props => props.flex || "1"};
white-space: ${props => props.whiteSpace || ""};
flex: ${props => props.flex || "1"};
border-bottom: ${props => props.border ? "1px solid black" : ""};
text-align:${props => props.textAlign || "left"};
padding: ${props => props.padding || "0"};
font-size:${props => props.fontSize || ""};
background-color:${props => props.bgColor || ""};
padding: ${props => props.padding || "0px"};
font-weight: ${props => props.fontWeight || "normal"};
h2{
    margin:0;
    padding:0;
}
`;




export const Textarea = styled(TextareaAutosize)`

min-height:${props =>props.minHeight || "50px"};
width:${props =>props.width || "100%"};
outline:none;
text-align:center;
border:1px solid #c8c8c8;
font-size:calc(.7rem + .5vw);
padding:${props =>props.padding || "8px"};
margin:${props =>props.margin || "0"};
resize:none;
font-weight:bold;
border-bottom:1px solid black;
:focus{
    border:2px solid var(--plsGreen);
  }

`;
export const Headerspan = styled.div`
width:100%;
text-align:center;
font-size:1.1rem;
`;


export default function PrintComponent({poinfo,quoteinfo,customerinfo,currentLocation,currentContact,notes,results}) {
  let componentRef = useRef();
const [message, setMessage] = useState("")
const [total, setTotal] = useState(0)
const [newlocation, setNewlocation] = useState()
useEffect(() => {
    const addit = () => {
        let u = 0
        poinfo?.items?.map(item => {
       u += (item.extprice)
            })
            setTotal(u)
    
    }
    addit()
},[poinfo])


  useEffect(() => {
const getpoinfo = async() => {

}




getpoinfo()
  },[])


  function percentage(partialValue, totalValue) {
    const t = (partialValue / 100) * totalValue
    return t + totalValue
 }      

 
  return (
    <>
      <Flex id="print_component">
        {/* button to trigger printing of target component */}
        <ReactToPrint
      
          trigger={() => <AiFillPrinter style={{fontSize:"calc(1.6rem + .5vw)"}} />}
          content={() => componentRef}
        />

        {/* component to be printed */}
        <Flex style={{ display: "none" }}>
          <ComponentToPrint poinfo={poinfo} results={results} notes={notes} quoteinfo={quoteinfo} currentContact={currentContact} customerinfo={customerinfo} currentLocation={currentLocation} ref={(el) => (componentRef = el)} />
        </Flex>
      </Flex>
    </>
  );
}




// component to be printed
class ComponentToPrint extends React.Component {

  constructor(props) {
    super(props);
     

  }

  render() {
    return (
      
     <Wrapper >

<Flexed margin="50px 0px 0px 0px">
    <Flex flex="3"><Img src={LOGO} alt="Logo" style={{width:"42%",left:"0px",}}/></Flex>
    <Flex></Flex>
    <Flex padding="0px 0px 0px 30px" fontSize="1.6rem">4250 118th Ave N<br />Clearwater, FL 33762<br />Phone: 727-573-1763<br />Fax: 727-573-2662<br />www.plsprint.com</Flex>
  
</Flexed>

<Flexed margin="25px 0px 0px -30px">
    <Flex flex="2.5" fontSize="1.5rem" style={{marginTop:"20px",padding:"3px 3px 10px 3px"}}><span style={{padding:"20px 0px",fontSize:"1.6rem",position:"relative",bottom:"10px"}}>CUSTOMER INFO</span><br />{this.props.customerinfo?.name}<br />
    {this.props.currentLocation?.address1}<br />
    {this.props.currentLocation?.address2 > "" && <>{this.props.currentLocation?.address2}<br /></>}
    {this.props.currentLocation?.city}{this.props.currentLocation?.state}, {this.props.currentLocation?.zip}<br />{this.props.currentContact?.name}
    </Flex>
    <Flex flex="2.5" fontSize="1.6rem" textAlign="right" style={{marginTop:"20px",padding:"3px 10px"}}>QUOTE # <br />DATE:<br />REP:<br />EMAIL:<br /></Flex>
    <Flex  style={{marginTop:"20px",padding:"3px"}} fontSize="1.6rem" width="170px">37239<br />01/05/2024<br />sam Broduer<br />pam@plsprint.com<br /></Flex>
</Flexed>
<Flexed margin="60px 0px 0px 0px" padding="5px 0px" style={{background:"rgb(0,0,0,.1)",borderBottom:"none",fontWeight:"bold"}} >
    <Flex fontSize="1.7rem" style={{paddingLeft:"30px"}}>PROJECT SPECS</Flex>
</Flexed>
<div style={{height:"55vh",position:"relative",width:"100%"}}>
<Flexed >
<Flex flex="1.5">
<Flexed><Flex  fontSize="1.4rem" style={{marginTop:"5px",padding:"0px",fontSize:"1.6rem"}}>{this.props.quoteinfo?.pname}</Flex></Flexed>
<div rows="20" style={{fontSize:"1.6rem",overflow:"hidden",width:"100%",whiteSpace:"pre-wrap"}} border="none">{this.props.notes}</div>
</Flex>
<Flex>
    <div style={{position:"relative",width:"100%",height:"55vh"}}>
        <div style={{position:"absolute",top:"400px",width:"90%",right:"0"}}>
    <Flexed>      
    <Flex fontSize="1.4rem" textAlign="center" bgColor="#E6E7E9" fontWeight="bold" padding="5px 8%">Please note, 4% will be added
for payments made by credit card.
</Flex>
    </Flexed>
    <Flexed gap="10px" >
        <Flex textAlign="right" fontSize="1.7rem" bgColor="#CAE2AC" fontWeight="bold" padding="3px 5px">QTY</Flex>
        <Flex textAlign="right" fontSize="1.7rem" bgColor="#CAE2AC" fontWeight="bold" padding="3px 5px">PRICE</Flex>
    </Flexed>
    {

     this.props.results.map(item => {
return <Flexed gap="10px">
        <Flex textAlign="right" fontSize="1.6rem" padding="3px 5px" fontWeight="bold">{item.quantity}</Flex>
        <Flex textAlign="right" fontSize="1.6rem" padding="3px 5px" fontWeight="bold">${item.cost}</Flex>
    </Flexed> 
     })   
    }
  
    </div>
    </div>
</Flex>
</Flexed>

   
</div>
<Flexed marginTop="50px">
   <Flex fontWeight="bold" flex="0" padding="0px 5px" fontSize="1.6rem">DATE:</Flex>
   <Flex border>&nbsp;</Flex>
   <Flex flex=".1"></Flex>
   <Flex fontWeight="bold"  flex="0" padding="0px 5px" fontSize="1.6rem">SIGNATURE</Flex>
   <Flex border>&nbsp;</Flex>
   <Flex></Flex>
</Flexed>
<Flexed marginTop="50px">
    <Flex fontSize="1.3rem">Local delivery included. Tax, postage, and shipping are additional. Acceptance of this quote represents a binding contract and agreement to the above terms and specifications. Payment is due in full at time of project completion. Quote is valid for 2 days.</Flex>
</Flexed>
<Flexed marginTop="25px">
    <Flex textAlign="right" fontSize="1.2rem">PLS/EST/2-22</Flex>
</Flexed>
  





    </Wrapper>

  
    );
  }
}
/////////////////////////////////////////////////////////////////////////////////////////////////////
