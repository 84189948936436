import React, { useEffect, useState } from 'react'
import { Colorbar, Tail, SheetWrap, Colorbarbuff, Content, InnerContent, Gripper } from './EstimatesStyles'

const Sheet = ({width,length,tail,colorbar,colorbarbuff,edges,gripper,quants}) => {


    const [sheetcolorbarbuff, setSheetcolorbarbuff] =  useState(colorbarbuff)
    const [totalsheetwidth ,setTotalsheetwidth] =  useState(0)
    const [totalsheetlength ,setTotalsheetlength] =  useState(0)



useEffect(() => {
let tailcheck = parseFloat(tail) + parseFloat(colorbar) + parseFloat(colorbarbuff)
if(tailcheck < .5){
let temp = (.5 - (parseFloat(tail) + parseFloat(colorbar)))
setSheetcolorbarbuff(temp)
}
setTotalsheetwidth(gettotalsheetwidth())
setTotalsheetlength(gettotalsheetlength)
},[width,colorbar,edges,tail])


const gettotalsheetwidth = () => {
let tempwidth = (parseFloat(width) || 0) - (parseFloat(edges) || 0) - (parseFloat(edges) || 0)
return tempwidth.toFixed(2)
}

const gettotalsheetlength = () => {
let templength = (parseFloat(length) || 0) - (parseFloat(tail) || 0) - (parseFloat(colorbar) || 0) - (parseFloat(colorbarbuff) || 0) - (parseFloat(gripper) || 0)
return templength
}

const scalenum = (num) => {
return (parseFloat(num) * 10)
}

  return (
  


    <SheetWrap style={{position:"relative",width:scalenum(width),height:scalenum(length)}}>
   <Tail height={(parseFloat(tail) * 10)+"px"} />
   <Colorbar height={(parseFloat(colorbar) * 10)+"px"}/>
   <Colorbarbuff height={(parseFloat(sheetcolorbarbuff) * 10)+"px"} />
       <Content padding={`0px ${scalenum(edges)}px`}>
<InnerContent >{totalsheetlength} X {totalsheetwidth}</InnerContent>
       </Content>
       <Gripper />
    </SheetWrap>
 
  )
}

export default Sheet